import React from "react";
import _ from "lodash";
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import {
    Box,
    Button,
    Dialog,
    FormControlLabel, FormControl, RadioGroup, Radio, Typography
} from "@mui/material";
import GridViewIcon from '@mui/icons-material/GridView';
import ListIcon from '@mui/icons-material/List';

import ComponentTypes from "../../componentTypes";
import {
    mdtCardConfigMapDispatchToProps,
    mdtCardConfigMapStateToProps,
    MdtCardConfigPanel,
    mdtCardConfigPanelPropTypes
} from "../mdtCard/mdtCardConfigPanel";
import SensorSelector from "../../common/dataExploration/chart/sensorSelector";
import Transition from "../../controls/dialogSlideTransition";
import { getCardFromLayoutConfigViews } from "../../common/layout/layoutHelper";

import * as dataGridActions from '../../../state/cards/dataGrid/dataGridActions';
import * as appUserConfigActions from '../../../state/app/actions/appUserConfigActions';
import { dataGridState } from "../../../state/cards/dataGrid/dataGridSelectors";
import { sanitizeDefinition } from "../../../state/cards/dataGrid/services/dataGridService";

import SensorConfigControl from "../../controls/configPanel/sensorConfigControl";

const styles = {
    actionButton: {
        marginBottom: '16px',
    },
    dialogPaper: {
        minHeight: '840px',
        maxHeight: '840px',
    },
    sensorHeader: {
        alignItems: 'center',
        display: 'flex'
    },
    sensorTitle: {
        fontSize: '1.25rem',
        flexGrow: 2,
        alignSelf: 'center',
        width: '280px',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    hiddenIcon: {
        marginRight: '10px',
        alignSelf: 'center',
        ml: 'auto'
    },
    dragIcon: {
        marginRight: '10px',
        "&:hover": {
            color: 'grey.400',
            transform: 'scale(1.1)'
        }
    },
    sensorDisplayText: {
        m: '10px 0',
        width: '100%'
    },
    sensorUom: {
        display: 'flex',
        m: '0px 0px 10px 0px',
        alignItems: 'center'
    },
    uomSelection: {
        flexGrow: 1,
        marginLeft: 1,
    },
    uomDropdown: {
        m: 1,
        minWidth: 120,
        width: 'calc(100% - 16px)'
    },
    formattingApplied: {
        display: 'flex',
        alignItems: 'center',
        m: '0px',
    },
    formattingCheckbox: {
        padding: '0 10px 0 0'
    },
    conditionalFormattingColor: {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '48px',
        height: '48px',
        overflow: 'visible',
        justifyContent: 'start',
        flexFlow: 'row nowrap',
        position: 'relative'
    },
    colorPickerPopover: {
        position: 'absolute',
        top: '-170px',
        left: '0px',
    },
    conditionSelection: {
        marginLeft: 1,
        marginRight: 1
    },
    conditionDropdown: {
        marginTop: 1,
        marginBottom: 1,
        width: '145px'
    },
    conditionNumber: {
        "& .MuiInputBase-root": {
            "& input": {
                textAlign: "right"
            }
        },
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            'WebkitAppearance': 'none',
            margin: 0
        },
        'input[type=number]': {
            'MozAppearance': 'textfield'
        }
    },
    radioIcon: {
        fontSize: '30'
    },
    checkedIcon: {
        fontSize: '30',
        color: "#ce4300",
    }
};

class DataGridConfigPanel extends MdtCardConfigPanel {

    _configurationProfile = {
        ...this._configurationProfile,
        defaultTitle: 'DATA GRID',
        availableSizes: [],
        width: 550
    };

    constructor(props) {
        super(props);

        //Set the card definition if not existing
        const card = getCardFromLayoutConfigViews(this.props.dashboards[this.props.card.dashboard]?.views, this.props.card.view, this.props.card.card.i);

        if (_.isEmpty(card?.configuration?.definition)) {
            this.props.initializeDefinition(this.props.card.dashboard, this.props.card.view, this.props.card.card.i, sanitizeDefinition(this.props.definition));
        };
    }

    componentDidMount() {
        super.componentDidMount();
        // load all sensors so we can get the target uoms of the selected sensors
        const trucks = _.uniq(_.map(this.props.definition.primary.trucks, elem => elem.pid));
        const startTime = this.props.definition.primary.timeRange.startTime;
        const endTime = startTime + this.props.definition.primary.timeRange.duration * 60;
        const namespace = 'DXP';
        if (!_.isNil(trucks) && !_.isEmpty(trucks) && !_.isNil(startTime) && !_.isNil(endTime)) {
            this.props.loadSensors(trucks, startTime, endTime, namespace);
        }
    }

    hasConfigChanged(dashboard, view, cardKey) {
        let hasChanges = super.hasConfigChanged(dashboard, view, cardKey);

        const currentDashboard = this.props.dashboards[dashboard];
        const originalCard = getCardFromLayoutConfigViews(currentDashboard?.originalConfigViews, view, cardKey);

        if (_.isNil(originalCard)) return false;

        // Check for changes in sensor config
        // Normalize empty configuration for cards
        const originalConfig = originalCard.configuration;
        const currSensors = _.map(this.props.definition.primary?.sensors || [], sensor => { return _.omit(sensor, ['targetUoms']) });
        const originalSensors = _.map(originalConfig.sensors || [], sensor => { return _.omit(sensor, ['targetUoms']) });
        const hasSensorsChanges = !_.isEqual(currSensors, originalSensors);

        // Check for changes in data viewing format 
        // Normalize empty configuration for cards
        const originalDataView = originalConfig.dataView || "";
        const currDataView = this.props.definition.primary.dataView || "";
        const hasDataViewChanges = !_.isEqual(originalDataView, currDataView);

        return hasChanges || hasSensorsChanges || hasDataViewChanges;
    };

    disableClose() {
        let disabled = super.disableClose();

        const missingField = _.find(this.props.definition.primary.sensors, function (item) { return item.displayName === ""; })

        return disabled || missingField;
    };

    getRenderedContent() {
        const card = getCardFromLayoutConfigViews(this.props.dashboards[this.props.card.dashboard]?.views, this.props.card.view, this.props.card.card.i);

        return (
            <Box>
                <Box>
                    <FormControl
                        onChange={(event, value) => {
                            this.props.onChangeCardView(this.props.stateDef, event.target.value, card.w * card.h, card.h);
                        }}
                    >
                        <Box display="flex" alignItems="center" gap={2} >
                            <Typography>Select a View:</Typography>
                            <RadioGroup
                                row
                                defaultValue={this.props.definition.primary.dataView}
                            >
                                <FormControlLabel
                                    value={this.props.dataViewOptions.gridView}
                                    control={<Radio
                                        icon={<GridViewIcon sx={styles.radioIcon} />}
                                        checkedIcon={<GridViewIcon sx={styles.checkedIcon} />}
                                    />}
                                    label="Grid View"
                                />
                                <FormControlLabel
                                    value={this.props.dataViewOptions.listView}
                                    control={<Radio
                                        icon={<ListIcon sx={styles.radioIcon} />}
                                        checkedIcon={<ListIcon sx={styles.checkedIcon} />}
                                    />}
                                    label="List View"
                                />
                            </RadioGroup>
                        </Box>
                    </FormControl>
                </Box>
                <Box sx={{ width: '500px' }} textAlign={'center'}>
                    <Button sx={styles.actionButton} variant='contained' color='primary'
                        onClick={() => {
                            this.props.openSensorSelector();
                        }}
                    >
                        Select Sensors
                    </Button>
                </Box>
                <Dialog
                    maxWidth={'lg'}
                    fullWidth={true}
                    open={this.props.shouldOpenConfigSensorSelector}
                    TransitionComponent={Transition}
                    PaperProps={{ paper: styles.dialogPaper }}
                >
                    <SensorSelector
                        parentCallback={(sensors) => this.props.setSelectedSensors('primary', sensors)}
                        enableGroupEdit={false}
                        initalSelectedSensors={_.isNil(this.props.definition?.primary.sensors) ? [] : this.props.definition.primary.sensors}
                        trucks={_.isNil(this.props.definition?.primary.defaultTruck) ? [] : [this.props.definition.primary.defaultTruck.pid]}
                        startTime={this.props.definition?.primary.timeRange.startTime}
                        endTime={this.props.definition.primary.timeRange.startTime + (60 * this.props.definition.primary.timeRange.duration)}
                        onClose={this.props.closeSensorSelector}
                        stateKey={ComponentTypes.SENSOR_SELECTOR + '_' + this.props.stateKey}
                        namespace={'DXP'}
                        unitType={_.isNil(this.props.definition?.primary.defaultTruck.unitType) ? '' : this.props.definition.primary.defaultTruck.unitType}
                    />
                </Dialog>

                <SensorConfigControl
                    sensors={this.props.definition.primary.sensors}
                    onDragDropCallback={this.props.onUpdateSensorsOrder}

                    sensorNotVisibleText='This sensor is not visible on the card'

                    canRemoveSensor={true}
                    onRemoveSensorCallback={this.props.onRemoveDataGridSensor}
                    cardWidth={card.w}
                    cardHeight={card.h}

                    onChangeSensorDisplayNameCallback={this.props.onChangeDataGridSensorDisplayName}
                    onChangeSensorUOMCallback={this.props.onChangeDataGridSensorUOM}

                    onChangeSensorPropertyCallback={this.props.onChangeDataGridSensorConditionalFormatting}
                    onChangeSensorBorderAndTextColor={this.props.onChangeDataGridBorderAndTextColor}

                    onAddRuleCallback={this.props.onAddDataGridSensorConditionalFormatting}

                    colorPickerStates={this.props.colorPickerStates}
                    onSetColorPickerStateCallback={this.props.onSetColorPickerState}

                    onRemoveRuleCallback={this.props.onRemoveDataGridSensorConditionalFormatting}

                    conditionOptions={this.props.conditionOptions}
                />
            </Box>
        )
    };
};

DataGridConfigPanel.proptypes = mdtCardConfigPanelPropTypes;

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.DATA_GRID : props.stateKey,
            type: ComponentTypes.DATA_GRID,
        }
    }
};

const mapStateToProps = (state, props) => {
    const { stateDef } = props;
    let componentState = dataGridState(state[stateDef.key]);
    return {
        ...mdtCardConfigMapStateToProps(state, props),
        shouldOpenConfigSensorSelector: componentState.shouldOpenConfigSensorSelector,
        definition: componentState.definition,
        conditionOptions: componentState.conditionOptions,
        dataViewOptions: componentState.dataViewOptions,
        colorPickerStates: componentState.colorPickerStates,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        ...mdtCardConfigMapDispatchToProps(dispatch, props),
        loadSensors: (trucks, startTime, endTime, namespace) => {
            dispatch(dataGridActions.querySensors(props.stateDef, trucks, startTime, endTime, namespace));
        },
        setSelectedSensors: (xAxisId, selectedSensors) => {
            dispatch(dataGridActions.onSetSelectedSensors(props.stateDef, xAxisId, selectedSensors, props.card.dashboard, props.card.view, props.card.card.i));
        },
        openSensorSelector: () => {
            dispatch(dataGridActions.openSensorSelector(props.stateDef));
        },
        closeSensorSelector: () => {
            dispatch(dataGridActions.closeSensorSelector(props.stateDef));
        },
        initializeDefinition: (dashboard, view, cardKey, definition) => {
            dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, definition));
        },
        onCloseCardConfiguration: (dashboard, view, cardKey) => {
            dispatch(dataGridActions.onCloseCardConfiguration(dashboard, view, cardKey));
        },
        onDiscardCardConfiguration: (dashboard, view, cardKey, cardType) => {
            dispatch(dataGridActions.onDiscardCardConfiguration(props.stateDef, dashboard, view, cardKey, cardType));
        },
        onChangeCardSize: (dashboard, view, cardKey, size) => {
            dispatch(dataGridActions.onChangeCardSize(props.stateDef, dashboard, view, cardKey, size));
        },
        onChangeDataGridSensorDisplayName: (sensorSetId, alias, displayName) => {
            dispatch(dataGridActions.updateSensorDisplayName(props.stateDef, sensorSetId, alias, displayName));
        },
        onChangeDataGridSensorUOM: (sensorSetId, alias, uom) => {
            dispatch(dataGridActions.updateSensorUOM(props.stateDef, sensorSetId, alias, uom));
        },
        onChangeDataGridSensorConditionalFormatting: (sensorSetId, alias, index, property, value) => {
            dispatch(dataGridActions.updateSensorConditionalFormatting(props.stateDef, sensorSetId, alias, index, property, value));
        },
        onChangeDataGridBorderAndTextColor: (sensorSetId, alias, borderAndTextColorRules) => {
            dispatch(dataGridActions.updateSensorBorderAndTextColor(props.stateDef, sensorSetId, alias, borderAndTextColorRules));
        },
        onAddDataGridSensorConditionalFormatting: (sensorSetId, alias) => {
            dispatch(dataGridActions.addDataGridSensorConditionalFormatting(props.stateDef, sensorSetId, alias));
        },
        onRemoveDataGridSensorConditionalFormatting: (sensorSetId, alias, index) => {
            dispatch(dataGridActions.removeDataGridSensorConditionalFormatting(props.stateDef, sensorSetId, alias, index));
        },
        onRemoveDataGridSensor: (sensorSetId, alias, area) => {
            dispatch(dataGridActions.removeDataGridSensor(props.stateDef, sensorSetId, alias, area));
        },
        onSetColorPickerState: (id, index, color) => {
            dispatch(dataGridActions.setColorPickerState(props.stateDef, id, index, color));
        },
        onUpdateSensorsOrder: ({ removedIndex, addedIndex }) => {
            dispatch(dataGridActions.onUpdateSensorsOrder(props.stateDef, removedIndex, addedIndex, props.card.dashboard, props.card.view, props.card.card.i));
        },
        onChangeCardView: (stateDef, dataView, area, height) => {
            dispatch(dataGridActions.setCardView(stateDef, dataView));
        },
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(DataGridConfigPanel));