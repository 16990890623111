import * as fracOpDGBMaintenanceOverviewCardSimulation from './fracOpDGBMaintenanceOverviewCardSimulation';
import {performCardFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';

const fetchFleetFracOpDGBMaintenanceOverview = (fleetId) => {
  if (useSimulationData('fracOpDGBMaintenanceOverviewCard')) {
    return fracOpDGBMaintenanceOverviewCardSimulation.fetchFracOpDGBMaintenanceOverview(fleetId);
  }


  let queryString = '{ fleetFracOpDGBMainOverview(fleetId:{fleetId}) { fleetType state flowRate pressure ' +
      'alarmCounts {criticalAlarmCount infoAlarmCount warningAlarmCount } ' +
      'frontSideCounts { label low med high } ' +
      'backSideCounts { label low med high } ' +
      'avgEngineLoad ' +
      'avgEngineTorque ' +
      'dgbBlendingRatio {numerator denominator} ' +
      'avgGasSubstitution ' +
      '} }';

  queryString = queryString.replace('{fleetId}', fleetId);

  return performCardFetch({
    'query': queryString
  })
};

export {
  fetchFleetFracOpDGBMaintenanceOverview
}


