import reduceReducers from 'reduce-reducers';

import queryReducer from './reducers/queryReducer';
import cardReducer from './reducers/cardReducer';

import { fracOpDGBMaintenanceOverviewCardState } from './fracOpDGBMaintenanceOverviewCardSelectors';

const initialState = fracOpDGBMaintenanceOverviewCardState();

const fracOverviewCardReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    queryReducer,
    cardReducer
  );

  return reducer(state, action);
};

export default fracOverviewCardReducer
