import jwt_decode from 'jwt-decode';
import history from './historyHelper';
import _ from "lodash";
import auth0 from "auth0-js";

/**
 * Helper method that persists the Auth0 authentication details in
 * the local storage object. Turns the expiry time into a specific
 * time for easier comparison later to determine if the user is
 * still authenticated.
 */
const setSession = (displayDataServiceToken, cardDataServiceToken, liveDataServiceToken, expiresIn, idToken) => {
  let expiresAt = JSON.stringify((expiresIn * 1000) + new Date().getTime());
  localStorage.setItem('display_access_token', displayDataServiceToken);
  localStorage.setItem('card_access_token', cardDataServiceToken);
  localStorage.setItem('id_token', idToken);
  localStorage.setItem('expires_at', expiresAt);
  if (!_.isNil(liveDataServiceToken)) {
    localStorage.setItem('live_access_token', liveDataServiceToken);
  }
};

const addToSession = (item, value) => {

  if (!_.isNil(item)) {
    sessionStorage.setItem(item, value);
  }

}

const getItemFromSession = (item) => {

  return sessionStorage.getItem(item);

}

/**
 * Initiate the Auth0 login process, which essentially leaves this application
 * and navigates the user to the Auth0 login application.
 *
 * IMPORTANT: This action is unique in that it does not resolve to a reducer
 * (state change), so do not use this as a template for new actions.
 */
const performLogin = () => {
  // Create an instance of the Auth0 client and initiate the login process.
  const client = createAuth0Client();
  client.authorize({
    prompt: 'login'
  });
};


const performLogout = () =>{
  const client = createAuth0Client();
  client.logout(); //default returnTo page already set at auth0
}

/**
 * Helper method that clears the Auth0 authentication details out
 * of the local storage and then directs the user to the home page
 */
const logout = () => {
  performLogout();
  clearLocalStorage();
  history.replace('/home');
};

/**
 * Creates an instance of the Auth0 javascript client.
 */
const createAuth0Client = () => {

  // Seems this information should be placed somewhere central, but not
  // sure where. Environment variables are only used in Production mode
  // and we want to authenticate in Development mode as well...

  return new auth0.WebAuth({
    domain: 'mdtcloud.auth0.com',
    clientID: 'Fuonv8viG6JeIhjq3OVcZO2S9dyDKHUA',
    redirectUri: getRedirectUrl(),
    // The initial audience was changed from 'portalappservice' to 'livedataservice' since we need our dev users
    // to give consent to access the livedataservice API in Auth0. 
    // Otherwise we cannot silently give consent and obtain a token from Auth0 through checkSession in handleLogin() in appUserActions.js
    // Silent consent works only in production domains - not localhost.
    // In the future, if we need to access other APIs, we can set them here so that the user can give consent to them interactively.
    audience: 'livedataservice',
    responseType: 'token id_token',
    scope: 'openid'
  });
};

/**
 * Returns the url that Auth0 will use to callback to us during
 * a user authentication. It is formatted based on the mode the
 * app is running in. In Production the callback will need to
 * include the ingress and base route information.
 */
const getRedirectUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    return window.location.protocol + '//{callBackHost}{baseRoute}/callback'
        .replace('{callBackHost}', process.env.REACT_APP_PROD_AUTH_CALLBACK_HOST)
        .replace('{baseRoute}', process.env.REACT_APP_PROD_BASE_ROUTE);
  } else {
    return 'http://localhost:3000/callback';
  }
};


/**
 * Helper method to determine if the user is still authenticated.
 * Retrieves the expiry time and evaluates if it is still valid.
 */
const isAuthenticated = () => {
  let authenticated = false;
  try {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    authenticated = new Date().getTime() < expiresAt;
  } catch(e) {
    authenticated = false;
  }
  if (!authenticated) {
    clearLocalStorage();
  }
  return authenticated;
};

/**
 * Clears out the authentication details from local storage.
 */
const clearLocalStorage = () => {
  localStorage.removeItem('display_access_token');
  localStorage.removeItem('card_access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');
};

/**
 * Utility method to return the access token from local storage.
 */
const getDisplayDataServiceToken = () => {
  return localStorage.getItem('display_access_token');
};

/**
 * Utility method to return the id token from local storage.
 */
const getIdToken = () => {
  return localStorage.getItem('id_token');
};

/**
 * Returns the token used to communicated with the card data service.
 */
const getCardDataServiceToken = () => {
  return localStorage.getItem('card_access_token');
};

/**
 * Utility method to split a token
 */
const splitToken = (token) => {
  let parts = token.split('.');
  if (parts.length === 2) {
    parts = [parts[0], parts[1], ""];
  }
  if (parts.length !== 3) {
    throw new Error('The token was expected to have 3 parts, but got ' + parts.length + '.')
  }
  return parts;
};

/**
 * Utility method that decodes a token and checks for the specific permission.
 */

const hasPermission = (expectedPermission) => {
  return () => {
    let token = getDisplayDataServiceToken();
    if (!_.isNil(token)) {
      let parts = splitToken(token);
      try {
        let payloadJson = atob(parts[1]);
        return payloadJson.includes(expectedPermission);
      } catch (e) {
        throw new Error('There was an error decoding the token.');
      }
    }
    return false;
  };
};

// Specific permission checkers created by using the generic hasPermission function.
const hasSetContextToken = hasPermission('site:setContext');
const hasOwnerAdminToken = hasPermission('usermanagement:admin');
const hasViewDevelopmentContentPermission = hasPermission('view:developmentcontent');
const hasFleetManagementPermission = hasPermission('view:fleetmanagement');
const hasViewPreReleaseContentPermission = hasPermission('view:prereleasecontent');
const hasDeviceSettingPermission = hasPermission('settings:device');
const hasJobApproverPermission = hasPermission('job:approver');
const hasDashboardAdminPermission = hasPermission('dashboard:admin');

/**
 * Utility method that decodes the ID Token and returns the user
 * detail claims.
 */
const getUserClaimsFromToken = () => {

  let token = getIdToken();
  let payload = null;

  try {
    payload = jwt_decode(token);
  } catch (e) {
    throw new Error('There was an error decoding the ID token.');
  }

  return {
    ownerId: payload['https://mobiledatatech.com/owner_id'],
    name: payload['https://mobiledatatech.com/name'],
    email: payload['https://mobiledatatech.com/email'],
    role: payload['https://mobiledatatech.com/role'],
    userId: payload['sub'],
  };
};

export {
  setSession,
  isAuthenticated,
  getDisplayDataServiceToken,
  hasSetContextToken,
  hasOwnerAdminToken,
  getUserClaimsFromToken,
  getCardDataServiceToken,
  hasViewDevelopmentContentPermission,
  hasFleetManagementPermission,
  hasViewPreReleaseContentPermission,
  hasDeviceSettingPermission,
  hasJobApproverPermission,
  addToSession,
  getItemFromSession,
  clearLocalStorage,
  createAuth0Client,
  performLogin,
  performLogout,
  logout,
  hasDashboardAdminPermission,
};