import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { trackPage } from '../../../../../helpers/googleAnalyticsHelper';

import ComponentTypes from '../../../../componentTypes';
import LayoutFixedWidth from '../../../../controls/layout/layoutFixedWidth';
import { getCardElementsFromLayout, addResizeHandle } from '../../../../../helpers/cardElementsHelper';

import { appState as applicationState } from '../../../../../state/app/appSelectors';
import fleetMapViews from '../../../../../state/displays/fleetMap/display/fleetMapViews';

import { CustomResizeHandle } from '../../../../cards/mdtCard/controls/resizeHandle';
import * as appUserConfigActions from '../../../../../state/app/actions/appUserConfigActions';
import * as fleetMapActions from '../../../../../state/displays/fleetMap/display/fleetMapActions';
import {compose, withProps} from "recompose";


class Dashboard extends Component {

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.FLEET_DASHBOARD, this.props.user);
  }

  render() {

    const viewConfiguration = _.isNil(this.props.dashboards) || _.isNil(this.props.dashboards[ComponentTypes.FLEET_DASHBOARD]) ?
      {} : _.find(this.props.dashboards[ComponentTypes.FLEET_DASHBOARD].views.views, ['id', fleetMapViews.DASHBOARD]) || {};

    // Determine the start/end time for the 6 hour charts since this view does not have a duration picker.
    const endTime = moment().startOf('minute').unix();
    const startTime = moment.unix(endTime).subtract(360, 'minutes').unix();

    const cardElements = getCardElementsFromLayout(ComponentTypes.FLEET_DASHBOARD, viewConfiguration.id, viewConfiguration.cards, { truck: this.props.fleet, startTime:startTime, endTime:endTime, liveDataEnabled: true, selectedTimeFrame: 360 }, this.props.editMode, this.props.selectedCard?.type, this.props.contextData, this.props.onCardsQueryRunning, this.props.cardsMetaconfig, this.props.navigateToView);

    let showResizeHandles = (!_.isNil(this.props.editMode) && this.props.editMode === true);
    addResizeHandle(showResizeHandles, viewConfiguration.cards);

    return (
      <LayoutFixedWidth layout={viewConfiguration.cards} cols={11} rowHeight={160} width={1880} isDraggable={this.props.editMode === true}
                        compactType={_.isNil(this.props.editMode) ? null : 'vertical'} preventCollision={this.props.editMode !== true}
                        onLayoutChange={(layout) => this.props.onUpdateDashboardLayout(ComponentTypes.FLEET_DASHBOARD, viewConfiguration, layout)}
                        resizeHandle={<CustomResizeHandle />} isEditMode={this.props.editMode}>
        {
          cardElements.map((card) => {
            return (
              <div key={card.key}>{card.element}</div>
            )
          })
        }
      </LayoutFixedWidth>
    )
  }
}   

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_DASHBOARD : props.stateKey,
      type: ComponentTypes.FLEET_DASHBOARD,
    }
  }
};

Dashboard.propTypes = {
  fleet: PropTypes.object,
  navigateToView: PropTypes.func,
  editMode: PropTypes.bool,
  cardsMetaconfig: PropTypes.array,
};

const mapStateToProps = (state, props) => {

  let appState = applicationState(state);

  return {
    dashboards: appState.user.dashboards,
    selectedCard: appState.user.dashboards[ComponentTypes.FLEET_DASHBOARD]?.selectedCard,
    contextData:appState.context.contextData
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onUpdateDashboardLayout: (dashboard, view, newLayout) => { dispatch(appUserConfigActions.updateDashboardLayout(dashboard, view, newLayout)); },
    onCardsQueryRunning: (queryRunning) => { dispatch(fleetMapActions.onCardsQueryRunning(props.stateDef, queryRunning)); }
  }
}

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(Dashboard));