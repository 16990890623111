import _ from 'lodash';
import React, {Component} from 'react';
import {withProps, compose} from 'recompose';
import {connect} from 'react-redux';

import {Typography, Box, IconButton, Dialog, DialogContent, CircularProgress} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import Progress from '../../controls/progress';

import ComponentTypes from '../../componentTypes';
import * as systemSoftwareActions from '../../../state/displays/systemSoftware/systemSoftwareActions';
import * as pumpDashboardActions from '../../../state/displays/pumpDashboard/pumpDashboardActions';
import { systemSoftwareState } from '../../../state/displays/systemSoftware/systemSoftwareSelectors';
import { appState as applicationState } from '../../../state/app/appSelectors';
import * as assetTypesService from '../../../state/common/services/assetTypeService';

import getDetailsPageStyles from '../../common/styles/detailsPageStyles';

import { trackPage } from '../../../helpers/googleAnalyticsHelper';
import MDTDataGrid from "../../common/table/MDTDataGrid";
import {GridActionsCellItem, gridClasses} from "@mui/x-data-grid-pro";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import * as notificationsActions from "../../../state/app/actions/appNotificationsActions";
import subscriptionEntity from "../../common/subscriptionEntity";
import SubscriptionDialog from "../../controls/notifications/subscriptionDialog";

const detailsPageStyles = getDetailsPageStyles();

const styles = {
  ...detailsPageStyles,
  exportAnchor: {
    visibility: 'hidden'
  },
  detailsPageContent: {
    ...detailsPageStyles.detailsPageContent,
    width: '98vw'
  },
  detailsPageHeaderActions: {
    ...detailsPageStyles.detailsPageHeaderActions,
    justifyContent: 'flex-start'
  },
  mdtDataGridContainer: {
    '& .MuiDataGrid-actionsCell': {
      visibility: 'hidden'
    },
    [`& .${gridClasses.row}:hover`]: {
      '.MuiDataGrid-actionsCell': {
        visibility: 'visible'
      },
    },
  },
};

class SystemSoftware extends Component {

  constructor(props) {
    super(props);

    // Generate the columns data for the data table.
    this.columns = this.generateTableColumns();
  }

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.SYSTEM_SOFTWARE, this.props.user);
    // Load the data from the service layer.
    this.props.queryItems();
    // Start the timer for refreshing the display.
    this.periodicItemsRefreshId = this.periodicRefreshItems();
    // Start the timer for refreshing the upload time label
    this.periodicUploadtimeRefreshId = this.periodicRefreshUploadTime();
  }

  componentWillUnmount() {
    // Stop the timer for refreshing the items.
    if (!_.isNil(this.periodicItemsRefreshId)) {
      clearInterval(this.periodicItemsRefreshId);
    }
    // Stop the timer for refreshing the upload time label
    if (!_.isNil(this.periodicUploadtimeRefreshId)) {
      clearInterval(this.periodicUploadtimeRefreshId);
    }
  }

  componentDidUpdate(prevProps) {

    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      // Query the data again if the owner changes.
      this.props.queryItems();
    }

    //Once truck configs are ready for download, state triggerExportByTruck will change to true, along with the exportZipUrl
    //This condition check makes sure download start automatically on ready
    if (prevProps.triggerExportByTruck === false && this.props.triggerExportByTruck === true) {
      this.exportAnchor.click();
    }

  }

  periodicRefreshItems() {

    // Periodically refresh the equipment.

    return setInterval(()=>{
      this.props.queryItems();
    }, 900000); // 15 minutes
  }

  periodicRefreshUploadTime() {

    // Periodically refresh the relative time for the sensor age.

    return setInterval(()=>{
      this.props.refreshUploadTime();
    }, 60000); // 1 minute
  }

  generateTableColumns() {
    // Columns match detail documented under README.md section "SystemSoftware Display columns match"
    let columns = [];
    columns.push({
      field: 'name',
      headerName: 'Unit ID',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 255,
      editable: false,
      hideable: false,
      pinnable: false
    });
    columns.push({
      field: 'displayValueType',
      headerName: 'Type',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 160,
      editable: false
    });
    columns.push({
      field: 'displayValueFirmwareVersion',
      headerName: 'Kernel Version',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 250,
      editable: false
    });
    columns.push({
      field: 'displayValueSoftwareVersion',
      headerName: 'Mainboard Version',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 160,
      editable: false
    });
    columns.push({
      field: 'displayValueKernelVersion',
      headerName: 'EB07 Version',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 160,
      editable: false
    });
    columns.push({
      field: 'displayValueConfigVersion',
      headerName: 'Config. Version',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 140,
      editable: false
    });
    columns.push({
      field: 'displayValueHardwareVersion',
      headerName: 'Hardware Version',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 140,
      editable: false
    });
    columns.push({
      field: 'displayValueSerialNumber',
      headerName: 'Serial Number',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 160,
      editable: false
    });
    columns.push({
      field: 'fleetName',
      headerName: 'Fleet',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 130,
      editable: false
    });
    columns.push({
      field: 'displayValueTimeStamp',
      headerName: 'Last Modified',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      width: 140,
      editable: false
    });
    columns.push({
      field: 'Actions',
      align: 'center',
      width: 30,
      resizable: false,
      hideable: false,
      disableReorder: true,
      type: 'actions',
      getActions: (params) => {
        let actions = [];
        const disableDownloadConfigs = !(params.row?.hasConfigFile ?? false);
        actions.push(<GridActionsCellItem icon={<NotificationAddIcon fontSize={'small'}/>} onClick={() => this.props.onShowSubscriptionDialog(true, params.row)} label="Create Notification" showInMenu />);
        actions.push(<GridActionsCellItem disabled={disableDownloadConfigs} icon={<DownloadIcon fontSize={'small'}/>} onClick={() => this.props.exportByTruck(params.row.name)} label="Download Configs" showInMenu />);
        // We currently only allow table actions for frac pumps
        if (!_.isNil(params.row) && params.row.type === assetTypesService.assetTypes.FRAC_PUMP) {
          actions.push(<GridActionsCellItem  icon={<DashboardIcon fontSize={'small'}/>} onClick={() => this.props.navigateToDashboard(params.row.id)} label="Dashboard View" showInMenu />);
        }
        return actions;
      }
    });
    return columns;
  }

  render() {

    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          <Box sx={styles.detailsPageHeader}>
            <Box sx={styles.detailsPageHeaderTitle}>
              <Typography variant={'h6'}>System Software</Typography>
            </Box>
            <Box sx={styles.detailsPageHeaderActions}>
              <IconButton
                onClick={() => this.props.queryItems()}
                size="large"><RefreshIcon/></IconButton>
              {/* Disable the ability to switch between software types until the Computer options is available */}
              {/*<Box sx={{display:'flex', flexFlow:'row nowrap', alignItems:'center'}}>*/}
                {/*<Typography sx={{paddingRight: 8}} variant={'subtitle1'}>SOFTWARE:</Typography>*/}
                {/*<Typography variant={'subtitle1'}>Controller</Typography>*/}
                {/*<Switch color='primary' checked={this.props.isComputerSoftware} onChange={(event) => this.props.setIsComputerSoftware(event.target.checked)}/>*/}
                {/*<Typography variant={'subtitle1'}>Computer</Typography>*/}
              {/*</Box>*/}
            </Box>
          </Box>
          <Box sx={styles.mdtDataGridContainer}>
            <MDTDataGrid
                columns={this.columns}
                rows={this.props.items}
                disableSelectionOnClick
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    printOptions: {
                      disableToolbarButton: true
                    },
                    csvOptions : {
                      fileName: 'system-software'
                    }
                  }
                }}
                stateDef={this.props.stateDef}
                rowsPerPage={this.props.rowsPerPage}
                setRowsPerPage={this.props.setRowsPerPage}
                rowsPerPageOptions={this.props.rowsPerPageOptions}
                getRowId={(row) => row.internalId}
            />
          </Box>
        </Box>

        {/* Export Progress */}
        <Dialog open={this.props.dialogOpen} disableEscapeKeyDown>
          <DialogContent sx={{
            display:'flex',
            flexFlow:'column',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Typography variant={'subtitle1'}>Generating Export File</Typography>
            <CircularProgress sx={{margin: 3}} size={80} />
            <Typography variant={'subtitle1'}>This can take several minutes...</Typography>
          </DialogContent>
        </Dialog>

        {/* Hidden Browser Export Anchor */}
        <Box component="a" sx={{visibility: 'hidden'}} ref={element => this.exportAnchor = element} href={this.props.exportByTruckStatus.exportZipUrl} download>exportAnchor</Box>

        <Progress open={this.props.queryRunning}/>
        <SubscriptionDialog entity={subscriptionEntity.TRUCKCONFIGURATION}/>
      </Box>
    );

  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.SYSTEM_SOFTWARE : props.stateKey,
      type: ComponentTypes.SYSTEM_SOFTWARE,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = systemSoftwareState(state[stateDef.key]);
  let appState = applicationState(state);

  return {
    selectedOwner: appState.selectedOwner,
    queryRunning: componentState.queryRunning,
    items: componentState.items,
    rowsPerPage: componentState.rowsPerPage,
    rowsPerPageOptions: componentState.rowsPerPageOptions,
    user: appState.user,
    exportByTruckStatus: componentState.exportJobState,
    triggerExportByTruck: componentState.triggerExportByTruck,
    dialogOpen: componentState.dialogOpen,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryItems: () => { dispatch(systemSoftwareActions.queryData(props.stateDef)) },
    refreshUploadTime: () => { dispatch(systemSoftwareActions.refreshUploadTime(props.stateDef)) },
    navigateToDashboard: (unitId) => dispatch(pumpDashboardActions.navigate(unitId)),
    setRowsPerPage: (rowSize) => { dispatch(systemSoftwareActions.dataGridSetRowSize(props.stateDef, rowSize)); },
    onShowSubscriptionDialog: (show, context) => { dispatch(notificationsActions.showSubscriptionDialog(show, context)); },
    exportByTruck: (truckName) => {dispatch(systemSoftwareActions.exportByTruck(props.stateDef, truckName));}
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(SystemSoftware))