import _ from 'lodash';
import exportActionTypes from '../exportActionTypes';
import * as dataExplorerService from '../../../../components/controls/dataExplorer/dataExplorerService';
import * as exportService from '../services/exportService';

const explorerReducer = (state, action) => {

  switch (action.type) {

    case exportActionTypes.EXPORT_FIND_NEXT_RANGE:
      return onFindNext(state, action);

    case exportActionTypes.EXPORT_FIND_PREVIOUS_RANGE:
      return onFindPrevious(state, action);

    default: return state;

  }
};

const onFindNext = (state, action) => {

  let nextEdge = dataExplorerService.getNextBlockEdge(state.selectedDateTime, state.dataRanges);

  let newState = {
    ...state,
    selectedDateTime: _.isNil(nextEdge) ? state.selectedDateTime : nextEdge,
    selectedDateTimeDisplay: _.isNil(nextEdge) ? state.selectedDateTime.clone().add(state.selectedDuration.value, 'minutes').startOf('minute') : nextEdge.clone().add(state.selectedDuration.value, 'minutes').startOf('minute'),
  };
  newState.canExport = exportService.resolveCanExport(newState);
  return newState;
};

const onFindPrevious = (state, action) => {

  let previousEdge = dataExplorerService.getPreviousBlockEdge(state.selectedDateTime, state.dataRanges);

  let newState = {
    ...state,
    selectedDateTime: _.isNil(previousEdge) ? state.selectedDateTime : previousEdge,
    selectedDateTimeDisplay: _.isNil(previousEdge) ? state.selectedDateTime.clone().add(state.selectedDuration.value, 'minutes').startOf('minute') : previousEdge.clone().add(state.selectedDuration.value, 'minutes').startOf('minute')
  };
  newState.canExport = exportService.resolveCanExport(newState);
  return newState;
};


export default explorerReducer