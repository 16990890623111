import _ from 'lodash';
import React from 'react';

import fleetMapViews from '../../../../../state/displays/fleetMap/display/fleetMapViews';

import Dashboard from '../../visuals/dashboard/dashboard';
import PumpOnlyFleetComponentView from './views/pumpOnlyFleetComponentView';
import PumpOnlyFleetHealthView from './views/pumpOnlyFleetHealthView';
import PumpOnlyFleetOperationView from './views/pumpOnlyFleetOperationView';
import DgbView from '../../visuals/dgbView/dgbView';
import CustomView from '../../visuals/customView/customView';
import componentTypes from '../../../../componentTypes';
import LiveView from '../../visuals/liveView/liveView';

export default (fleet, view, selectedTimeFrame, selectedCustomStartTime, selectedCustomDuration, navigateToView, user, editMode, cardsMetaconfig, viewConfig, isManualRefresh) => {
  if (!_.isNil(fleet) && !_.isNil(view)) {
    const viewType = view.type || view;
    switch (viewType) {
      case fleetMapViews.DASHBOARD:
        return <Dashboard fleet={fleet} navigateToView={navigateToView} user={user} editMode={editMode} cardsMetaconfig={cardsMetaconfig}/>;
      case fleetMapViews.COMPONENT:
        return <PumpOnlyFleetComponentView fleet={fleet}/>;
      case fleetMapViews.HEALTH:
        return <PumpOnlyFleetHealthView fleet={fleet}/>;
      case fleetMapViews.OPERATION:
        return <PumpOnlyFleetOperationView fleet={fleet}/>;
      case fleetMapViews.DGB:
        return <DgbView fleet={fleet} user={user}/>;
      case componentTypes.USER_VIEW:
        return <CustomView
          fleet={fleet}
          selectedTimeFrame={selectedTimeFrame}
          selectedCustomStartTime={selectedCustomStartTime}
          selectedCustomDuration={selectedCustomDuration}
          owner={componentTypes.FLEET_DASHBOARD}
          user={user}
          editMode={editMode}
          viewConfig={viewConfig}
          cardsMetaconfig={cardsMetaconfig}
          stateKey={view.id}
        />;
      case fleetMapViews.LIVE_VIEW:
        return <LiveView datavanPid={fleet.datavanPid} fleetId={fleet.id} fleetName={fleet.name} fleetUnitTypes={fleet.unitTypes} isManualRefresh={isManualRefresh}/>
      default:
        break;
    }
  }

  return <div/>;
};

