import fleetOpDGBMaintenanceCardActionTypes from '../fracOpDGBMaintenanceOverviewCardActionTypes';

const cardReducer = (state, action) => {
  switch (action.type) {

    case fleetOpDGBMaintenanceCardActionTypes.FRAC_OP_DGB_MAINTENANCE_OVERVIEW_CARD_OPEN_MENU:
      return onOpenMenu(state, action);

    case fleetOpDGBMaintenanceCardActionTypes.FRAC_OP_DGB_MAINTENANCE_OVERVIEW_CARD_CLOSE_MENU:

      return onCloseMenu(state, action);

    default:
      return state;

  }
};

const onOpenMenu = (state, action) => {
  return {
    ...state,
    openMenu: true,
    menuTargetElement: action.menuTargetElement,
  };
};

const  onCloseMenu = (state, action) => {
  return {
    ...state,
    openMenu: false,
    menuTargetElement: null,
  };
};

export default cardReducer