import React, { Component } from "react";
import PropTypes from "prop-types";
import getCardStyles from "../cardStyles";
import getLayoutStyles from "../../controls/layout/layoutStyles";
import getTypographyStyles from "../../common/styles/typographyStyles";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import SettingsIcon from '@mui/icons-material/Settings';

import * as appUserConfigActions from "../../../state/app/actions/appUserConfigActions";
import { appState as applicationState } from "../../../state/app/appSelectors";
import { getCardFromLayoutConfigViews } from "../../common/layout/layoutHelper";
import _ from "lodash";
const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();

const styles = {
    ...cardStyles,
    ...layoutStyles,
    ...typographyStyles,
    cardContainerReplace: {
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        backgroundColor: 'background.paper',
        borderRadius: '4px',
    },
    cardContainerReplaceHighLighted: {
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        backgroundColor: 'background.paper',
        borderRadius: '4px',
        border: '2px solid',
        borderColor: 'grey.600'
    },
    cardHeaderContent: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between'
    },
    cardHeaderLabel: {
        borderBottomColor: 'primary.main',
    },
    cardActionButton: {
        width: '24px',
        height: '24px',
        borderRadius: '4px',
        '& svg': {
            fontSize: '20px',
            color: 'grey.500'
        }
    }
};

/**
 * Base class for all MDT card components
 * This base class abstract the common rendered content along with some basic setting
 */
export class MDTCard extends Component {

    /**
     * Get name of the card
     */
    getName() {
        return 'Unknown';
    }

    /**
     * Get card title for showing as the card header
     */
    getTitle() {
        return this.props.card?.configuration?.title || this.getName();
    }

    /**
     * Get card configuration profile i.e. can resize, can select sensors, can rename etc.
     */
    getConfigurationProfile() {
        return {};
    }

    /**
     * Get configuration for the card
     */
    getConfiguration() {
        return {
            cardKey: this.props.cardKey,
            view: this.props.view,
            profile: this.getConfigurationProfile(),
            configuration: this.props.card?.configuration
        }
    }

    /**
     * Get card content for rendering
     */
    getRenderedContent() {
        return <Box>No Content</Box>;
    }

    /**
     * Get buttons in the card's header
     */
    getRenderedButtonsInHeader() {
        return <></>;
    }

    /**
     * Check if context is ready for reference
     */
    isContextReady() {
        return !_.isNil(this.props.context);
    }

    /**
     * Check if the card is in edit mode or not
     */
    isInEditMode() {
        return (!_.isNil(this.props.editMode) && this.props.editMode);
    }

    componentDidMount() {

        //Set the card title to default title if it's empty
        const card = getCardFromLayoutConfigViews(this.props.dashboards[this.props.dashboard]?.views, this.props.view, this.props.cardKey);
        if (_.isEmpty(card?.configuration?.title)) {
            this.props.onSetDefaultCardTitle(this.props.dashboard, this.props.view, this.props.cardKey, this.getTitle());
        }

        // Load the card data if we have a context.
        if (this.isContextReady() && (!this.isInEditMode())) {
            this.refresh();
        }
    }

    componentDidUpdate(prevProps) {
        // For all MDT Cards, refresh the card data when:
        // 1. We are not in edit mode and
        // 2. Either the context changes or we are exiting edit mode (context changing is not a factor when exiting edit mode)
        // NOTE: context is not a simple object - it can have many properties so that's why we do the deep compare using lodash
        //       instead of the previous !== operator
        // 3. Each card is configured with a canPeriodicRefresh property, which is set to true by default
        //    and can be configured to be false for a specific card
        // 4. For now, cards that cannot periodicRefresh handle their own refresh cases when component updates

      if (!_.isNil(this.props.metaConfiguration)) {
        const canPeriodicRefresh = this.props.metaConfiguration.canPeriodicRefresh;

        const refreshConditions = [
            !_.isEqual(prevProps.context, this.props.context),
            prevProps.editMode === true,
            !_.isEqual(prevProps.periodicRefreshTime, this.props.periodicRefreshTime),
            !_.isEqual(prevProps.manualRefreshTime, this.props.manualRefreshTime)
        ]

        if (canPeriodicRefresh === true && !this.isInEditMode() && refreshConditions.some(Boolean)) {
            this.refresh();
        }
        
      }  
    }

    refresh() { }

    render() {
        return (
            <Box sx={this.isInEditMode() && this.props.highlight === true ? styles.cardContainerReplaceHighLighted : styles.cardContainerReplace}>
                <Box key='header' sx={styles.cardHeader}>
                    <Box sx={styles.cardHeaderContent}>
                        <Typography sx={styles.cardHeaderLabel} variant={'subtitle1'}>{this.getTitle()}</Typography>
                        {this.getRenderedButtonsInHeader()}
                        {this.isInEditMode() && (
                            <Box sx={{ display: 'flex', flexFlow: 'row nowrap' }}>
                                {(this.props.editable && this.props.metaConfiguration?.configurable) && (
                                    <Tooltip title='Edit Setting' disableInteractive>
                                        <IconButton sx={styles.cardActionButton} size='small' onClick={() => { this.props.onEditCardConfig(this.props.dashboard, this.props.view, this.props.cardKey, this.getTitle(), this.props.stateKey); }}><SettingsIcon /></IconButton>
                                    </Tooltip>
                                )}
                                {this.props.removable && (
                                    <Tooltip title='Remove' disableInteractive>
                                        <IconButton sx={styles.cardActionButton} size='small' onClick={() => { this.props.onRemoveCard(this.props.dashboard, this.props.view, this.props.cardKey); }}><ClearIcon /></IconButton>
                                    </Tooltip>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
                {this.getRenderedContent()}
            </Box>);
    }
}

/**
 * Default MDT card proptypes
 */
export const mdtCardPropTypes = {
    //The old views don't passin the dashboard/view/cardKey yet.
    //These props will be required once all the old views complete conversion
    cardKey: PropTypes.string,
    dashboard: PropTypes.string,
    view: PropTypes.string,
    context: PropTypes.object.isRequired,
    editMode: PropTypes.bool,
    highlight: PropTypes.bool,
    removable: PropTypes.bool,
    editable: PropTypes.bool,
    cardQueryRunningCallback: PropTypes.func,
    metaConfiguration: PropTypes.object,
}

/**
 * Default MDT card State To Props
 */
export const mdtCardMapStateToProps = (state, props) => {
  let appState = applicationState(state);
  return {
      dashboards: appState.user.dashboards,
      //For some old views that have not yet been converted to init the card component in the new way, card props can be null if the component doesn't pass in dashboard/view/cardkey
      //When use this card prop, please do null check until all the old views complete conversion.
      card: getCardFromLayoutConfigViews(appState.user.dashboards[props.dashboard]?.views, props.view, props.cardKey),
      selectedCard: appState.user.dashboards[props.dashboard]?.selectedCard,
      periodicRefreshTime: appState.refreshTimestamp,
      manualRefreshTime: appState.manualRefreshTimestamp,
  }
};

/**
 * Default MDT card dispatch props
 */
export const mdtCardMapDispatchToProps = (dispatch, props) => {
    return {
        onRemoveCard: (dashboard, view, cardKey) => { dispatch(appUserConfigActions.removeCardFromLayout(dashboard, view, cardKey)); },
        onEditCardConfig: (dashboard, view, cardKey, defaultTitle, stateKey) => { dispatch(appUserConfigActions.editCardConfigFromLayout(dashboard, view, cardKey, defaultTitle, stateKey)); },
        onSetDefaultCardTitle: (dashboard, view, cardKey, defaultTitle) => { dispatch(appUserConfigActions.onSetDefaultCardTitle(dashboard, view, cardKey, defaultTitle)); },
    }
};