import _ from 'lodash';
import moment from 'moment';

const DEFAULT_SPECS = {

  CHART_WIDTH: 900,
  CHART_HEIGHT: 130,
  DATA_LINE_HEIGHT: 40,
  CHART_CENTER_LINE_HEIGHT: 1,

  SELECTION_WINDOW_HEIGHT: 95,
  PIXELS_BETWEEN_TICKS: 300,

  selectionWindowStart: 299,
  selectionWindowEnd: 599,

  HOVER_BUTTON_HEIGHT: 95,
  HOVER_BUTTON_WIDTH: 48,
  rightScrollOffset: 852,

  TICK_CONTAINER_HEIGHT: 35,
  TICK_WIDTH: 1,
  TICK_HEIGHT: 5,

  BLOCK_HEIGHT: 40,
};

const SPECS = { ...DEFAULT_SPECS };

const resetSpecs = () => {
  Object.assign(SPECS, DEFAULT_SPECS);
};

const updateSpecs = (customSpecs = {}) => {
  Object.keys(customSpecs).forEach(key => {
    const matchingKey = Object.keys(SPECS).find(
      specKey => specKey.toLowerCase() === key.toLowerCase()
    );

    if (matchingKey) {
      SPECS[matchingKey] = customSpecs[key];
    }
  });
};

const getLeftTime = (selectedDateTime, duration) => {

  if (_.isNil(selectedDateTime) || _.isNil(duration)) {
    return null;
  }

  return moment(selectedDateTime).subtract(duration.value, 'minutes');
};

const getRightTime = (selectedDateTime, duration) => {

  if (_.isNil(selectedDateTime) || _.isNil(duration)) {
    return null;
  }

  let minutesPerPixel = getMinutesPerPixel(duration);
  let totalTimeInMinutes = SPECS.CHART_WIDTH * minutesPerPixel;
  return moment(getLeftTime(selectedDateTime, duration)).add(totalTimeInMinutes, 'minutes');
};

const getPixelsPerMinute = (duration) => {

  if (_.isNil(duration) || _.isNil(duration.value)) {
    return null;
  }

  return (SPECS.CHART_WIDTH / 3) / duration.value
};

const getMinutesPerPixel = (duration) => {
  if (_.isNil(duration) || _.isNil(duration.value)) {
    return null;
  }

  return duration.value / (SPECS.CHART_WIDTH / 3);
};

const getTicksToRender = (dateTime, duration) => {

  let ticks = [];

  if (!_.isNil(dateTime) && !_.isNil(duration)) {

    let numberOfTicks = 4;
    let leftTime = getLeftTime(dateTime, duration);
    let adjustedTime = moment(leftTime);

    for (let i = 0; i < numberOfTicks; i++) {
      let isFirst = (i === 0);
      let isLast = (i === numberOfTicks-1);
      let tickPosition = isFirst ? 0 : (i * (SPECS.CHART_WIDTH / 3)) - 1;
      let label = adjustedTime.format('MMM DD hh:mm a');
      ticks.push({left: tickPosition, label: label, isFirst:isFirst, isLast:isLast, raw: adjustedTime.clone()});
      adjustedTime.add(duration.value, 'minutes');
    }
  }

  return ticks;
};

const getBlocksToRender = (dateTime, dataRanges, duration) => {

  if (_.isNil(dateTime) || _.isNil(dataRanges) || _.isNil(duration)) {
    return null;
  }

  let blocks = [];

  let leftTime = getLeftTime(dateTime, duration);
  let rightTime = getRightTime(dateTime, duration);

  for (let i=0, len=dataRanges.length; i<len; i++) {

    let range = dataRanges[i];

    let rangeStart = moment.unix(range.startTime);
    let rangeEnd = moment.unix(range.endTime);

    if ((rangeStart.isSameOrAfter(leftTime, 'minutes') && rangeStart.isSameOrBefore(rightTime, 'minutes')) ||
        (rangeEnd.isSameOrAfter(leftTime, 'minutes') && rangeEnd.isSameOrBefore(rightTime, 'minutes')) ||
        (leftTime.isSameOrAfter(rangeStart, 'minutes') && rightTime.isSameOrBefore(rangeEnd, 'minutes'))) {

      // This range fits try and render it!

      let startTime = rangeStart.isSameOrBefore(leftTime, 'minutes') ? leftTime : rangeStart;
      let diffStartMinutes = _.round(startTime.diff(leftTime, 'minutes', true));
      let startPixelPosition = diffStartMinutes <= 0 ? 0 : (diffStartMinutes * getPixelsPerMinute(duration));

      let endTime = rangeEnd.isSameOrAfter(rightTime, 'minutes') ? rightTime : rangeEnd;
      let diffEndMinutes = _.round(endTime.diff(startTime, 'minutes', true));
      let blockWidth = diffEndMinutes <= 0 ? 0 : diffEndMinutes * getPixelsPerMinute(duration);

      blocks.push({left: startPixelPosition, width: blockWidth});
    }

    // Check if we can render anything else
    if (rangeEnd.isSameOrAfter(rightTime, 'minutes')) {

      // Do not bother proceeding as all the rest of the ranges are beyond what
      // we are able to render.
      break;
    }
  }

  return blocks;
};

const getLatestEndTime = (dataRanges) => {
  if (!dataRanges || dataRanges.length === 0) {
    return null; // No data available
  }

  // Find the maximum endTime in dataRanges
  const latestEndTime = Math.max(...dataRanges.map(range => range.endTime));

  // Return as a moment object
  return moment.unix(latestEndTime);
};

const calculateDataInSelectionWindow = (selectedDateTime, selectedDuration, dataRanges) => {
  const selectionStartTime = selectedDateTime;
  const selectionEndTime = selectedDateTime.clone().add(selectedDuration.value, 'minutes')
  const dataRangesInSelection = dataRanges.filter(range => {
      const rangeStart = moment.unix(range.startTime);
      const rangeEnd = moment.unix(range.endTime);
      return (
          rangeEnd.isAfter(selectionStartTime) && // Ends after the selection starts
          rangeStart.isBefore(selectionEndTime)   // Starts before the selection ends
      );
  }).map(range => ({
      ...range,
      rangeStart: moment.unix(range.startTime), // Convert to moment
      rangeEnd: moment.unix(range.endTime),     // Convert to moment
  }));

  return dataRangesInSelection;
};

const getNextBlockEdge = (dateTime, dataRanges) => {

  if (!_.isNil(dateTime) && !_.isNil(dataRanges)) {

    // Find the next edge that is after the provided date/time

    for (let i=0, len=dataRanges.length; i<len; i++) {
      let range = dataRanges[i];
      if (moment.unix(range.startTime).isAfter(dateTime, 'minutes')) {
        return moment.unix(range.startTime);
      } else if (moment.unix(range.endTime).isAfter(dateTime, 'minutes')) {
        return moment.unix(range.endTime);
      }
    }
  }

  return null;
};

const getPreviousBlockEdge = (dateTime, dataRanges) => {

  if (!_.isNil(dateTime) && !_.isNil(dataRanges)) {

    // Find the next edge that is before the provided date/time

    for (let len=dataRanges.length, i=len-1; i>=0; i--) {
      let range = dataRanges[i];
      if (moment.unix(range.endTime).isBefore(dateTime, 'minutes')) {
        return moment.unix(range.endTime);
      } else if (moment.unix(range.startTime).isBefore(dateTime, 'minutes')) {
        return moment.unix(range.startTime);
      }
    }
  }

  return null;
};

export {
  SPECS,
  updateSpecs,
  resetSpecs,
  getLeftTime,
  getRightTime,
  getPixelsPerMinute,
  getTicksToRender,
  getBlocksToRender,
  getNextBlockEdge,
  getPreviousBlockEdge,
  getLatestEndTime,
  calculateDataInSelectionWindow
}