import _ from 'lodash';
import appActionTypes from '../appActionTypes';
import { appState } from '../appSelectors';

const initialState = appState();

const applicationReducer = (state=initialState, action) => {

  switch (action.type) {
    case appActionTypes.APP_OPEN_DRAWER:
      return { ...state, navigation: { ...state.navigation, drawerOpen: true } };
    case appActionTypes.APP_CLOSE_DRAWER:
      return { ...state, navigation: { ...state.navigation, drawerOpen: false } };
    case appActionTypes.APP_SET_OWNER:
      return onSetOwner(state, action);
    case appActionTypes.APP_DISPLAY_ERROR:
      // When we are asked to display an error, just set the error property in the state.
      // If no error is currently being displayed we should trigger the open transition.
      if (!state.errorHandling.openErrorUI && !state.errorHandling.errorUIOpening && !state.errorHandling.errorUIClosing) {
        return { ...state, errorHandling: { ...state.errorHandling, error: null, displayedError: action.error, openErrorUI: true, errorUIOpening: true } }
      }
      return { ...state, errorHandling: { ...state.errorHandling, error: action.error } };
    case appActionTypes.APP_HIDE_ERROR:
      // When we are asked to hide an error, just set the open state to false to
      // initiate the closing transition.
      return { ...state, errorHandling: { ...state.errorHandling, openErrorUI: false, errorUIOpening: false, errorUIClosing: true } };
    case appActionTypes.APP_ERROR_UI_OPENED:
      // When the error UI opening transition is complete, check to see if there is another
      // error that we need to display. If there is we need to start the close transition
      // for the currently displayed error. Google guidelines only allow displaying one Snackbar
      // message at a time and addition messages must wait until the close transition of a
      // previous message is completed.
      if (!_.isNil(state.errorHandling.error)) {
        return { ...state, errorHandling: { ...state.errorHandling, openErrorUI: false, errorUIOpening: false, errorUIClosing: true } };
      }
      return { ...state, errorHandling: { ...state.errorHandling, errorUIOpening: false, errorUIClosing: false } };
    case appActionTypes.APP_ERROR_UI_CLOSED:
      // When the error UI closing transition is complete, check to see if there is another
      // error that we need to display. Google guidelines only allow displaying one Snackbar
      // message at a time and addition messages must wait until the close transition of a
      // previous message is completed.
      if (!_.isNil(state.errorHandling.error)) {
        return { ...state, errorHandling: { ...state.errorHandling, displayedError: state.errorHandling.error, error: null, openErrorUI: true, errorUIOpening: true, errorUIClosing: false } };
      }
      return { ...state, errorHandling: { ...state.errorHandling, displayedError: null, openErrorUI: false, errorUIOpening: false, errorUIClosing: false } };
    case appActionTypes.APP_OPEN_USER_MENU:
      return { ...state, navigation: { ...state.navigation, userMenuOpen: true, userMenuTargetElement: action.userMenuTargetElement } };
    case appActionTypes.APP_CLOSE_USER_MENU:
      return { ...state, navigation: { ...state.navigation, userMenuOpen: false, userMenuTargetElement: null } };
    case appActionTypes.APP_CLEAR_USER_INFO:
      return {
        ...state,
        user: { ...state.user, ownersVisible: false, defaultRoute: null, routes:initialState.routes, loaded: false },
        owners: [],
        selectedOwner: null
      };

    case appActionTypes.APP_SET_NAVIGATION_CONTEXT:
      return onSetNavigationContext(state, action);
    case appActionTypes.APP_LOAD_USER_INFO_STARTING:
      return onLoadUserInformationStarting(state, action);
    case appActionTypes.APP_LOAD_USER_INFO_SUCCESS:
      return onLoadUserInformationSuccess(state, action);
    case appActionTypes.APP_LOAD_USER_INFO_ERROR:
      return onLoadUserInformationError(state, action);
    case appActionTypes.APP_SET_USER_INFO:
      return onSetUserInformation(state, action);
    case appActionTypes.APP_SET_OWNERS:
      return onSetOwners(state, action);
    case appActionTypes.APP_SET_USER_ROUTES:
      return onSetUserRoutes(state, action);
    case appActionTypes.APP_SET_SHOW_DEVELOPMENT_CONTENT:
      return onSetShowDevelopmentContent(state, action);
    case appActionTypes.APP_SET_SHOW_PRE_RELEASE_CONTENT:
      return onSetShowPreReleaseContent(state, action);
    case appActionTypes.APP_USER_PROFILE_SETTINGS_LOADED:
      return onUserProfileSettingsLoadSuccess(state, action);
    case appActionTypes.APP_USER_PROFILE_LASTWHATSNEW_UPDATED:
      return onUserProfileLastWhatsNewUpdated(state, action);
    case appActionTypes.APP_SHOW_WHATS_NEW:
      return onShowWhatsNew(state, action);
    case appActionTypes.APP_WHATS_NEW_PANEL_EXPANDED:
      return onWhatsNewPanelExpanded(state, action);

    case appActionTypes.APP_SHOW_SUBSCRIPTION_DIALOG:
      return onShowSubscriptionDialog(state, action);

    case appActionTypes.APP_SAVE_LANDING_PAGE_STARTING: 
      return { ...state, queryRunning: true };
    case appActionTypes.APP_SAVE_LANDING_PAGE_ERROR:
      return { ...state, queryRunning: false };
    case appActionTypes.APP_SAVE_LANDING_PAGE_SUCCESS:
      return onSaveLandingPageSuccess(state, action);

    case appActionTypes.APP_GET_AVAILABLE_DISPLAYS_STARTING: 
      return { ...state, queryRunning: true };
    case appActionTypes.APP_GET_AVAILABLE_DISPLAYS_ERROR:
      return { ...state, queryRunning: false };
    case appActionTypes.APP_GET_AVAILABLE_DISPLAYS_SUCCESS:
      return onGetAvailableDisplays(state, action);

    case appActionTypes.APP_CONTEXT_OPEN_DRAWER:
      return onOpenContextDrawer(state, action);
    case appActionTypes.APP_CONTEXT_SET_TYPE:
      return onSetContextType(state, action);
    case appActionTypes.APP_CONTEXT_SET:
      return onSetContext(state, action);
    case appActionTypes.APP_CONTEXT_MINIMIZE_DRAWER:
      return onMinimizeContextDrawer(state, action);
    case appActionTypes.APP_CONTEXT_SET_DRAWER_WIDTH:
      return onSetContextDrawerWidth(state, action);
    case appActionTypes.APP_REFRESH_TIMESTAMP:
      return onRefreshTimestamp(state, action);
    case appActionTypes.APP_REFRESH_MANUAL_TIMESTAMP:
      return onManualRefreshTimestamp(state, action);

    default:
      return state;
  }

};

const onSetNavigationContext = (state, action) => {

  if (_.isNil(action.contextKey)) {
    return state;
  }

  return {...state, navigation: {...state.navigation, context: {...state.navigation.context, [action.contextKey]: action.contextData }}}
};

const onLoadUserInformationStarting = (state, action) => {
  return {...state, user: {...state.user, loaded: false } }
};

const onLoadUserInformationSuccess = (state, action) => {
  return {...state, user: {...state.user, loaded: true } }
};

const onLoadUserInformationError = (state, action) => {
  return {...state, user: {...state.user, loaded: false } }
};

const onSetUserInformation = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      ownersVisible: action.info.ownersVisible,
      hasViewDevelopmentContentPermission: action.info.hasViewDevelopmentContentPermission,
      hasViewPreReleaseContentPermission: action.info.hasViewPreReleaseContentPermission,
      name: action.info.userClaims.name,
      email: action.info.userClaims.email,
      ownerId: action.info.userClaims.ownerId,
      role: action.info.userClaims.role,
      userId: action.info.userClaims.userId,
      isUserAdmin: action.info.isUserAdmin,
      hasFleetManagementPermission: action.info.hasFleetManagementPermission,
      hasDeviceSettingPermission: action.info.hasDeviceSettingPermission,
      hasJobApproverPermission: action.info.hasJobApproverPermission,
      hasDashboardAdminPermission: action.info.hasDashboardAdminPermission,
    }
  }
};

const onSetOwner = (state, action) => {
  return {
    ...state,
    selectedOwner: action.owner,
    user: {
      ...state.user,
      defaultRoute: initialState.user.defaultRoute,
      routes: initialState.user.routes,
      dashboards: initialState.user.dashboards,
    }
  }
};

const onSetOwners = (state, action) => {

  let defaultSelectedOwner = null;
  let owners = [];

  if (!_.isEmpty(action.owners)) {
    owners = _.orderBy(action.owners.owners, 'ownerName').map(owner => ({
      value: owner.id,
      label: owner.ownerName
    }));
    if (!_.isEmpty(owners)) {
      if (!_.isNil(action.defaultOwner)) {
        defaultSelectedOwner = _.find(owners, ['value', action.defaultOwner]);
      }
      defaultSelectedOwner = !_.isNil(defaultSelectedOwner) ? defaultSelectedOwner : owners[0];
    }
  }

  return {
    ...state,
    owners: owners,
    selectedOwner: defaultSelectedOwner
  }
};

const onSetUserRoutes = (state, action) => {

  let userState = _.cloneDeep(state.user); //avoid issue of deep nested property not reflected in the updated state
  userState.routes = action.routeDetails.routes;
  userState.defaultRoute = action.routeDetails.defaultRoute;

  return {
    ...state,
    user: userState
  }

};

const onSetShowDevelopmentContent = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      showDevelopmentContent: action.show,
      defaultRoute: initialState.user.defaultRoute,
      routes: initialState.user.routes,
    }
  }
};

const onSetShowPreReleaseContent = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      showPreReleaseContent: action.show,
      defaultRoute: initialState.user.defaultRoute,
      routes: initialState.user.routes,
    }
  }
};

const onUserProfileSettingsLoadSuccess = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      profileSettings: {
        lastWhatsNewDate : action.queryResults.userProfileSetting.lastWhatsNewDate,
        landingPage : JSON.parse(action.queryResults.userProfileSetting.landingPage),
      }
    }
  }
}

const onUserProfileLastWhatsNewUpdated = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      profileSettings: {
        ...state.user.profileSettings,
        lastWhatsNewDate : action.lastWhatsNewDate,
      }
    }
  }
}

const onShowWhatsNew = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      showWhatsNew: action.show
    }
  }
}

const onWhatsNewPanelExpanded = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      whatsNewPanelExpanded: action.panel
    }
  }
}

const onShowSubscriptionDialog = (state, action) => {
  return {
    ...state,
    notifications: {
      ...state.notifications,
      showSubscriptionDialog: action.show,
      subscriptionDialogContext: action.context
    }
  }
}

const onSaveLandingPageSuccess = (state, action) => {

  return {
    ...state,
    queryRunning: false,
    user: {
      ...state.user,
      profileSettings: {
        ...state.user.profileSettings,
        // Update the landing page to what the user selected
        landingPage: action.page,
      }
    }
  }
}

const onGetAvailableDisplays = (state, action) => {
  return {
    ...state,
    queryRunning: false,
    user: {
      ...state.user,
      availableDisplays: action.queryResults.availableDisplays
    }
  }
}

const onOpenContextDrawer = (state, action) => {
  return {
    ...state,
    context: {
      ...state.context,
      contextDrawerOpen: action.open,
      // Either the drawer is closed entirely or opened, it should no longer be minimized
      contextDrawerMinimized: action.open ? false : state.context.contextDrawerMinimized
    }
  }
}

const onSetContextType = (state, action) => {
  return {
    ...state,
    context: {
      ...state.context,
      contextType: action.contextType
    }
  }
}

const onSetContext = (state, action) => {
  return {
    ...state,
    context: {
      ...state.context,
      contextType: action.contextType,
      contextData: action.contextData
    }
  }
}

const onMinimizeContextDrawer = (state, action) => {
  return {
    ...state,
    context: {
      ...state.context,
      contextDrawerMinimized: true
    }
  }
}

const onSetContextDrawerWidth = (state, action) => {
  return {
    ...state,
    context: {
      ...state.context,
      contextDrawerWidth: action.width
    }
  }
}

const onRefreshTimestamp = (state, action) => {
  return {
    ...state, 
    refreshTimestamp: action.timestamp
  }
}

const onManualRefreshTimestamp = (state, action) => {
  return {
    ...state,
    manualRefreshTimestamp: action.timestamp
  }
}

export {
  applicationReducer as default,
  initialState,
}