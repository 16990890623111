import _ from "lodash";
import moment from "moment";

import * as filterService from '../../../common/filtering/filterService';
import { assetTypes } from "../../../common/services/assetTypeService";

import { onlineFilterOptions } from "../pumpDashboardSelectors";

const prettifyValue = (value) => {
  let noValue = '-';
  return !_.isNil(value) ? moment.utc(value*1000).fromNow() : noValue;
};

const resolveSelectedContext = (contexts, contextId) => {

  let selectedContext = null;

  // Validate that we have a context with the requests id
  if (!_.isNil(contextId) && !_.isEmpty(contexts)) {
    let match = _.find(contexts, { id: contextId });
    selectedContext = _.isNil(match) ? null : match;
  }

  // If we did not resolve a selected context, but we do have contexts, set it to the first element.
  if (_.isNil(selectedContext) && !_.isEmpty(contexts)) {
    selectedContext = contexts[0];
  }

  return selectedContext;
};

const processPumps = (pumps, filters) => {

  // Map the pumps into context objects for the dashboard UI
  let contexts = mapPumpsToContexts(pumps);
  // Filter the pump contexts by the current filters (in order)
  let filteredContexts = filterService.filterItems(contexts, filters);
  // Generate filter data from the filtered equipment list
  let updatedFilters = filterService.generateFilters(filteredContexts, filterDefinitions());

  return {
    contexts: filteredContexts,
    filters: updatedFilters
  };
};

const mapPumpsToContexts = (pumps) => {

  let contexts = [];

  if (!_.isNil(pumps) && !_.isEmpty(pumps)) {
    contexts = _.orderBy(pumps, 'name').map(pump => {
      return {
        ...pump,
        unitType: assetTypes.FRAC_PUMP,
        // We set the value and label to the same value to work around the text
        // search in the autocomplete control. The text search looks in both the
        // value and the label properties.
        value: pump.name,
        label: pump.name,
      }
    });
  }

  return contexts;
};

const filterDefinitions = () => {
  let definitions = [];
  definitions.push(filterService.createFilterDefinition('Fleet', 'fleetName'));
  definitions.push(filterService.createFilterDefinition('Region', 'region'));
  definitions.push(filterService.createExpressionFilterDefinition('Online',
    [
      filterService.createExpressionDefinition(onlineFilterOptions[0].label, (item) => { return moment().diff(moment.unix(item.lastModified), 'minutes') <= onlineFilterOptions[0].value; }),
      filterService.createExpressionDefinition(onlineFilterOptions[1].label, (item) => { return moment().diff(moment.unix(item.lastModified), 'minutes') <= onlineFilterOptions[1].value; }),
      filterService.createExpressionDefinition(onlineFilterOptions[2].label, (item) => { return moment().diff(moment.unix(item.lastModified), 'minutes') <= onlineFilterOptions[2].value; }),
      filterService.createExpressionDefinition(onlineFilterOptions[3].label, (item) => { return moment().diff(moment.unix(item.lastModified), 'minutes') <= onlineFilterOptions[3].value; }),
      filterService.createExpressionDefinition(onlineFilterOptions[4].label, (item) => { return moment().diff(moment.unix(item.lastModified), 'minutes') <= onlineFilterOptions[4].value; }),
      filterService.createExpressionDefinition(onlineFilterOptions[5].label, (item) => { return moment().diff(moment.unix(item.lastModified), 'minutes') > onlineFilterOptions[5].value; }),
      filterService.createExpressionDefinition(onlineFilterOptions[6].label, (item) => { return _.isNil(item.lastModified); })
    ]
  ))
  return definitions;
};

export {
  prettifyValue,
  resolveSelectedContext,
  processPumps,
  filterDefinitions
}