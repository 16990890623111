import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';
import dataGridActionTypes from './dataGridActionTypes';
import { sanitizeDefinition } from './services/dataGridService';
import { handleError } from '../../app/actions/appErrorActions';
import * as appContextActions from "../../app/actions/appContextActions";
import * as appUserConfigActions from "../../app/actions/appUserConfigActions";

import fetchDataGridCardData from './dataGridQueries';
import errorMessages from '../../common/errorMessages';
import { getCardFromLayoutConfigViews } from '../../../components/common/layout/layoutHelper';
import { fetchSensorSelectorSensors } from "../../common/sensorSelector/sensorSelectorQueries";
import { determineTruckFromDashboard } from '../../common/services/truckService';
import _ from "lodash";
import * as appWebsocketActions from "../../app/actions/appWebsocketActions";

const clearData = defaultActionFactory(dataGridActionTypes.DATA_GRID_CLEAR_DATA, 'stateDef');

const queryDataStarting = defaultActionFactory(dataGridActionTypes.DATA_GRID_QUERY_DATA_STARTING, 'stateDef');
const queryDataSuccess = defaultActionFactory(dataGridActionTypes.DATA_GRID_QUERY_DATA_SUCCESS, 'stateDef', 'queryResults');
const queryDataError = defaultActionFactory(dataGridActionTypes.DATA_GRID_QUERY_DATA_ERROR, 'stateDef');

const queryData = (stateDef, truck, sensors, dashboard) => {
  return async (dispatch, getState) => {
    try {

      let queryResults = null;
      await dispatch(queryDataStarting(stateDef));

      let truckForQuery = determineTruckFromDashboard(truck, dashboard);

      queryResults = await fetchDataGridCardData(truckForQuery, sensors);
      await dispatch(queryDataSuccess(stateDef, queryResults));

    } catch (e) {
      await dispatch(queryDataError(stateDef));

      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_DATA_GRID_DATA, e.message));
    }

  }
};

const querySensors = queryActionFactory(
  dataGridActionTypes.DATA_GRID_QUERY_DATA_STARTING,
  dataGridActionTypes.DATA_GRID_QUERY_SENSORS_SUCCESS,
  dataGridActionTypes.DATA_GRID_QUERY_DATA_ERROR,
  "Error retrieving sensors",
  fetchSensorSelectorSensors
);

const setDefinitionDefaultTruck = defaultActionFactory(dataGridActionTypes.DATA_GRID_DEFINITION_SET_DEFAULT_TRUCK, 'stateDef', 'truck', 'dashboard');
const setDefinitionStartTime = defaultActionFactory(dataGridActionTypes.DATA_GRID_DEFINITION_SET_START_TIME, 'stateDef', 'startTime', 'duration');
const setDefinitionSensors = defaultActionFactory(dataGridActionTypes.DATA_GRID_DEFINITION_SET_SENSORS, 'stateDef', 'sensors');

const openSensorSelector = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_OPEN_SENSOR_SELECTOR, 'stateDef');
const closeSensorSelector = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_CLOSE_SENSOR_SELECTOR, 'stateDef');
const setSelectedSensors = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_SET_SELECTED_SENSORS, 'stateDef', 'xAxisId', 'sensors');


const onSetSelectedSensors = (stateDef, xAxisId, sensors, dashboard, view, cardKey) => {
  return async (dispatch, getState) => {
    await dispatch(setSelectedSensors(stateDef, xAxisId, sensors));
  }
};

const updateSensorDisplayName = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_UPDATE_DISPLAY_NAME, 'stateDef', 'sensorSetId', 'alias', 'displayName');
const updateSensorUOM = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_UPDATE_UOM, 'stateDef', 'sensorSetId', 'alias', 'uom');
const updateSensorConditionalFormatting = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_UPDATE_CONDITIONAL_FORMATTING, 'stateDef', 'sensorSetId', 'alias', 'index', 'property', 'value');
const updateSensorBorderAndTextColor = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_SET_BORDER_AND_TEXT_COLOR, 'stateDef', 'sensorSetId', 'alias', 'borderAndTextColorRules');

const onDiscardDefinition = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_DISCARD_DEFINITION, 'stateDef', 'originalViews', 'view', 'cardKey');

const onCloseCardConfiguration = (dashboard, view, cardKey) => {
  return async (dispatch, getState) => {
    // Sanitize the definition before saving it to config
    let sanitizedDefinition = sanitizeDefinition(getState()[cardKey].definition);
    await dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, sanitizedDefinition));
    await dispatch(appContextActions.setContext(dashboard, {}));
    await dispatch(appContextActions.openContextDrawer(true, getState()[dashboard].configPanelWidth));
  }
};

const onDiscardCardConfiguration = (stateDef, dashboard, view, cardKey, cardType) => {
  return async (dispatch, getState) => {
    const originalViews = getState().app.user.dashboards[dashboard].originalConfigViews;
    await dispatch(onDiscardDefinition(stateDef, originalViews, view, cardKey));
    await dispatch(appUserConfigActions.onDiscardCardConfiguration(dashboard, view, cardKey, cardType));
  }
};

const onChangeCardSize = (stateDef, dashboard, view, cardKey, size) => {
  return async (dispatch, getState) => {
    await dispatch(appUserConfigActions.onChangeCardSize(dashboard, view, cardKey, size));
  }
};

const setCardView = defaultActionFactory(dataGridActionTypes.DATA_GRID_DEFINITION_SET_VIEW, 'stateDef', 'dataView');

const setColorPickerState = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_SET_COLOR_PICKER_STATE, 'stateDef', 'sensor', 'index', 'origColor');
const updateSensorsOrder = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_UPDATE_ORDER, 'stateDef', 'removedIndex', 'addedIndex');
const addDataGridSensorConditionalFormatting = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_ADD_CONDITIONAL_FORMATTING, 'stateDef', 'sensorSetId', 'alias',);
const removeDataGridSensorConditionalFormatting = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_REMOVE_CONDITIONAL_FORMATTING, 'stateDef', 'sensorSetId', 'alias', 'index');
const removeDataGridSensor = defaultActionFactory(dataGridActionTypes.DATA_GRID_CONFIG_REMOVE_SENSOR, 'stateDef', 'sensorSetId', 'alias', 'area');

const onUpdateSensorsOrder = (stateDef, removedIndex, addedIndex, dashboard, view, cardKey) => {
  return async (dispatch, getState) => {
    await dispatch(updateSensorsOrder(stateDef, removedIndex, addedIndex));
  }
};

const setupEditMode = defaultActionFactory(dataGridActionTypes.DATA_GRID_SETUP_EDIT_MODE, 'stateDef');

const onSetupEditMode = (stateDef, dashboard, view, cardKey) => {
  return async (dispatch, getState) => {
    await dispatch(setupEditMode(stateDef));
    await dispatch(appUserConfigActions.onUpgradeCardAddCanFreeSize(dashboard, view, cardKey));
  }
}

const createSubscription = (stateDef, truck, sensors, dashboard) => {
  return async (dispatch, getState) => {
    try {
      const sensorSetIds = _.map(sensors, (sensor) => sensor.sensorSetId);
      const uoms = _.map(sensors, (sensor) => sensor.uom);

      const truckToUse = determineTruckFromDashboard(truck, dashboard);
      if (!_.isNil(truckToUse.truckPid) && truckToUse.truckPid !== 0) {
        let payload = {
          clientId: getState().app.live.clientId,
          type: 'sub:latestvalue',
          stateDef: stateDef,
          payload: [{
            truckPid: truckToUse.truckPid,
            sensorSetIds: sensorSetIds,
            uoms: uoms
          }]
        };

        await dispatch(appWebsocketActions.sendMessage(JSON.stringify(payload)));

        // Save a "unsub" message we can use to close the subscription at the app level
        // so we can clean up subscriptions when the websocket connection is closed
        payload = {
          clientId: getState().app.live.clientId,
          type: 'unsub:latestvalue',
          stateDef: stateDef,
          payload: [truckToUse.truckPid]
        };

        await dispatch(appWebsocketActions.saveSubscription(JSON.stringify(payload)));
      }
    } catch (e) {
      return dispatch(handleError(errorMessages.ERROR_WEBSOCKET_SENDING_MESSAGE, e.message));
    }
  }
}

const closeSubscription = (stateDef, truck, dashboard) => {
  return async (dispatch, getState) => {
    try {
      const truckToUse = determineTruckFromDashboard(truck, dashboard);

      if (!_.isNil(truckToUse.truckPid) && truckToUse.truckPid !== 0) {
        const payload = {
          clientId: getState().app.live.clientId,
          type: 'unsub:latestvalue',
          stateDef: stateDef,
          payload: [truckToUse.truckPid]
        };
        await dispatch(appWebsocketActions.sendMessage(JSON.stringify(payload)));
      }
    } catch (e) {
      return dispatch(handleError(errorMessages.ERROR_WEBSOCKET_SENDING_MESSAGE, e.message));
    }
  }
}

export {
  queryData,
  clearData,
  querySensors,
  setDefinitionDefaultTruck,
  setDefinitionStartTime,
  setDefinitionSensors,
  setCardView,
  onSetupEditMode,
  openSensorSelector,
  closeSensorSelector,
  onSetSelectedSensors,
  updateSensorDisplayName,
  updateSensorUOM,
  updateSensorConditionalFormatting,
  updateSensorBorderAndTextColor,
  onCloseCardConfiguration,
  onDiscardCardConfiguration,
  onChangeCardSize,
  setColorPickerState,
  onUpdateSensorsOrder,
  addDataGridSensorConditionalFormatting,
  removeDataGridSensorConditionalFormatting,
  removeDataGridSensor,
  createSubscription,
  closeSubscription
}