import _ from 'lodash';
import moment from 'moment';

import ct from "countries-and-timezones";
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionCustomFactory from '../../common/factories/queryActionCustomFactory';
import exportActionTypes from './exportActionTypes';

import { fetchTrucks, fetchExportByTruck, fetchExportByTruckStatus, fetchTruckDetails } from './exportQueries';
import { handleError } from '../../app/actions/appErrorActions';
import errorMessages from '../../common/errorMessages';
import { createSensorsCollectionForExportInput } from './services/exportService';

const POLLING_TIMEOUT = 2000;

const queryTrucksStarting = defaultActionFactory(exportActionTypes.EXPORT_QUERY_TRUCKS_STARTING, 'stateDef');
const queryTrucksSuccess = defaultActionFactory(exportActionTypes.EXPORT_QUERY_TRUCKS_SUCCESS, 'stateDef', 'queryResults');
const queryTrucksError = defaultActionFactory(exportActionTypes.EXPORT_QUERY_TRUCKS_ERROR, 'stateDef');

const queryTrucks = queryActionCustomFactory(
  queryTrucksStarting,
  queryTrucksSuccess,
  queryTrucksError,
  errorMessages.ERROR_RETRIEVING_TRUCKS,
  fetchTrucks
);

const queryTruckDetailsStarting = defaultActionFactory(exportActionTypes.EXPORT_QUERY_TRUCK_DETAILS_STARTING, 'stateDef');
const queryTruckDetailsSuccess = defaultActionFactory(exportActionTypes.EXPORT_QUERY_TRUCK_DETAILS_SUCCESS, 'stateDef', 'queryResults');
const queryTruckDetailsError = defaultActionFactory(exportActionTypes.EXPORT_QUERY_TRUCK_DETAILS_ERROR, 'stateDef');

const queryTruckDetails = queryActionCustomFactory(
  queryTruckDetailsStarting,
  queryTruckDetailsSuccess,
  queryTruckDetailsError,
  errorMessages.ERROR_RETRIEVING_EXPORT_DATA_DETAILS,
  fetchTruckDetails
);

const selectTruck = defaultActionFactory(exportActionTypes.EXPORT_SELECT_TRUCK, 'stateDef', 'truckId');
const selectStartTime = defaultActionFactory(exportActionTypes.EXPORT_SELECT_DATE_TIME, 'stateDef', 'startTime');
const selectDuration = defaultActionFactory(exportActionTypes.EXPORT_SELECT_DURATION, 'stateDef', 'duration');
const selectTimezone = defaultActionFactory(exportActionTypes.EXPORT_SELECT_TIMEZONE, 'stateDef', 'timezone');
const findNext = defaultActionFactory(exportActionTypes.EXPORT_FIND_NEXT_RANGE, 'stateDef');
const findPrevious = defaultActionFactory(exportActionTypes.EXPORT_FIND_PREVIOUS_RANGE, 'stateDef');

const queryExportByTruckInfoStarting = defaultActionFactory(exportActionTypes.EXPORT_QUERY_EXPORT_BY_TRUCK_INFO_STARTING, 'stateDef');
const queryExportByTruckInfoSuccess = defaultActionFactory(exportActionTypes.EXPORT_QUERY_EXPORT_BY_TRUCK_INFO_SUCCESS, 'stateDef', 'results');
const queryExportByTruckInfoError = defaultActionFactory(exportActionTypes.EXPORT_QUERY_EXPORT_BY_TRUCK_INFO_ERROR, 'stateDef');

const initTimezones = (stateDef) => {
  return (dispatch, getState) => {
    const caData = ct.getCountryForTimezone('America/Edmonton');
    const usData = ct.getCountryForTimezone('America/Denver');
    const dateTimeOptions = Intl.DateTimeFormat().resolvedOptions();
    return Promise.resolve(
      dispatch({
        type: exportActionTypes.EXPORT_INIT_TIMEZONES,
        stateDef: stateDef,
        caTimezones: !_.isNil(caData) ? caData.timezones : null,
        usTimezones: !_.isNil(usData) ? usData.timezones : null,
        localTimezone: !_.isNil(dateTimeOptions) ? dateTimeOptions.timeZone : null,
        locale: !_.isNil(dateTimeOptions) ? dateTimeOptions.locale : null,
      })
    )
  }
};

const reloadDataForTruck = (stateDef, selectedTruck) => {
  return async (dispatch, getState) => {
    const currentDateTime = getState()[stateDef.key].selectedDateTime.unix();

    //selectedDateTime cannot be in future
    if(currentDateTime <= moment().unix()){
      // it should try reload once it out of portalAppService generated query period by left or right drag
      // it should try reload once input selectedDateTime out of portalAppService generated query period
      if (currentDateTime <= getState()[stateDef.key].queryStartTime || currentDateTime >= getState()[stateDef.key].queryEndTime ){
        await dispatch(queryTruckDetails(stateDef, selectedTruck, currentDateTime));
      }
    }

  }

};

const findNextWithDataReload = (stateDef) => {
  return async (dispatch, getState) => {

    await dispatch(findNext(stateDef));

    const selectedDateTime =  getState()[stateDef.key].selectedDateTime;
    const selectedTruck = getState()[stateDef.key].selectedTruck;
    let dataRanges = getState()[stateDef.key].dataRanges;

    if(!_.isEmpty(dataRanges)){
      let currentDateTime = selectedDateTime.unix();

      // it should try reload once it reach right end of current dataRange by right arrow click
      if ((currentDateTime >= _.last(dataRanges).endTime)){
        await dispatch(queryTruckDetails(stateDef, selectedTruck.id, selectedDateTime.unix()));
      }

    }

  }
};

const findPreviousWithDataReload = (stateDef) => {
  return async (dispatch, getState) => {

      await dispatch(findPrevious(stateDef));

      const selectedDateTime =  getState()[stateDef.key].selectedDateTime;
      const selectedTruck = getState()[stateDef.key].selectedTruck;
      const dataRanges = getState()[stateDef.key].dataRanges;

      if(!_.isEmpty(dataRanges)){
        const currentDateTime = selectedDateTime.unix();

        // it should try reload once it reach left end of current dataRange by left arrow click
        if ((currentDateTime <= _.head(dataRanges).startTime)){
          await dispatch(queryTruckDetails(stateDef, selectedTruck.id, selectedDateTime.unix()));
        }
      }

    }
};

/**
 * Action to keep checking for the status of an export and
 * dispatch appropriately. While the complete state for the
 * provided export is not complete this method will keep
 * polling for the status. When a complete status is detected
 * then it will trigger the status to be updated in the state
 * information.
 */
const pollExportStatus = (stateDef, exportId, timeout=POLLING_TIMEOUT) => {
  return async (dispatch, getState) => {
    let response = null;
    // Go get the status of the export.
    try {
      if (_.isNil(exportId)) {
        throw Error('Error: Invalid parameter (exportId)')
      }
      response = await fetchExportByTruckStatus(exportId);
    } catch(e) {
      await dispatch(queryExportByTruckInfoError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_EXPORTING, e.message));
    }
    if (_.get(response, 'exportByTruckStatus.error', false)) {
      // If the export error flag is set, cancel the export.
      await dispatch(queryExportByTruckInfoError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_EXPORTING, 'Export failure status returned from server'));
    } else if (_.get(response, 'exportByTruckStatus.complete', false)) {
      // If the export success flag is set, dispatch a success action.
      return dispatch(queryExportByTruckInfoSuccess(stateDef, response));
    } else {
      // Else continue to poll for the status.
      return new Promise( (resolve) => {
        setTimeout(() => { resolve(dispatch(pollExportStatus(stateDef, exportId))) }, timeout);
      });
    }
  }
};

/**
 * Action to perform a remote query for a file of exported data. This
 * is done in two steps. The first step is to initiate the export
 * which provides an export id and status. The second step is to keep
 * checking the status to determine when it is complete. See the
 * pollExportStatus for step 2 details.
 */
const exportByTruck = (stateDef, truckId, startTime, duration, timezone) => {
  return async (dispatch, getState) => {
    // Signal that the export is starting
    await dispatch(queryExportByTruckInfoStarting(stateDef));
    let exportId = null;
    // Initiate the export
    try {
      validateExportParameters(truckId, startTime, duration, timezone);
      
      // Determine which sensors to use in the export
      // If we have selected sensors, use those by transforming them into a format recognized by the export
      // If we have no selected sensors, then use all sensors
      let selectedSensors = getState()[stateDef.key].selectedSensors;

      let endTime = moment(startTime).add(duration, 'minutes');

      let response = await fetchExportByTruck(truckId, startTime, endTime, timezone, createSensorsCollectionForExportInput(selectedSensors));
      exportId = response.exportByTruck.exportId;
    } catch(e) {
      await dispatch(queryExportByTruckInfoError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_EXPORTING, e.message));
    }
    // Start polling the export status until it is ready for download.
    return dispatch(pollExportStatus(stateDef, exportId));
  }
};

const validateExportParameters = (truckId, startTime, duration, timezone) => {
  if (_.isNil(truckId)) {
    throw Error('Error: Invalid parameter (truckId)')
  }
  if (_.isNil(startTime)) {
    throw Error('Error: Invalid parameter (startTime)')
  }
  if (_.isNil(duration)) {
    throw Error('Error: Invalid parameter (duration)')
  }
  if (_.isNil(timezone)) {
    throw Error('Error: Invalid parameter (timezone)')
  }
};

const selectStartTimeDisplay = defaultActionFactory(exportActionTypes.EXPORT_SELECT_DATE_TIME_DISPLAY, 'stateDef', 'startTime');

const showSensorSelector = defaultActionFactory(exportActionTypes.EXPORT_SHOW_SENSOR_SELECTOR, 'stateDef', 'show');
const setSelectedSensors = defaultActionFactory(exportActionTypes.EXPORT_SET_SELECTED_SENSORS, 'stateDef', 'selectedSensors');

const selectNextTruck = defaultActionFactory(exportActionTypes.EXPORT_SELECT_NEXT_TRUCK, 'stateDef');
const selectPrevTruck = defaultActionFactory(exportActionTypes.EXPORT_SELECT_PREV_TRUCK, 'stateDef');

export {
  queryTrucks,
  queryTrucksStarting,
  queryTrucksSuccess,
  queryTrucksError,
  queryTruckDetails,
  pollExportStatus,
  exportByTruck,
  queryExportByTruckInfoSuccess,
  selectTruck,
  selectStartTime,
  selectDuration,
  selectTimezone,
  findNext,
  findPrevious,
  initTimezones,
  reloadDataForTruck,
  findPreviousWithDataReload,
  findNextWithDataReload,
  selectStartTimeDisplay,
  showSensorSelector,
  setSelectedSensors,
  selectNextTruck,
  selectPrevTruck,
}