import _ from 'lodash';
import { filterState } from '../../common/filtering/filterSelectors';
import pumpDashboardViews from './pumpDashboardViews';

// Values to classify a truck under a specific band in time for Filtering purposes
// The common Filter feature only allows for a single value for a specific filter
const onlineFilterOptions = [
  { value: 10, label: 'In the Last 10 minutes', order: 1 },
  { value: 60, label: 'In the Last Hour', order: 2 },
  { value: 360, label: 'In the Last 6 Hours', order: 3 },
  { value: 720, label: 'In the Last 12 Hours', order: 4  },
  { value: 1440, label: 'In the Last 24 Hours', order: 5 },
  { value: 1440, label: 'More than 24 Hours Ago', order: 6 },
  { value: -1, label: 'Never', order: 7 },
]

const pumpDashboardState = state => {

  let filterDetails = filterState(state);

  return {

    ...filterDetails,

    // The list of pump trucks (the available contexts for the cards)
    contexts: _.isNil(state) ? [] : state.contexts,

    // The full list of pumps returned from the service.
    pumps: _.isNil(state) ? [] : state.pumps,

    // The selected pump truck (the context for the cards)
    selectedContext: _.isNil(state) ? null : state.selectedContext,

    dataAgeActual: _.isNil(state) ? '' : state.dataAgeActual,
    dataAgeDisplay: _.isNil(state) ? '-' : state.dataAgeDisplay,
    selectedView: _.isNil(state) ? pumpDashboardViews.DASHBOARD : state.selectedView,

    timeFrames: _.isNil(state) ? [
      { value: 60, label: 'Last hour' },
      { value: 360, label: 'Last 6 hours' },
      { value: 720, label: 'Last 12 hours' },
      { value: 1440, label: 'Last 24 hours' },
      { value: null, label: 'Custom' },
    ] : state.timeFrames,

    customDurations: _.isNil(state) ? [
      { value: 60, label: 'Last hour' },
      { value: 360, label: '6 hours' },
      { value: 720, label: '12 hours' },
      { value: 1440, label: '24 hours' },
    ] : state.customDurations,

    selectedTimeFrame: _.isNil(state) ? { value: 360, label: 'Last 6 hours' } : state.selectedTimeFrame,
    selectedCustomStartTime: _.isNil(state) ? null : state.selectedCustomStartTime,
    selectedCustomDuration: _.isNil(state) ? { value: 360, label: 'Last 6 hours' } : state.selectedCustomDuration,

    selectedCustomStartTimeDisplay: _.isNil(state) ? null : state.selectedCustomStartTimeDisplay,

    queryRunning: _.isNil(state) ? false : state.queryRunning,

    editMode: _.isNil(state) ? false : state.editMode,
    showDialog: _.isNil(state) ? "" : state.showDialog,

    selectedCard: _.isNil(state) ? null : state.selectedCard,

    cardsList: _.isNil(state) ? [] : state.cardsList,

    configPanelWidth: 350,
    customViewsConfigPanelWidth: 400,

    customViewInput: _.isNil(state) ? '' : state.customViewInput,

    cardsQueryRunning: _.isNil(state) ? false : state.cardsQueryRunning,
    
    selectedCustomView: _.isNil(state) ? null : state.selectedCustomView,

  };

};

export {
  pumpDashboardState,
  onlineFilterOptions
};