import React, {Component} from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import moment from 'moment';
import {withProps} from 'recompose';

import DataExplorer from '../../controls/dataExplorer/dataExplorer';
import { Dialog, DialogContent, CircularProgress, Chip, Button, Paper, IconButton, Typography, Box, Icon, Badge, Tooltip } from '@mui/material';
import { AutoCompleteMDT } from '../../controls/mdtMuiControls';
import FilterDialog from '../../common/filtering/filterDialog';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import * as exportActions from '../../../state/displays/export/exportActions';
import * as filterActions from '../../../state/common/filtering/filterActions';
import { exportState } from '../../../state/displays/export/exportSelectors';
import { appState as applicationState } from '../../../state/app/appSelectors';
import ComponentTypes from "../../componentTypes";

import SensorSelector from '../../common/dataExploration/chart/sensorSelector';
import Transition from "../../controls/dialogSlideTransition";

import getDetailsPageStyles from '../../common/styles/detailsPageStyles';

import { trackPage } from '../../../helpers/googleAnalyticsHelper';

const detailsPageStyles = getDetailsPageStyles();

const styles = {
  ...detailsPageStyles,
  exportAnchor: {
    visibility: 'hidden'
  },
  dateTimeControl: {
    // NOTE: The padding below is a bit of a hack to
    // get the 3rd Party datetime picker to align
    // correctly with the other controls.
    paddingTop: '6px',
  },
  pageTitle: {
    alignSelf: 'center'
  },
  truckSelectionContainer: {
    display:'flex',
    alignItems: 'center',
    paddingBottom: 2
  },
  truckSelection: {
    width: '150px',
    marginLeft: 1,
  },
  filterLabel: {
    alignSelf: 'center',
    marginLeft: 3
  },
  filterComponent: {
    marginLeft: 1,
  },
  filterChip: {
    alignSelf: 'center',
    marginLeft: 1,
  },
  actionContent: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: 2
  },
  progressContent: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  progressIndicator: {
    margin: 3
  },
  formLabel: {
    marginTop: '4px'
  },
  formContent: {
    padding: 2
  },
  formControls: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingBottom: 2
  },
  timeSelection: {
    width: '200px'
  },
  timeZoneSelection: {
    width: '320px',
    paddingLeft: 3
  },
  maximumDurationLabel: {
    paddingRight: 2

  },
  dialogPaper: {
    minHeight: '840px',
    maxHeight: '840px',
  },
  truckNextPrevActions: {
    marginRight: 6
  }
};

class ExportPage extends Component {

  componentDidUpdate(prevProps) {
    if (prevProps.triggerExportByTruck === false && this.props.triggerExportByTruck === true) {
      this.exportAnchor.click();
    }
    if (_.isNil(prevProps.localTimezone) && !_.isNil(this.props.localTimezone)) {
      this.props.selectTimezone(this.props.localTimezone);
    }
    if (prevProps.selectedOwner !== this.props.selectedOwner) {
      this.props.queryTrucks();
    }
    if (prevProps.selectedTruck !== this.props.selectedTruck) {
      this.props.queryTruckDetails(this.props.selectedTruck.id, this.props.selectedDateTime.unix()); //initial load for truck data
    }
  }

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.EXPORT, this.props.user);

    // Initialize the time zone data.
    this.props.initTimezones();

    // Get the list of trucks..
    this.props.queryTrucks();

    // Set the default time.
    if (_.isNil(this.props.selectedDateTime)) {
      this.props.selectStartTime(moment().subtract(this.props.selectedDuration.value, 'minutes'));
      this.props.selectStartTimeDisplay(moment());
    }

  }

  render() {
    const onExportByTruck = () => {
      this.props.exportByTruck(this.props.selectedTruck.id, this.props.selectedDateTime, this.props.selectedDuration.value, this.props.selectedTimezone.value);
    };

    // Check for an empty filter collection here to keep the markup cleaner. This disables the add filter button.
    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));

    let sensorSelectorStateDef = getSensorSelectorStateDef(this.props);

    return (
      <Box sx={styles.detailsPage}>
        <Box sx={styles.detailsPageContent}>
          {/* Page Header */}
          <Box sx={styles.detailsPageHeader}>
            <Typography sx={{alignSelf: 'center'}} variant={'h6'}>Export</Typography>
            <Typography sx={{alignSelf: 'center', marginLeft: 3}} variant={'subtitle1'}>FILTER:</Typography>
            {
              this.props.appliedFilters.map((filterContext, index) => {
                return (
                  <Chip
                    sx={{  
                      alignSelf: 'center',
                      marginLeft: 1
                    }}
                    key={index}
                    label={filterContext.chipLabel}
                    onDelete={() => this.props.deleteFilterContext(index)}
                  />
                )
              })
            }
            <IconButton
              sx={{marginLeft: 1}}
              onClick={() => this.props.openFilterDialog()}
              disabled={emptyFilters}
              size="large"><AddCircleIcon/>
            </IconButton>
          </Box>
          {/* Truck Selection */}
          <Box sx={{
                display:'flex',
                alignItems: 'center',
                paddingBottom: 2
              }}>
            <Typography variant={'subtitle1'}>TRUCK:</Typography>
            <AutoCompleteMDT
              sx={{width: '150px', marginLeft: 1}}
              options={this.props.filteredTrucks}
              value={this.props.selectedTruck}
              onChange={(event, value, reason) => {this.props.selectTruck(value)}}
              noOptionsText={'No trucks found...'}            
              />
            <Box sx={styles.truckNextPrevActions}>
              <Tooltip title=
                      {
                      (!_.isEmpty(this.props.filteredTrucks) &&
                      _.first(this.props.filteredTrucks) === this.props.selectedTruck) ?
                      "No Prev Truck" : 
                      "Prev Truck"
                      }>
                <span>
                  <IconButton
                    disabled={
                      !_.isEmpty(this.props.filteredTrucks) &&
                      _.first(this.props.filteredTrucks) === this.props.selectedTruck
                    }
                    onClick={() => this.props.onSelectPrevTruck()}
                    size="large"
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={
                      (!_.isEmpty(this.props.filteredTrucks) &&
                      _.last(this.props.filteredTrucks) === this.props.selectedTruck) ?
                      "No Next Truck" : 
                      "Next Truck"
                      }>
                <span>
                  <IconButton
                    disabled={
                      !_.isEmpty(this.props.filteredTrucks) &&
                      _.last(this.props.filteredTrucks) === this.props.selectedTruck
                    }
                    onClick={() => this.props.onSelectNextTruck()}
                    size="large"
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
          {/* Form Container */}
          <Paper sx={{padding: 2}}>
            {/* Data Explorer*/}
            {/* There is an adjustment for the datetime from the DataExplorer such that the selectedDateTime is the endTime
                and the startTime is (selectedDateTime - selectedDuration) */}
            <DataExplorer
              isBusy={this.props.truckDetailsQueryRunning}
              dataRanges={this.props.dataRanges}
              durations={this.props.durations}
              selectedDateTime={this.props.selectedDateTime}
              selectedDuration={this.props.selectedDuration}
              onDateTimeChange={(dateTime) => {
                // This is the callback for when the user drags+scrolls with the mouse to select a new time range
                // The reason we are doing the adjustment for startTime + endTime differently here is because we want to respect the datetime that is selected
                // regardless if they advance or rewind time.
                // If we were to adjust the selectedTime by always subtracting the duration, then we would always net effect be rewinding time
                this.props.selectStartTime(dateTime);
                this.props.selectStartTimeDisplay(moment(dateTime).add(this.props.selectedDuration.value,'minutes').startOf('minute'));
                this.props.reloadDataForTruck(this.props.selectedTruck.id);
              }} //reload data only triggered if new selectedDateTime out of current query range
              onDurationChange={(duration) => this.props.selectDuration(duration)}
              onFindNext={() => this.props.findNextWithDataReload()} // data reload only trigger when no more data at left of current dataRange
              onFindPrevious={() => this.props.findPreviousWithDataReload()} // data reload only trigger when no more data at right of current dataRange
              reachedLeftEnd={this.props.reachedLeftEnd}
              selectedTruckId={this.props.selectedTruck}
              selectedCustomStartTimeDisplay={this.props.selectedDateTimeDisplay}
              setCustomStartTime={(value) => {
                // This is the callback for when the user selects a new selectedDateTime from the DateTimePicker dialog and clicks OK
                // Here we can adjust the selectedDateTime by subtracting the duration to get the startTime since we want the selectedDateTime to be the endTime
                this.props.selectStartTime(moment(value).subtract(this.props.selectedDuration.value,'minutes').startOf('minute'));
                this.props.selectStartTimeDisplay(value);
                this.props.reloadDataForTruck(this.props.selectedTruck.id);
              }}
              setChartStartTime={(value) => {/* There's no charting here so this has no effect for this display */ return; }} 
              setCustomStartTimeDisplay={(value) => this.props.selectStartTimeDisplay(value)}
              groupedTimezones={this.props.groupedTimezones}
              selectedTimezone={this.props.selectedTimezone}
              selectTimezone={(value) => this.props.selectTimezone(value)}
              customSpecs={{
                CHART_WIDTH: 1200,
                CHART_HEIGHT: 130,
                DATA_LINE_HEIGHT: 40,
              }}
            />
            {/* Form Actions */}
            <Box sx={{  
                display:'flex',
                flexGrow:1,
                alignItems:'center',
                justifyContent:'flex-end',
                paddingTop: 2
              }}>
              <Box sx={{
                display:'flex',
                flexGrow:1,
                justifyContent:'flex-start'
              }}>
                <Badge badgeContent={this.props.selectedSensors.length} color="primary" max={1000} invisible={_.isEmpty(this.props.dataRanges)}>
                  <Button variant='text'
                          color="primary"
                          size={"small"}
                          onClick={() => this.props.onShowSensorSelector()}
                          disabled={_.isEmpty(this.props.dataRanges)}>
                    Select Sensors
                    <Icon sx={{marginLeft: 1}} >edit_note</Icon>
                  </Button>
                </Badge>
              </Box>

              {
                this.props.exceededMaximumDuration &&
                !_.isNil(this.props.selectedSensors) &&
                !_.isEmpty(this.props.selectedSensors) &&
                <Typography sx={{paddingRight: 2}}>
                  This truck has {this.props.selectedSensors.length} selected sensors, the maximum export duration is {this.props.maximumDurationLabel}
                </Typography>
              }
              {
                _.isEmpty(this.props.selectedSensors) &&
                !_.isEmpty(this.props.dataRanges) &&
                <Typography sx={{paddingRight: 2}}>
                  Please select the sensors you want to include in your export
                </Typography>
              }
              <Button variant={'contained'} color='primary' disabled={!this.props.canExport} onClick={onExportByTruck}>Export</Button>
            </Box>
          </Paper>
          {/* Export Progress */}
          <Dialog open={this.props.dialogOpen} disableEscapeKeyDown>
            <DialogContent sx={{  
                display:'flex',
                flexFlow:'column',
                alignItems:'center',
                justifyContent:'center'
              }}>
              <Typography variant={'subtitle1'}>Generating Export File</Typography>
              <CircularProgress sx={{margin: 3}} size={80} />
              <Typography variant={'subtitle1'}>This can take several minutes...</Typography>
            </DialogContent>
          </Dialog>
          {/* Hidden Browser Export Anchor */}
          <Box component="a" sx={{visibility: 'hidden'}} ref={element => this.exportAnchor = element} href={this.props.exportByTruckStatus.exportZipUrl} download>exportAnchor</Box>
          {/* Hidden Filter Dialog */}
          <FilterDialog stateDef={this.props.stateDef}/>
          {/* Sensor Selector */}
          <Dialog maxWidth={'lg'}
            fullWidth={true}
            open={this.props.showSensorSelector}
            onClose={this.props.onCloseSensorSelector}
            TransitionComponent={Transition}
            PaperProps={{paper: styles.dialogPaper}}
          >
            <SensorSelector
              parentCallback={this.props.onSetSelectedSensors}
              initalSelectedSensors={this.props.selectedSensors}
              trucks={_.isNil(this.props.selectedTruck) ? [] : [this.props.selectedTruck.truckPid]}
              startTime={ _.isNil(this.props.selectedDateTime) ? null : this.props.selectedDateTime.unix() }
              endTime={ _.isNil(this.props.selectedDateTime) ? null : (this.props.selectedDateTime.unix() + (60 * this.props.selectedDuration.value)) }
              onClose={this.props.onCloseSensorSelector}
              stateKey={sensorSelectorStateDef.key}
              enableGroupEdit={false}
              namespace={'MDT'}
              unitType={this.props.selectedTruck?.type}
            />
          </Dialog>
        </Box>
      </Box>
    );
  }
}

function getSensorSelectorStateDef(props) {
  let sensorSelectorKey = ComponentTypes.SENSOR_SELECTOR + '_' + (_.isNil(props.stateKey) ? ComponentTypes.EXPORT : props.stateKey);
  let sensorSelectorStateDef = 
  {
    key: sensorSelectorKey,
    type: ComponentTypes.SENSOR_SELECTOR,
  }
  return sensorSelectorStateDef;
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.EXPORT : props.stateKey,
      type: ComponentTypes.EXPORT,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = exportState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    selectedOwner: appState.selectedOwner,
    filteredTrucks: componentState.filteredTrucks,
    selectedTruck: componentState.selectedTruck,
    durations: componentState.durations,
    selectedDuration: componentState.selectedDuration,
    selectedDateTime: componentState.selectedDateTime,
    exportByTruckStatus: componentState.exportJobState,
    triggerExportByTruck: componentState.triggerExportByTruck,
    dialogOpen: componentState.dialogOpen,
    canExport: componentState.canExport,
    groupedTimezones: componentState.timezones.groupedTimezones,
    localTimezone: componentState.timezones.localTimezone,
    selectedTimezone: componentState.selectedTimezone,
    dataRanges: componentState.dataRanges,
    truckDetailsQueryRunning: componentState.truckDetailsQueryRunning,
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    exceededMaximumDuration: componentState.exceededMaximumDuration,
    maximumDurationLabel: componentState.maximumDurationLabel,
    user: appState.user,
    selectedDateTimeDisplay: componentState.selectedDateTimeDisplay,
    showSensorSelector: componentState.showSensorSelector,
    selectedSensors: componentState.selectedSensors,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryTrucks: () => dispatch(exportActions.queryTrucks(props.stateDef)),
    queryTruckDetails: (truckId, startTime) => dispatch(exportActions.queryTruckDetails(props.stateDef, truckId, startTime)),
    exportByTruck: (truckId, startTime, duration, timezone) => dispatch(exportActions.exportByTruck(props.stateDef, truckId, startTime, duration, timezone)),
    selectTruck: (truckId) => dispatch(exportActions.selectTruck(props.stateDef, truckId)),
    selectStartTime: (startTime) => dispatch(exportActions.selectStartTime(props.stateDef, startTime)),
    selectDuration: (duration) => dispatch(exportActions.selectDuration(props.stateDef, duration)),
    initTimezones: () => dispatch(exportActions.initTimezones(props.stateDef)),
    selectTimezone: (timezone) => dispatch(exportActions.selectTimezone(props.stateDef, timezone)),
    findNextWithDataReload: () => dispatch(exportActions.findNextWithDataReload(props.stateDef)),
    findPreviousWithDataReload: () => dispatch(exportActions.findPreviousWithDataReload(props.stateDef)),
    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef))},
    deleteFilterContext: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index))},
    reloadDataForTruck: (truckId)  => dispatch(exportActions.reloadDataForTruck(props.stateDef, truckId)),
    selectStartTimeDisplay: (startTime) => dispatch(exportActions.selectStartTimeDisplay(props.stateDef, startTime)),
    onShowSensorSelector: () => dispatch(exportActions.showSensorSelector(props.stateDef, true)),
    onCloseSensorSelector: () => dispatch(exportActions.showSensorSelector(props.stateDef, false)),
    onSetSelectedSensors: (selectedSensors) => dispatch(exportActions.setSelectedSensors(props.stateDef, selectedSensors)),
    onSelectNextTruck: () => dispatch(exportActions.selectNextTruck(props.stateDef)),
    onSelectPrevTruck: () => dispatch(exportActions.selectPrevTruck(props.stateDef)),
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(ExportPage))