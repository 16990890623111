import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from "../../common/factories/queryActionFactory";
import errorMessages from '../../common/errorMessages';
import * as navActions from '../../app/actions/appNavActions';
import * as appUserConfigActions from '../../app/actions/appUserConfigActions';
import * as appContextActions from '../../app/actions/appContextActions';
import { handleError } from '../../app/actions/appErrorActions';
import pumpDashboardViews from './pumpDashboardViews';

import ComponentTypes from '../../../components/componentTypes';

import { fetchPumpDashboardDisplayData, fetchPumpDashboardDataAge, fetchSaveOwnerDashboard, fetchSaveUserDashboard, fetchPumpDashboardCards, fetchOwnerDefaultDashboardConfiguration } from "./pumpDashboardQueries";
import pumpDashboardActionTypes from './pumpDashboardActionTypes';

import * as filterActions from "../../common/filtering/filterActions.js"
import { parseUserConfigurationForPage } from '../../app/services/userConfigService.js';
import { filterDefinitions } from './services/pumpService.js';

const selectContext = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SELECT_CONTEXT, 'stateDef', 'context');
const selectView = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SELECT_VIEW, 'stateDef', 'view');
const onUpdateCustomViewInput = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_UPDATE_CUSTOM_VIEW_INPUT, 'stateDef', 'view', 'customViewInput');

const selectNavigationContext = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SELECT_NAVIGATION_CONTEXT, 'stateDef', 'context');

const selectNextPumpStart = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SELECT_NEXT_PUMP_START, 'stateDef');
const selectNextPumpEnd = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SELECT_NEXT_PUMP_END, 'stateDef');

const selectPrevPumpStart = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SELECT_PREV_PUMP_START, 'stateDef');
const selectPrevPumpEnd = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SELECT_PREV_PUMP_END, 'stateDef');

const selectNextPump = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(selectNextPumpStart(stateDef));

    await dispatch(selectNextPumpEnd(stateDef));
  }
}

const selectPrevPump = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(selectPrevPumpStart(stateDef));

    await dispatch(selectPrevPumpEnd(stateDef));
  }
}

const setTimeFrame = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SET_TIME_FRAME, 'stateDef', 'timeFrame');
const setCustomStartTime = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SET_CUSTOM_START_TIME, 'stateDef', 'startTime');
const setCustomDuration = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SET_CUSTOM_DURATION, 'stateDef', 'duration');

const clearDataAge = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_DATA_AGE_CLEAR_DATA, 'stateDef');
const refreshRelativeTime = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_DATA_AGE_REFRESH_RELATIVE, 'stateDef');

const queryData = queryActionFactory(
  pumpDashboardActionTypes.PUMP_DASHBOARD_QUERY_DATA_STARTING,
  pumpDashboardActionTypes.PUMP_DASHBOARD_QUERY_DATA_SUCCESS,
  pumpDashboardActionTypes.PUMP_DASHBOARD_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_PUMP_LIST,
  fetchPumpDashboardDisplayData
);

const queryDataAge = queryActionFactory(
  pumpDashboardActionTypes.PUMP_DASHBOARD_QUERY_DATA_AGE_STARTING,
  pumpDashboardActionTypes.PUMP_DASHBOARD_QUERY_DATA_AGE_SUCCESS,
  pumpDashboardActionTypes.PUMP_DASHBOARD_QUERY_DATA_AGE_ERROR,
  errorMessages.ERROR_RETRIEVING_DATA_AGE,
    fetchPumpDashboardDataAge
);

const loadDisplay = (stateDef) => {
  return async (dispatch, getState) => {

    await dispatch(loadCardsList(stateDef));

    // Check if there is a navigation context for this display in the app state
    let navigationContext = getState().app.navigation.context;
    let dashboardContext = _.isNil(navigationContext['/pump-dashboard']) ? null : navigationContext['/pump-dashboard'];
    if (!_.isNil(dashboardContext)) {
      // Clear out the context from the app state now that we have it
      await dispatch(navActions.setNavigationContext('/pump-dashboard', null));
    }

    const userId = getState()["app"].user.userId;
    const userConfigForPage = await dispatch(appUserConfigActions.queryUserConfigurationForPage(stateDef, userId, ComponentTypes.PUMP_DASHBOARD));

    if (!_.isEmpty(userConfigForPage)){
      const parsedUserConfig = parseUserConfigurationForPage(userConfigForPage, filterDefinitions());
      await dispatch(filterActions.onSetAppliedFilters(stateDef, parsedUserConfig));
    }

    // Execute our display query
    await dispatch(queryData(stateDef));

    // Set the selected context
    if (_.isNil(dashboardContext)) {
      await dispatch(selectContext(stateDef, getState()[stateDef.key].selectedContext));
    } else {
      await dispatch(selectNavigationContext(stateDef, dashboardContext));
    }

  }
};

const navigate = (unitId) => {
  return async (dispatch, getState) => {
    const url = '/pump-dashboard';
    const context = _.isNil(unitId) ? null : { unitId: unitId };
    return dispatch(navActions.navigate(url, context));
  }
};

const setCustomStartTimeDisplay = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SET_CUSTOM_START_TIME_DISPLAY, 'stateDef', 'startTime');
const showDialog = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SHOW_DIALOG, 'stateDef', 'show');

const editDashboard = (stateDef, isEdit, dashboard) => {
  return async(dispatch, getState) => {
    await dispatch(appContextActions.setContext(ComponentTypes.PUMP_DASHBOARD, {}));
    await dispatch(appContextActions.openContextDrawer(isEdit, getState()[stateDef.key].configPanelWidth));
    await dispatch(appUserConfigActions.onEditDashboard(isEdit, dashboard));
  }
};

const manageCustomViews = (stateDef, isManage, dashboard) => {
  return async(dispatch, getState) => {
    if(isManage){
      await dispatch(appContextActions.setContext(ComponentTypes.CONFIG_PANEL_PUMP_DASHBOARD_CUSTOM_VIEWS, {dashboard: dashboard}));
    }
    await dispatch(appContextActions.openContextDrawer(isManage, getState()[stateDef.key].customViewsConfigPanelWidth));
    await dispatch(appUserConfigActions.onManageDashboardCustomViews(isManage, dashboard));
  }
}

const undoChangesDialogOK = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(showDialog(stateDef, ""));
    await dispatch(editDashboard(stateDef, false));
    const userId = getState().app.user.userId;
    await dispatch(appUserConfigActions.queryUserConfigurationForDashboardLayout(null, userId));
  }
};

const dialogCancel = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(showDialog(stateDef, ""));
  }
};

const saveDashboardToOwnerStarting = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SAVE_DASHBOARD_TO_OWNER_STARTING, 'stateDef');
const saveDashboardToOwnerSuccess = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SAVE_DASHBOARD_TO_OWNER_SUCCESS, 'stateDef', 'queryResults');
const saveDashboardToOwnerError = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SAVE_DASHBOARD_TO_OWNER_ERROR, 'stateDef');

const saveDashboardToOwner = (stateDef, name) => {

  return async(dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(showDialog(stateDef, ""));

      await dispatch(saveDashboardToOwnerStarting(stateDef));

      // Only store system views 
      const configToSave = _.cloneDeep(getState().app.user.dashboards[name].views);
      _.remove(configToSave.views, function(view) {
        return view.type === "USER"
      });      

      // Setup the input object for the mutation
      let input = 
      {
        name: name,
        config: JSON.stringify(configToSave)
      }
      queryResults = await fetchSaveOwnerDashboard(input);

      await dispatch(saveDashboardToOwnerSuccess(stateDef, queryResults));

      // After saving, reload the user's dashboard config to reset them back to where they were before
      const userId = getState().app.user.userId;
      await dispatch(appUserConfigActions.queryUserConfigurationForDashboardLayout(null, userId));

      await dispatch(editDashboard(stateDef, false));
      await dispatch(manageCustomViews(stateDef, false, name));


    } catch(e) {
      await dispatch(saveDashboardToOwnerError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_DASHBOARD, e.message));
    }
  }
};

const saveDashboardToUserStarting = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SAVE_DASHBOARD_TO_USER_STARTING, 'stateDef');
const saveDashboardToUserSuccess = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SAVE_DASHBOARD_TO_USER_SUCCESS, 'stateDef', 'queryResults');
const saveDashboardToUserError = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_SAVE_DASHBOARD_TO_USER_ERROR, 'stateDef');

const saveDashboardToUser = (stateDef, name) => {

  return async(dispatch, getState) => {
    let queryResults = null;
    try {

      await dispatch(saveDashboardToUserStarting(stateDef));

      const userId = getState().app.user.userId;
      // Setup the input object for the mutation
      let input = 
      {
        userId: userId,
        name: name,
        config: JSON.stringify(getState().app.user.dashboards[name].views)
      }
      queryResults = await fetchSaveUserDashboard(input);

      await dispatch(saveDashboardToUserSuccess(stateDef, queryResults));

      // After saving, reload the user's dashboard config
      await dispatch(appUserConfigActions.queryUserConfigurationForDashboardLayout(null, userId));

      await dispatch(editDashboard(stateDef, false));
      await dispatch(manageCustomViews(stateDef, false, name));

    } catch(e) {
      await dispatch(saveDashboardToUserError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_DASHBOARD, e.message));
    }
  }
};

const loadCardsStarting = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_LOAD_CARDS_STARTING, 'stateDef');
const loadCardsSuccess = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_LOAD_CARDS_SUCCESS, 'stateDef', 'cardsList');
const loadCardsError = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_LOAD_CARDS_ERROR, 'stateDef');

const loadCardsList = (stateDef) => {

  return async(dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(loadCardsStarting(stateDef));

      queryResults = await fetchPumpDashboardCards();

      await dispatch(loadCardsSuccess(stateDef, queryResults));

    } catch(e) {
      await dispatch(loadCardsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_LOADING_CARDS_LIST, e.message));
    }
  }
};

const onRemoveCustomView = (stateDef, dashboard, viewId) => {
  return async(dispatch, getState) => {
    let selectedView = getState()[dashboard].selectedView;
    if (viewId === selectedView.id) {
      await dispatch(selectView(stateDef, pumpDashboardViews.DASHBOARD));
    }
    await dispatch(appUserConfigActions.onRemoveCustomView(dashboard, viewId));
  }
};

const restoreDefaultDashboardsStarting = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_RESTORE_DEFAULTS_STARTING, 'stateDef');
const restoreDefaultDashboardsSuccess = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_RESTORE_DEFAULTS_SUCCESS, 'stateDef', 'queryResults');
const restoreDefaultDashboardsError = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_RESTORE_DEFAULTS_ERROR, 'stateDef');

const restoreDefaultDialogOK = (stateDef, dashboard, view) => {

  return async(dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(showDialog(stateDef, ""));

      await dispatch(restoreDefaultDashboardsStarting(stateDef));
      queryResults = await fetchOwnerDefaultDashboardConfiguration();
      await dispatch(restoreDefaultDashboardsSuccess(stateDef, queryResults));

      // Update dashboard with default version of current view 
      await dispatch(appUserConfigActions.onRestoreDefaultView(dashboard, view, queryResults));

    } catch(e) {
      await dispatch(restoreDefaultDashboardsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RESTORING_DEFAULTS, e.message));
    }
  }
};

const discardCustomViewConfiguration = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_DISCARD_CUSTOM_VIEW_CONFIGURATION, 'stateDef', 'originalViewConfig');

const onDiscardCustomViewConfiguration = (stateDef) => {
  return async(dispatch, getState) => {
    const originalDashboardConfigViews = getState().app.user.dashboards[ComponentTypes.PUMP_DASHBOARD].originalConfigViews;
    await dispatch(appUserConfigActions.onDiscardCustomViewConfiguration(ComponentTypes.PUMP_DASHBOARD));
    await dispatch(discardCustomViewConfiguration(stateDef, originalDashboardConfigViews));
  }
};

const onChangeCustomViewName = (stateDef, dashboard, view, name) => {
  return async(dispatch, getState) => {
    await dispatch(appUserConfigActions.onChangeCustomViewName(dashboard, view.id, name));
    await dispatch(onUpdateCustomViewInput(stateDef, view, name));
  }
};

const onCardsQueryRunning = defaultActionFactory(pumpDashboardActionTypes.PUMP_DASHBOARD_CARDS_QUERY_RUNNING, 'stateDef', 'queryRunning');

export {
  queryData,
  queryDataAge,
  clearDataAge,
  refreshRelativeTime,
  selectContext,
  selectView,
  onUpdateCustomViewInput,
  selectNavigationContext,
  selectPrevPump,
  selectNextPump,
  loadDisplay,
  setTimeFrame,
  setCustomStartTime,
  setCustomDuration,
  navigate,
  setCustomStartTimeDisplay,
  editDashboard,
  manageCustomViews,
  showDialog,
  undoChangesDialogOK,
  dialogCancel,
  saveDashboardToOwner,
  saveDashboardToUser,
  loadCardsList,
  onRemoveCustomView,
  onDiscardCustomViewConfiguration,
  onChangeCustomViewName,
  onCardsQueryRunning,
  restoreDefaultDialogOK
}
