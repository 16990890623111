import _ from 'lodash';
import moment from 'moment';

import { ELabelPlacement } from "scichart/types/LabelPlacement";

import { MdtYAxisPosition } from '../../../../../common/dataExplorationChart/mdtYAxisPosition';
import { mdtPalette } from "../../../../../../components/common/styles/mdtPalette";
import { dataDisplayModes } from '../liveViewSelectors';

/**
 * Filter the trucks by finding the trucks that have the selected label
 * @param {*} trucks List of all Trucks (can be either from a query or what we have in the state)
 * @param {*} truckFilter The desired label to filter by
 * @returns All trucks that have the selected label sorted in order of slot number with a color assigned to them
 */
const processTrucksForLiveView = (trucks, truckFilter) => {

  const filteredTrucks = filterTrucks(trucks, truckFilter);

  return setColorForTrucks(_.sortBy(filteredTrucks, ['slotNumber']));
}

/**
 * Given a list of trucks and a truck filter, filter the trucks based on the filter
 * @param {*} trucks List of all Trucks (can be either from a query or what we have in the state)
 * @param {*} truckFilter The desired label to filter by
 * @returns All trucks that have the selected label
 */
const filterTrucks = (trucks, truckFilter) => {

  if (truckFilter.value === 'all') {
    return trucks;
  }

  const filteredTrucks = _.filter(trucks, (truck) => {
    return _.includes(truck.labels, truckFilter.value);
  });

  return filteredTrucks;
}

/**
 * Assigns a color to each truck in the list of trucks
 * @param {*} trucks List of trucks
 * @returns The list of trucks, with each truck assigned a color
 */
const setColorForTrucks = (trucks) => {
  
  const colors = generateListOfColors();
  // Reserve the colour for average truck
  const averageTruckColor = colors.pop();
  
  _.forEach(trucks, (truck, index) => {
    if (truck.truckPid === 0) {
      trucks[index].color = averageTruckColor;
    } else {
      truck.color = colors.pop();
    }
  });

  return trucks;
}

/**
 * Create an initial data set for the given sensors and trucks
 * @param {*} trucks List of trucks
 * @param {*} sensor List of sensors
 * @returns Initial data set
 */
const generateInitialDataSet = (trucks, sensors) => {

  const data = [];

  const slotSensor =
  [
    {
      rowId: '0_slotNumber',
      sensorSetId: 0,
      alias: 'slotNumber',
      displayName: 'Slot Number',
      uom: '#',
      color: '#000000',
      axisPosition: -1,
      targetUoms: [],
      conditionalFormatting: {
        applied: false,
        rules: []
      },
      isVisible: true
    }
  ]

  const sensorsWithSlot = _.concat(slotSensor, sensors);

  _.forEach(sensorsWithSlot, (sensor) => {

    let sensorRow = {
      rowId: sensor.sensorSetId + '_' + sensor.alias,
      sensorSetId: sensor.sensorSetId,
    }

    _.forEach(trucks, (truck) => {
      if (sensor.sensorSetId === 0) {
        sensorRow[truck.truckPid] = truck.slotNumber;
      } else {
        sensorRow[truck.truckPid] = null;
      }
    });

    data.push(sensorRow);

  })

  return data;

}

/**
 * Updates the given definition with the trucks (contexts)
 * @param {*} definition Chart Definition object
 * @param {*} trucks Selected Trucks
 * @returns Updated Chart Definition object
 */
const updateDefinitionWithTrucks = (definition, trucks, displayModeToggle) => {

  const newDefinition = JSON.parse(JSON.stringify(definition));

  newDefinition.primary.contexts = [];
  newDefinition.secondary.contexts = [];

  _.forEach(trucks, (truck, index) => {
    newDefinition.primary.contexts.push({
      name: truck.truckName,
      id: truck.truckPid,
      visible: true,
      color: truck.color,
      value: '-'
    });
  });

  newDefinition.secondary.contexts = JSON.parse(JSON.stringify(newDefinition.primary.contexts));
  // By Default secondary contexts are hidden
  if (displayModeToggle === dataDisplayModes[0]) {
    _.forEach(newDefinition.secondary.contexts, (context) => {
      context.visible = false;
    });
  } else {
    _.forEach(newDefinition.primary.contexts, (context) => {
      context.visible = true;
    });
  }

  return newDefinition;
}

/**
 * Updates the given definition with the selected sensor
 * @param {*} definition Chart Definition object
 * @param {*} sensor Selected Sensor
 * @returns Updated Chart Definition object
 */
const updateDefinitionWithSensor = (definition, sensor) => {

  const newDefinition = JSON.parse(JSON.stringify(definition));

  newDefinition.primary.sensors = [];
  newDefinition.secondary.sensors = [];
  newDefinition.primary.sensors.push({...sensor, yAxisId: MdtYAxisPosition.LeftInner});
  newDefinition.secondary.sensors.push({...sensor, yAxisId: MdtYAxisPosition.LeftInner});

  return newDefinition;

}

/**
 * Toggles the visibility of a truck context in the given definition based on the data display mode.
 *
 * @param {Object} definition - The current definition object containing primary and secondary contexts.
 * @param {string} truckPid - The unique identifier of the truck.
 * @param {string} dataDisplayModeToggle - The current data display mode toggle value.
 * @param {Array<string>} dataDisplayModes - An array of possible data display modes.
 * @returns {Object} The new definition object with the updated visibility of the truck context.
 */
const toggleContextVisibility = (definition, truckPid, dataDisplayModeToggle, dataDisplayModes) => {

  const newDefinition = JSON.parse(JSON.stringify(definition));

  if (dataDisplayModeToggle === dataDisplayModes[0]) {
    const truck = _.find(newDefinition.primary.contexts, ['id', truckPid]);
    truck.visible = !truck.visible;
  }
  else if (dataDisplayModeToggle === dataDisplayModes[1]) {
    const truckSecondary = _.find(newDefinition.secondary.contexts, ['id', truckPid]);
    truckSecondary.visible = !truckSecondary.visible;
  }

  return newDefinition;
}

/**
 * Toggles the visibility of all other trucks except the specified one in the given definition.
 *
 * @param {Object} definition - The current definition object containing truck contexts.
 * @param {string} truckPid - The ID of the truck to remain visible.
 * @param {boolean} isVisible - The visibility state to set for all other trucks.
 * @param {string} dataDisplayModeToggle - The current data display mode toggle.
 * @param {Array<string>} dataDisplayModes - The array of possible data display modes.
 * @returns {Object} - The new definition object with updated visibility states.
 */
const toggleContextVisibilityAllOthers = (definition, truckPid, isVisible, dataDisplayModeToggle, dataDisplayModes) => {

  const newDefinition = JSON.parse(JSON.stringify(definition));

  if (dataDisplayModeToggle === dataDisplayModes[0]) {
    _.forEach(newDefinition.primary.contexts, (truck) => {
      // Set all other trucks to not visible
      if (truck.id !== truckPid) {
        truck.visible = isVisible;
      } else {
      // Set the clicked on truck to visible 
        truck.visible = true;
      }
    });
  }
  else if (dataDisplayModeToggle === dataDisplayModes[1]) {
    _.forEach(newDefinition.secondary.contexts, (truck) => {
      // Set all other trucks to not visible
      if (truck.id !== truckPid) {
        truck.visible = isVisible;
      } else {
      // Set the clicked on truck to visible 
        truck.visible = true;
      }
    });
  }

  return newDefinition;
}

/**
 * Toggles the visibility of all trucks after a specified truck in the context list.
 *
 * @param {Object} definition - The current definition object containing primary and secondary contexts.
 * @param {string} truckPid - The ID of the truck after which visibility should be toggled.
 * @param {boolean} isVisible - The visibility state to set for the trucks.
 * @param {string} dataDisplayModeToggle - The current display mode toggle.
 * @param {Array<string>} dataDisplayModes - The array of possible display modes.
 * @returns {Object} The updated definition object with modified visibility for the trucks.
 */
const toggleContextVisibilityOtherAfter = (definition, truckPid, isVisible, dataDisplayModeToggle, dataDisplayModes) => {
  const newDefinition = JSON.parse(JSON.stringify(definition));

  if (dataDisplayModeToggle === dataDisplayModes[0]) {
    const truckIndex = _.findIndex(newDefinition.primary.contexts, ['id', truckPid]);
    const allOtherTrucksAfter = newDefinition.primary.contexts.slice(truckIndex + 1);
    if (!_.isEmpty(allOtherTrucksAfter)) {
      _.forEach(allOtherTrucksAfter, (truck) => {
        truck.visible = isVisible;
      });
    }
  }
  else if (dataDisplayModeToggle === dataDisplayModes[1]) { 
    const truckIndexSecondary = _.findIndex(newDefinition.secondary.contexts, ['id', truckPid]);
    const allOtherTrucksAfterSecondary = newDefinition.secondary.contexts.slice(truckIndexSecondary + 1);
    if (!_.isEmpty(allOtherTrucksAfterSecondary)) {
      _.forEach(allOtherTrucksAfterSecondary, (truck) => {
        truck.visible = isVisible;
      });
    }
  }

  return newDefinition;
}

/**
 * This used to do a randomization of the colors but for now, we leave it in the order as they are in the mdtPalette.charting collection
 * @returns List of color values from the mdtPalette.charting collection
 */
const generateListOfColors = () => {

  let colors = _.values(mdtPalette().charting);
  return colors;
}

/**
 * Sets the color for a truck in both primary and secondary contexts within the definition.
 *
 * @param {Object} definition - The original definition object containing primary and secondary contexts.
 * @param {string} truckPid - The unique identifier of the truck.
 * @param {string} color - The color to set for the truck.
 * @returns {Object} - A new definition object with the updated truck color.
 */
const setContextColor = (definition, truckPid, color) => {

  const newDefinition = JSON.parse(JSON.stringify(definition));

  const truck = _.find(newDefinition.primary.contexts, ['id', truckPid]);
  truck.color = color;
  
  const truckSecondary = _.find(newDefinition.secondary.contexts, ['id', truckPid]);
  truckSecondary.color = color;

  return newDefinition;
}

/**
 * Adds or updates an annotation for a given rule in the provided definition.
 *
 * @param {Object} definition - The definition object containing primary and secondary annotations.
 * @param {Object} rule - The rule object containing the annotation details.
 */
const addAnnotationForRule = (definition, rule) => {

  const newAnnotation = {
    id: rule.id,
    y1: rule.value1+'',
    labelPlacement: ELabelPlacement.Axis,
    stroke: rule.color,
    strokeThickness: 5,
    axisLabelFill: rule.color
  }
  if (rule.condition.includes('between')) {
    newAnnotation.y2 = rule.value2+'';
  }

  const foundAnnotation = _.find(definition.primary.annotations, ['id', rule.id]);

  if (_.isNil(foundAnnotation)) {
    definition.primary.annotations.push(newAnnotation);
  } else {
    foundAnnotation.y1 = rule.value1+'';
    // Clear out any previous y2 values in case we are moving  from a between condition to a non between condition
    foundAnnotation.y2 = ''; 
    foundAnnotation.stroke = rule.color;
    foundAnnotation.axisLabelFill = rule.color;

    if (rule.condition.includes('between')) {
      foundAnnotation.y2 = rule.value2+'';
    }
  }

  const foundAnnotationSecondary = _.find(definition.secondary.annotations, ['id', rule.id]);

  if (_.isNil(foundAnnotationSecondary)) {
    definition.secondary.annotations.push(newAnnotation);
  } else {
    foundAnnotationSecondary.y1 = rule.value1+'';
    // Clear out any previous y2 values in case we are moving  from a between condition to a non between condition
    foundAnnotationSecondary.y2 = ''; 
    foundAnnotationSecondary.stroke = rule.color;
    foundAnnotationSecondary.axisLabelFill = rule.color;

    if (rule.condition.includes('between')) {
      foundAnnotationSecondary.y2 = rule.value2+'';
    }
  }
}

/**
 * Processes a list of sensors by comparing them with existing sensors and updating or adding them as necessary.
 *
 * @param {Array} existingSensors - The array of existing sensor objects.
 * @param {Array} sensors - The array of new sensor objects to process.
 * @returns {Array} - The array of processed sensor objects.
 */
const processSensors = (existingSensors, sensors) => {

  let processedSensors = [];

  _.forEach(sensors, (sensor) => {

    const foundSensor = _.find(existingSensors, {'sensorSetId':sensor.sensorSetId, 'alias':sensor.alias});

    if (!_.isNil(foundSensor)) {
      // The UoM might have changed
      if (sensor.uom !== foundSensor.uom) {
        foundSensor.uom = sensor.uom;
      }
      if (_.isNil(foundSensor.rowId)) {
        foundSensor.rowId = sensor.sensorSetId + '_' + sensor.alias;
      }
      processedSensors.push(foundSensor);
    } else {
      processedSensors.push({
        ...sensor,
        rowId: sensor.sensorSetId + '_' + sensor.alias,
        displayName: !_.isNil(sensor.displayName) ? sensor.displayName : sensor.alias,
        isVisible: !_.isNil(sensor.isVisible) ? sensor.isVisible : true,
        conditionalFormatting: !_.isNil(sensor.conditionalFormatting) ? 
          sensor.conditionalFormatting :
          {
            applied: false,
            rules: []
          }
      });
    }
  });

  return processedSensors;
}

/**
 * Takes the data we have in the Chart and transforms it to a collection that can be easily
 * converted to a text file.
 * Needs to take truck name and use them as headers and have timestamps as a column.
 * Output format should be something like
 * 
 * timestamps             truckName   truckName   truckName   ...
 * '01/01/2021 00:00:00'  1           2           3           ...
 * '01/01/2021 00:00:01'  2           3           4           ...
 * ...                                
 * @param xValues The collection of xValues (timestamps)
 * @param yValues The collection of yValues (single sensor values for each truck)
 * @param visibleRange The current visible range of the chart
 * @param trucks List of selected trucks
 */
const transformDataToJson = (xValues, yValues, visibleRange, trucks) => {
  const outputJson = {};

  const yValuesToRemove = [];
  // Remove the xValues that are not in the visible time range
  // We only need to worry about the primary set because we only have 1 xValues set for both primary(absolute) and secondary(relative) yValues
  _.remove(xValues, (value, index) => {
    if (value < visibleRange.x.primary.min || value > visibleRange.x.primary.max) {
      yValuesToRemove.push(index);
      return true;
    }
  });

  // Remove the yValues that are not in the visible time range for each truck
  _.forEach(yValues, (yValue) => {
    _.remove(yValue, (value, index) => {
      return _.includes(yValuesToRemove, index);
    });
  });

  _.forEach(xValues, (xValue, index) => {

    let itemRow = {};
    itemRow['timeStamp'] = moment.unix(xValue/1000);

    _.forEach(trucks, (truck, truckIndex) => {
      if (truck.visible === true) {
        const value = yValues[truck.id][index];
        itemRow[truck.name] = (_.isNil(value) || _.isNaN(value)) ? '' : value;
      }
    });

    outputJson[index] = itemRow;

  });

  return outputJson;
}

/**
 * Creates a message object to close a subscription.
 *
 * @param {string} clientId - The ID of the client.
 * @param {Object} stateDef - The state definition object.
 * @param {Object} data - Additional data related to the subscription.
 * @returns {Object} The message object to close the subscription.
 */
const getCloseSubscriptionMessage = (clientId, stateDef, data) => {
  return {
    clientId: clientId,
    type: 'unsub:latestvalue',
    stateDef: stateDef,
    payload: data
  }
}

/**
 * Calculates the average value at a specific time index for a given set of trucks.
 *
 * @param {Array} trucks - An array of truck objects.
 * @param {Object} yValues - An object containing y-values for each truck, indexed by truckPid.
 * @param {number} timeIndex - The index of the time for which the average value is calculated.
 * @returns {number} The average value at the specified time index, rounded to two decimal places.
 */
const calculateAverageAtTimeIndex = (trucks, yValues, timeIndex) => {

  let newAverageValueSum = 0;
  let numOfTrucksContributing = 0;
  _.forEach(trucks, (truck) => {
    if (!_.isNil(yValues) && !_.isNil(yValues[truck.truckPid])) {
      const foundTruckValue = yValues[truck.truckPid][timeIndex];
      if (!_.isNil(foundTruckValue) && !isNaN(foundTruckValue)) {
        newAverageValueSum += foundTruckValue;
        numOfTrucksContributing++;
      }
    }
  });
  return Number((newAverageValueSum / numOfTrucksContributing).toFixed(2));
}

/**
 * Calculates and updates the relative values for trucks at a specific time index.
 *
 * @param {Array} trucks - The array of truck objects.
 * @param {Object} yValues - The object containing y-values for each truck, indexed by truckPid.
 * @param {Object} relativeYValues - The object containing relative y-values for each truck, indexed by truckPid.
 * @param {number} timeIndex - The index of the time for which the relative values are calculated.
 * @param {number} averageValue - The average value used to calculate the relative value.
 * @param {boolean} replaceValue - A flag indicating whether to replace the value at the time index or push it to the array.
 */
const calculateAndUpdateRelativeValuesAtTimeIndex = (trucks, yValues, relativeYValues, timeIndex, averageValue, replaceValue) => {
  _.forEach(trucks, (truck, ) => {
    if (!_.isNil(yValues) && !_.isNil(yValues[truck.truckPid])) {
      const foundTruckValue = yValues[truck.truckPid][timeIndex];
      if (!_.isNil(foundTruckValue) && !isNaN(foundTruckValue)) {
        const relativeValue = isNaN(averageValue) ? NaN : Number((foundTruckValue - averageValue).toFixed(2));
        if (_.isNil(replaceValue) || replaceValue === false) {
          relativeYValues[truck.truckPid].push(relativeValue);
          relativeYValues[truck.truckPid].splice(0,1);
        } else {
          relativeYValues[truck.truckPid][timeIndex] = relativeValue;
        }
      } 
    }
  });
}

/**
 * Generates a title for an axis based on the provided sensor and fleet name.
 *
 * @param {Object} sensor - The sensor object containing displayName and uom properties.
 * @param {string} fleetName - The name of the fleet.
 * @returns {string} The generated axis title.
 */
const generateAxisTitle = (sensor, fleetName) => {
  const axisTitle = (_.isNil(fleetName) ? 'Unknown Fleet' : fleetName) + ' - ' + (_.isNil(sensor) ? 'Unknown Sensor' : (sensor.displayName + ' (' + sensor.uom + ')'));
  return axisTitle;
}

export {
  processTrucksForLiveView,
  generateInitialDataSet,
  updateDefinitionWithTrucks,
  updateDefinitionWithSensor,
  toggleContextVisibility,
  setContextColor,
  addAnnotationForRule,
  processSensors,
  transformDataToJson,
  toggleContextVisibilityAllOthers,
  toggleContextVisibilityOtherAfter,
  getCloseSubscriptionMessage,
  calculateAverageAtTimeIndex,
  calculateAndUpdateRelativeValuesAtTimeIndex,
  generateAxisTitle,
  filterTrucks
}