import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import errorMessages from '../../common/errorMessages';
import {handleError} from './appErrorActions';
import {
  addToSession,
  createAuth0Client,
  getItemFromSession,
  getUserClaimsFromToken,
  hasSetContextToken,
  hasViewDevelopmentContentPermission,
  hasViewPreReleaseContentPermission,
  setSession,
  hasOwnerAdminToken,
  hasFleetManagementPermission,
  hasDeviceSettingPermission,
  hasJobApproverPermission,
  hasDashboardAdminPermission
} from "../../../helpers/authHelper";
import history from "../../../helpers/historyHelper";
import * as appQueries from '../appQueries';
import appActionTypes from '../appActionTypes';
import * as rootActions from '../../common/rootActions';
import moment from "moment";
import * as appUserConfigActions from "./appUserConfigActions";
import * as appWebSocketActions from "./appWebsocketActions";

import { getLatestWhatsNewRecordDate } from '../../../components/app/updateHistory';

const USER_CONFIG_NAME_MENU = "menu";

/**
 * Handle the callback from Auth0. This parses the details returned from Auth0
 * and if valid places the authentication information in local storage. It then
 * navigates the user to an appropriate route.
 *
 * IMPORTANT: This action is unique in that it does not resolve to a reducer
 * (state change), so do not use this as a template for new actions. It also
 * has the side effect of altering the browser location, which should be avoided.
 * We only do this as we have a specific Auth0 flow that must be followed.
 */
const handleLogin = () => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      let client = createAuth0Client();
      client.parseHash((err, authResult) => {
        if (validateAuth0Response(err, authResult, resolve, dispatch)) {
          let liveDataServiceToken = authResult.accessToken;
          let expiresIn = authResult.expiresIn;
          let idToken = authResult.idToken;
          // As part of the authentication flow, go get the token to talk to the card data service
          // and store it in local storage.
          client.checkSession({audience: 'carddataservice', scope: 'openid'}, (err, authResult) => {
            if (validateAuth0Response(err, authResult, resolve, dispatch)) {
              let cardDataServiceToken = authResult.accessToken;
              client.checkSession({audience: 'portalappservice', scope: 'openid'}, (err, authResult) => {
              if (validateAuth0Response(err, authResult, resolve, dispatch)) {
                  let displayDataServiceToken = authResult.accessToken;
                  // Save the data to the session.
                  setSession(displayDataServiceToken, cardDataServiceToken, liveDataServiceToken, expiresIn, idToken);

                  // Restore some items from local storage
                  const isShowPreReleaseContentSet = getItemFromSession('showPreReleaseContent');
                  showPreReleaseContent(isShowPreReleaseContentSet);

                  history.replace('/home');
                  resolve();
                }
              });
            }
          });
        }
      });
    })
  }
};

/**
 * Method to validate the response from Auth0 and dispatch errors accordingly
 */
const validateAuth0Response = (err, authResult, resolve, dispatch) => {
  let isValid = false;
  if (!_.isNil(err)) {
    // If Auth0 sends us an error then we log it, navigate the user to the error route,
    // and dispatch an error so the UI can handle it.
    history.replace('/error');
    console.log(err);
    resolve(dispatch(handleError(errorMessages.ERROR_SIGNING_IN, err)));
  } else if (_.isNil(authResult) || _.isNil(authResult.accessToken) || _.isNil(authResult.idToken) || _.isNil(authResult.expiresIn)) {
    // If Auth0 sends us invalid data then we navigate the user to the error route,
    // and dispatch an error so the UI can handle it.
    history.replace('/error');
    resolve(dispatch(handleError(errorMessages.ERROR_SIGNING_IN, 'Error: Invalid data returned from authorization service')));
  } else {
    // All is good...
    isValid = true;
  }
  return isValid;
};

const setUserInformation = defaultActionFactory(appActionTypes.APP_SET_USER_INFO, 'info');

const loadUserInformationStarting = defaultActionFactory(appActionTypes.APP_LOAD_USER_INFO_STARTING);
const loadUserInformationSuccess = defaultActionFactory(appActionTypes.APP_LOAD_USER_INFO_SUCCESS);
const loadUserInformationError = defaultActionFactory(appActionTypes.APP_LOAD_USER_INFO_ERROR);
const userProfileSettingsLoaded = defaultActionFactory(appActionTypes.APP_USER_PROFILE_SETTINGS_LOADED,'queryResults')

/**
 * Sets up the user details from the user token in the app state and optionally loads the
 * list of owners if the user has the super user permission
 */
const loadUserInformation = () => {
  return async (dispatch, getState) => {
    try {

      await dispatch(loadUserInformationStarting());

      // First set the user information in the state
      await dispatch(setUserInformation({
        ownersVisible: hasSetContextToken(),
        hasViewDevelopmentContentPermission: hasViewDevelopmentContentPermission(),
        hasViewPreReleaseContentPermission: hasViewPreReleaseContentPermission(),
        userClaims: getUserClaimsFromToken(),
        isUserAdmin: hasOwnerAdminToken(),
        hasFleetManagementPermission: hasFleetManagementPermission(),
        hasDeviceSettingPermission: hasDeviceSettingPermission(),
        hasJobApproverPermission: hasJobApproverPermission(),
        hasDashboardAdminPermission: hasDashboardAdminPermission()
      }));

      // then load the list of owners
      await dispatch(loadOwners());

      // Restore some items from local storage
      const isShowPreReleaseContentSet = getItemFromSession('showPreReleaseContent');
      await dispatch(showPreReleaseContent(isShowPreReleaseContentSet === "true"));

      await dispatch(loadUserProfileSettings());

      await dispatch(appWebSocketActions.connectWebSocket());
    } catch(e) {
      await dispatch(loadUserInformationError());
      history.replace('/error');
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_USER_INFO, e.message));
    }
    return dispatch(loadUserInformationSuccess());
  }
};

const loadUserProfileSettings = () => {
  return async (dispatch, getState) => {
    const currentUserId = getState()?.app?.user?.userId;
    if (currentUserId) {
      try {
        let userProfileSettings = await appQueries.fetchUserProfileSettings(currentUserId);
        await dispatch(userProfileSettingsLoaded(userProfileSettings));

        // If the user has not seen the what's new panel yet, or
        // If the last the user has seen it is before the latest what's new record date, then show it
        const latestWhatsNewRecordDate = getLatestWhatsNewRecordDate();
        if (_.isNil(userProfileSettings.userProfileSetting.lastWhatsNewDate) || moment(userProfileSettings.userProfileSetting.lastWhatsNewDate).isBefore(latestWhatsNewRecordDate)) {
          await dispatch(onShowWhatsNew(true));
        }

      } catch (e) {
        dispatch(handleError(errorMessages.ERROR_RETRIEVING_USER_PROFILE_SETTINGS, e.message));
      }
    }
  }
}

const userLastWhatsNewDateUpdatedSuccess = defaultActionFactory(appActionTypes.APP_USER_PROFILE_LASTWHATSNEW_UPDATED, 'lastWhatsNewDate');

const updateUserProfileLastWhatsNew = (time) => {
  return async (dispatch, getState) => {
    try {
      let currentUserId = getState()?.app?.user?.userId;
      if(currentUserId) {
        let result = await appQueries.saveUserProfileSetting(currentUserId,'lastWhatsNewDate', time);
        result?.saveUserProfileSetting?.success ? dispatch(userLastWhatsNewDateUpdatedSuccess(time)) : dispatch(handleError(errorMessages.ERROR_SAVING_USER_PROFILE_SETTINGS)) ;
      }
    } catch(e) {
      dispatch(handleError(errorMessages.ERROR_SAVING_USER_PROFILE_SETTINGS, e.message));
    }
  }
}

const clearUserInformation = defaultActionFactory(appActionTypes.APP_CLEAR_USER_INFO);
const openUserMenu = defaultActionFactory(appActionTypes.APP_OPEN_USER_MENU, 'userMenuTargetElement');
const closeUserMenu = defaultActionFactory(appActionTypes.APP_CLOSE_USER_MENU);

const setOwners = defaultActionFactory(appActionTypes.APP_SET_OWNERS, 'owners', 'defaultOwner');

/**
 * Loads the list of owners from the server is the current user has the super user permission and stores
 * the list in the app state.
 *
 * NOTE: Currently this methods hard codes the default user to C&J for ALL super users. This
 * should be moved into some kind of user configuration.
 */
const loadOwners = () => {
  return async (dispatch, getState) => {

    // Only load the owners if the user has the permission to set an owner context
    // otherwise we do not need the list as it will not be displayed in the UI.
    if (hasSetContextToken()) {
      let owners = await appQueries.fetchOwners();

      // TODO: The default owner should come back with the owner information. For now hard code it here.

      let defaultOwner = 0;

      if (!_.isEmpty(owners)) {
        let nexTierOwner = _.find(owners.owners, ['id', 2]);
        if (!_.isNil(nexTierOwner)) {
          nexTierOwner.ownerName = 'NexTier';
          defaultOwner = nexTierOwner.id;
        }
      }

      return dispatch(setOwners(owners, defaultOwner));
    }
  }
};

const setOwner = defaultActionFactory(appActionTypes.APP_SET_OWNER, 'owner');

/**
 * This action will update the selected owner in the app state. It clears out the route details
 * currently loaded and all other display states.
 */
const setOwnerContext = (ownerContext) => {
  return async (dispatch, getState) => {
    // Only process this action if the owner context has actually changed.
    let currentOwnerId = _.isNil(getState().app.selectedOwner) ? null : getState().app.selectedOwner.value;
    if (ownerContext.value !== currentOwnerId) {
      await dispatch(setOwner(ownerContext));
      // Clear the display states AFTER setting the owner context or else the old display could still
      // be loaded in the browser and react to the state changes triggered by clearing the state.
      await dispatch(rootActions.clearOwnerState());

      // Close the existing websocket connection
      await dispatch(appWebSocketActions.closeWebSocket());

      // Open a new websocket connection with the new owner
      setTimeout(async () => { await dispatch(appWebSocketActions.connectWebSocket()); }, 1000);
    }
  }
};

const setUserRoutes = defaultActionFactory(appActionTypes.APP_SET_USER_ROUTES, 'routeDetails');

/**
 * update expand status for a specific group at menu
 * @param groupValue value of target group to update status
 * @param expand expand status
 */
const updateUserRoutes = (groupValue, expand) => {
  return async (dispatch, getState) => {
    try {
      let currentRoutes = getState().app.user.routes;
      const itemToUpdate = currentRoutes.groups.find(g => g.value === groupValue);
      itemToUpdate.expand = expand;
      const routeDetails = {
        defaultRoute: getState().app.user.defaultRoute,
        routes: currentRoutes
      }

      await dispatch(setUserRoutes(routeDetails));
    } catch(e) {
      history.replace('/error');
      return dispatch(handleError(errorMessages.ERROR_SAVING_USER_MENU_EXPAND_SETTINGS, e.message));
    }

  }
}

/**
 * Loads the route details from the server for an owner context. The owner context is either the owner
 * for the current user OR the selected owner context if the user is a super user.
 *
 * The browser will be directed to the default route if the current browser path is not found in the
 * new list of routes.
 */
const loadUserRoutes = () => {
  return async (dispatch, getState) => {

    let routeDetails = null;

    try {
      // If we are in offline mode, we should ignore owner/user specific routes and use the default local routes
      if(process.env.REACT_APP_OFFLINE_MODE === "true") {
        routeDetails = await appQueries.fetchLocalRoutes();
        await dispatch(setUserRoutes(routeDetails));
        return;
      }

      // If the current user is a super user we get the routes for the selected owner, otherwise use the users owner id
      let ownerId = _.isNil(getState().app.selectedOwner) ? getState().app.user.ownerId : getState().app.selectedOwner.value;

      //load if any user display configuration for the menu
      const userId = getState().app.user.userId;
      const queryPinnedPageResult = await dispatch(appUserConfigActions.queryUserConfigurationForPage(null, userId, USER_CONFIG_NAME_MENU));

      // Load the list of routes for this user
      const userState = getState().app.user;
      routeDetails = await appQueries.fetchUserRoutes(
          ownerId,
          userState.showDevelopmentContent,
          userState.showPreReleaseContent,
          userState.profileSettings.landingPage,
          queryPinnedPageResult || [],
          userState.routes.groups);

      // Save the route details in the state
      await dispatch(setUserRoutes(routeDetails));

      // Load the list of available displays for this user
      await dispatch(getAvailableDisplays());

      // Since the routes have changes, get the dashboard configurations for the user
      await dispatch(appUserConfigActions.queryUserConfigurationForDashboardLayout(null, userId));
    } catch(e) {
      history.replace('/error');
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_USER_INFO, e.message));
    }

    if (!_.isNil(routeDetails)) {
      // Determine if we need to navigate the user to a different display.
      let currentLocation = history.location.pathname;
      let parsedCurrentLocation = currentLocation.split('/');
      if (_.isNil(_.find(routeDetails.routes.routes, ['value', currentLocation])) && 
          (parsedCurrentLocation.length > 0 && _.isNil(_.find(routeDetails.routes.routes, ['value',  '/' + parsedCurrentLocation[1]])))
         ) {
        history.replace(routeDetails.defaultRoute);
      }
    }
  }
};

/**
 * function to update user configuration for menu
 */
const updateUserConfigForMenu = (config) => {
  return async (dispatch, getState) => {
    const userId = getState().app.user.userId;
    if (!_.isEmpty(userId)) {
      await dispatch(appUserConfigActions.saveUserConfigurationForPage(null, userId, USER_CONFIG_NAME_MENU, config));
      //reload menu for new pinned info, latest available route etc.
      await dispatch(loadUserRoutes());
    }
  }
}

const setShowDevelopmentContent = defaultActionFactory(appActionTypes.APP_SET_SHOW_DEVELOPMENT_CONTENT, 'show');

/**
 * Sets the application flag to indicate any development content can be included in the
 * resolving of the displays for the current owner/user and then triggers the evaluation.
 * All existing display states are cleared.
 */
const showDevelopmentContent = (show) => {
  return async (dispatch, getState) => {
    await dispatch(setShowDevelopmentContent(show));
    // Clear the display states AFTER setting the owner context or else the old display could still
    // be loaded in the browser and react to the state changes triggered by clearing the state.
    await dispatch(rootActions.clearOwnerState());
  }
};

const setShowPreReleaseContent = defaultActionFactory(appActionTypes.APP_SET_SHOW_PRE_RELEASE_CONTENT, 'show');

/**
 * Sets the application flag to indicate any pre-release content can be included in the
 * resolving of the displays for the current owner/user and then triggers the evaluation.
 * All existing display states are cleared.
 */
const showPreReleaseContent = (show) => {
  return async (dispatch, getState) => {
    await dispatch(setShowPreReleaseContent(show));
    
    addToSession('showPreReleaseContent', show);

    // Clear the display states AFTER setting the owner context or else the old display could still
    // be loaded in the browser and react to the state changes triggered by clearing the state.
    await dispatch(rootActions.clearOwnerState());
  }
};

const showWhatsNew = defaultActionFactory(appActionTypes.APP_SHOW_WHATS_NEW, 'show');

const onShowWhatsNew = (show) => {
  return async (dispatch, getState) => {
    await dispatch(showWhatsNew(show));

    // Set the last what's new date to the beginning of tomorrow if the user has seen it (closed the panel)
    if (show === false) {
      await dispatch(updateUserProfileLastWhatsNew(moment.utc().add(1, 'day').startOf('day').format()));
    }
  }
}

const whatsNewPanelExpanded = defaultActionFactory(appActionTypes.APP_WHATS_NEW_PANEL_EXPANDED, 'panel');
const onRefreshTimestamp = defaultActionFactory(appActionTypes.APP_REFRESH_TIMESTAMP, 'timestamp');
const onRefreshManualTimestamp = defaultActionFactory(appActionTypes.APP_REFRESH_MANUAL_TIMESTAMP, 'timestamp');

const getAvailableDisplaysStarting = defaultActionFactory(appActionTypes.APP_GET_AVAILABLE_DISPLAYS_STARTING);
const getAvailableDisplaysSuccess = defaultActionFactory(appActionTypes.APP_GET_AVAILABLE_DISPLAYS_SUCCESS, 'queryResults');
const getAvailableDisplaysError = defaultActionFactory(appActionTypes.APP_GET_AVAILABLE_DISPLAYS_ERROR);

const getAvailableDisplays = () => {
  return async (dispatch, getState) => {

    let queryResults = null;

    try {

      await dispatch(getAvailableDisplaysStarting());

      // We don't really care about the returned value
      // We need the selected page (page parameter) so we can update state accordingly
      queryResults = await appQueries.fetchAvailableDisplays(getState().app.user.routes.routes);
      if(queryResults?.availableDisplays?.length > 1 ) {
          queryResults.availableDisplays.sort( (a, b) => a.label.localeCompare(b.label) );
      }

      await dispatch(getAvailableDisplaysSuccess(queryResults));

      // If the current landing page is not valid anymore, update it to the first item in the list
      // Also when a user first logs in, they will not have any landing page set so they will get back a default
      // route that is a value/label object
      // Validate that object against the returned available displays
      const currentLandingPage = getState().app.user.profileSettings.landingPage;
      let foundLandingPage = _.find(queryResults.availableDisplays, ['value', currentLandingPage.value]);
      if (_.isNil(foundLandingPage)) {
        // User's current landing page is not valid anymore (ie. list of owner routes has changed)
        // Change the landing page to the first item in the available displays list
        await dispatch(setLandingPage(queryResults.availableDisplays[0]))
      }
    } catch (e) {

      await dispatch(getAvailableDisplaysError());

      return dispatch(handleError(errorMessages.ERROR_GETTING_AVAILABLE_DISPLAYS, e.message));
    }

  }
}

const saveUserLandingPageStarting = defaultActionFactory(appActionTypes.APP_SAVE_LANDING_PAGE_STARTING);
const saveUserLandingPageSuccess = defaultActionFactory(appActionTypes.APP_SAVE_LANDING_PAGE_SUCCESS, 'page');
const saveUserLandingPageError = defaultActionFactory(appActionTypes.APP_SAVE_LANDING_PAGE_ERROR);

const setLandingPage = (page) => {
  return async (dispatch, getState) => {

    try {

      await dispatch(saveUserLandingPageStarting());

      // We don't really care about the returned value
      // We need the selected page (page parameter) so we can update state accordingly
      await appQueries.saveUserProfileSetting(getState().app.user.userId, 'landingPage', JSON.stringify(page));

      await dispatch(saveUserLandingPageSuccess(page));

    } catch (e) {

      await dispatch(saveUserLandingPageError());

      return dispatch(handleError(errorMessages.ERROR_SAVING_USER_LANDING_PAGE, e.message));
    }

  }
}

export {
  handleLogin,
  loadUserInformation,
  loadUserRoutes,
  clearUserInformation,
  openUserMenu,
  closeUserMenu,
  setOwnerContext,
  showDevelopmentContent,
  showPreReleaseContent,
  onShowWhatsNew,
  whatsNewPanelExpanded,
  updateUserProfileLastWhatsNew,
  loadUserProfileSettings,
  setLandingPage,
  getAvailableDisplays,
  updateUserRoutes,
  updateUserConfigForMenu,
  onRefreshTimestamp,
  onRefreshManualTimestamp
};