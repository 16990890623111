import {performDisplayFetch} from '../../common/fetch'
import useSimulationData from '../../../helpers/simulationHelper';
import * as dataExplorationDisplay from './dataExplorationDisplaySimulation';

const fetchDataExplorationDisplayDataAge = (truckId) => {
  return performDisplayFetch({
    'query': 'query TruckDataAge($truckId:Long!){ truckDataAge(truckId: $truckId) { timestamp }}',
    'variables': {
      'truckId': truckId
    }
  })
};

const fetchDataExplorationDisplayData = () => {
   if (useSimulationData('dataExplorationDisplayPage')) {
       return dataExplorationDisplay.fetchDataExplorationDisplay();
   }
  return performDisplayFetch({
    'query': 'query dataExplorationDisplayAllTrucks { dataExplorationDisplayAllTrucks { datavan truckId truckPid truckName unitType fleetName lastModified } }'
  })
};

const fetchTruckDetails = (truckId, time) => {
  return performDisplayFetch({
    'query': 'query dataExplorationTruckDetails($dataExplorationTruckDetailsInput: DataExplorationTruckDetailsInput!) { dataExplorationTruckDetails(dataExplorationTruckDetailsInput: $dataExplorationTruckDetailsInput) { startTime endTime availabilityBlocks {startTime endTime}}}',
    'variables': {
      dataExplorationTruckDetailsInput: {
        truckId: truckId,
        time: time,
      }
    }
  })

};
export {
  fetchDataExplorationDisplayDataAge,
  fetchDataExplorationDisplayData,
  fetchTruckDetails
};
