import _ from 'lodash';

import unitUserChartActionTypes from './unitUserChartActionTypes';
import { unitUserChartState, defnEmpty } from './unitUserChartSelectors';
import { updateDefn, normalizeSensorData } from './services/unitUserChartService';
import { getCardFromLayoutConfigViews } from '../../../components/common/layout/layoutHelper';
import { determineTruckFromDashboard } from '../../common/services/truckService';
import appActionTypes from '../../app/appActionTypes';
import moment from 'moment';

const initialState = unitUserChartState();

const unitUserChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case unitUserChartActionTypes.UNIT_USER_CHART_QUERY_STARTING:
      return { ...state, queryRunning: true };
    case unitUserChartActionTypes.UNIT_USER_CHART_QUERY_ERROR:
      return { ...state, queryRunning: false };
    case unitUserChartActionTypes.UNIT_USER_CHART_QUERY_SUCCESS:
      return onQuerySuccess(state, action);
    case unitUserChartActionTypes.UNIT_USER_CHART_QUERY_SENSORS_SUCCESS:
      return onQuerySensorSuccess(state, action);
    case unitUserChartActionTypes.UNIT_USER_CHART_CLEAR_DATA:
      return onClearData(state, action);

    case unitUserChartActionTypes.CHART_CONFIG_OPEN_SENSOR_SELECTOR:
      return updateConfigSensorSelectorState(state, true);
    case unitUserChartActionTypes.CHART_CONFIG_CLOSE_SENSOR_SELECTOR:
      return updateConfigSensorSelectorState(state, false);
    case unitUserChartActionTypes.CHART_CONFIG_SET_SELECTED_SENSORS:
      return onSelectedSensors(state, action.xAxisId, action.sensors);

    case unitUserChartActionTypes.CHART_CONFIG_UPDATE_DISPLAY_NAME:
      return onChangeDisplayName(state, action);
    case unitUserChartActionTypes.CHART_CONFIG_UPDATE_UOM:
      return onChangeUOM(state, action);
    case unitUserChartActionTypes.CHART_CONFIG_UPDATE_YAXIS:
      return onChangeYAxis(state, action);
    case unitUserChartActionTypes.CHART_CONFIG_UPDATE_LINE:
      return onChangeLine(state, action);
    case unitUserChartActionTypes.CHART_CONFIG_UPDATE_COLOR:
      return onConfigChangedColor(state, action);
    case unitUserChartActionTypes.CHART_CONFIG_SET_COLOR_PICKER_STATE:
      return onSetColorPickerState(state, action);

    case unitUserChartActionTypes.UNIT_USER_CHART_DEFINITION_SET_DEFAULT_TRUCK:
      return onSetDefinitionDefaultTruck(state, action);
    case unitUserChartActionTypes.UNIT_USER_CHART_DEFINITION_SET_START_TIME:
      return onSetDefinitionStartTime(state, action);
    case unitUserChartActionTypes.UNIT_USER_CHART_DEFINITION_SET_SENSORS:
      return onSetDefinitionSensors(state, action);
    case unitUserChartActionTypes.UNIT_USER_CHART_DEFINITION_SET_AXES:
      return onSetDefinitionAxes(state, action);

    case unitUserChartActionTypes.CHART_CONFIG_DISCARD_DEFINITION:
      return onDiscardDefinition(state, action);

    case unitUserChartActionTypes.UNIT_USER_CHART_SETUP_EDIT_MODE:
      return onSetupEditMode(state, action);

    case unitUserChartActionTypes.CHART_CONFIG_SELECT_TAB:
      return onSelectTab(state, action);
    case unitUserChartActionTypes.CHART_CONFIG_SET_AXIS_PROPERTY_VALUE:
      return onSetAxisPropertyValue(state, action);
    case unitUserChartActionTypes.CHART_CONFIG_SET_AXIS_AUTO:
      return onSetAxisAuto(state, action);

    case appActionTypes.APP_PROCESS_WEBSOCKET_DATA:
      return onProcessWebSocketData(state, action);
    default:
      return state;
  }
};

const onQuerySuccess = (state, action) => {

  // All the series have the same number of buckets so just use one to get the xValues
  const xValues = (_.isEmpty(action.queryResults.userUnitChart.series)) ? [] : [...action.queryResults.userUnitChart.series[0].timeStamp];
  const yValues = normalizeSensorData(xValues, action.queryResults.userUnitChart.series);

  let latest = {};
  // Update Latest values based on configured sensors and how those map to values returned
  // Sometimes there won't be data for a selected sensor
  _.map(state.definition.primary.sensors, (sensor) => {
    let latestValueForSeries = _.find(action.queryResults.userUnitChart.series, ['sensorSetId', sensor.sensorSetId])
    latest[sensor.sensorSetId] = (_.isNil(latestValueForSeries) || _.isNil(latestValueForSeries.latest)) ? '-' : parseFloat(latestValueForSeries.latest.toFixed(1)).toLocaleString('en');
  })

  return {
    ...state,
    queryRunning: false,
    xValues: xValues,
    yValues: yValues,
    latest: latest,
  }

}

const onQuerySensorSuccess = (state, action) => {
  const sensors = action.queryResults.sensorForAssets;
  let selectedSensors = _.cloneDeep(state.definition.primary.sensors);
  if (!_.isNil(sensors)) {
    const targetUoms = _.chain(sensors).keyBy('sensorSetId').mapValues('targetUoms').value();
    selectedSensors.forEach(sensor => {
      sensor.targetUoms = targetUoms[sensor.sensorSetId];
    });
  }
  return {
    ...state,
    queryRunning: false,
    definition: {
      ...state.definition,
      primary: {
        ...state.definition.primary,
        sensors: selectedSensors,
      }
    },
    allSensors: sensors ?? [],
  }
}

const onClearData = (state, action) => {
  return {
    ...state,
    ...initialState
  }
}

const updateConfigSensorSelectorState = (state, enabled) => {
  return {
    ...state,
    shouldOpenConfigSensorSelector: enabled
  }
};

const onSelectedSensors = (state, xAxisId, newSensors) => {
  if (_.isEqual(state.definition[xAxisId].sensors, newSensors)) {
    return state;
  }

  const definition = updateDefn(state.definition, newSensors, xAxisId, state.lineStylesList[0]);

  // Display an empty value for new sensors until the query returns an actual number
  let latest = _.cloneDeep(state.latest)
  _.forEach(newSensors, function (sensor) {
    if (_.isNil(latest[sensor.sensorSetId])) latest[sensor.sensorSetId] = '-'
  })

  return {
    ...state,
    definition: definition,
    latest: latest,
  }
};

const onChangeDisplayName = (state, action) => {
  let definition = _.cloneDeep(state.definition)
  _.find(definition.primary.sensors, function (sensor) { return sensor.alias === action.sensor; }).displayName = action.displayName;
  return {
    ...state,
    definition: definition,
  }
};

const onChangeUOM = (state, action) => {
  let definition = _.cloneDeep(state.definition)
  _.find(definition.primary.sensors, function (sensor) { return sensor.alias === action.sensor; }).uom = action.uom;
  return {
    ...state,
    definition: definition,
  }
};

const onChangeYAxis = (state, action) => {
  let definition = _.cloneDeep(state.definition)
  _.find(definition.primary.sensors, function (sensor) { return sensor.alias === action.sensor; }).yAxisId = action.yAxis;
  return {
    ...state,
    definition: definition,
  }
};

const onChangeLine = (state, action) => {
  let definition = _.cloneDeep(state.definition)
  _.find(definition.primary.sensors, function (sensor) { return sensor.alias === action.sensor; }).lineStyle = action.lineStyle;
  return {
    ...state,
    definition: definition,
  }
};

const onConfigChangedColor = (state, action) => {
  let definition = _.cloneDeep(state.definition)
  _.find(definition.primary.sensors, function (sensor) { return sensor.alias === action.sensor; }).color = action.color;
  return {
    ...state,
    definition: definition,
  }
};

const onSetColorPickerState = (state, action) => {
  return {
    ...state,
    colorPickerState: _.isNil(action.sensor) ? null : { sensor: action.sensor, origColor: action.origColor },
  }
};

const onSetDefinitionDefaultTruck = (state, action) => {
  let newDefinition = _.cloneDeep(state.definition);
  let truck = determineTruckFromDashboard(action.truck, action.dashboard);

  newDefinition.primary.defaultTruck =
  {
    id: truck.id,
    pid: truck.truckPid,
    name: truck.name,
    unitType: truck.unitType
  };
  newDefinition.primary.trucks = Array(newDefinition.primary.sensors.length).fill(newDefinition.primary.defaultTruck);

  return {
    ...state,
    definition: newDefinition,
  }
}

const onSetDefinitionStartTime = (state, action) => {
  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.timeRange.startTime = action.startTime;
  newDefinition.primary.timeRange.duration = action.duration;
  return {
    ...state,
    definition: newDefinition,
  }
}

const onSetDefinitionSensors = (state, action) => {
  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.sensors = action.sensors;

  newDefinition.primary.trucks = Array(action.sensors.length).fill(newDefinition.primary.defaultTruck);

  return {
    ...state,
    definition: newDefinition,
  }
}

const onDiscardDefinition = (state, action) => {
  const originalCard = getCardFromLayoutConfigViews(action.originalViews, action.view, action.cardKey);
  let newDefinition = _.cloneDeep(state.definition);
  // Original card may not exist because we just added it
  if (!_.isNil(originalCard)) {
    newDefinition.primary.sensors = originalCard.configuration.sensors || [];
    newDefinition.primary.axes = originalCard.configuration.axes || defnEmpty().primary.axes;
  }
  return {
    ...state,
    definition: newDefinition
  }
}

const onSetupEditMode = (state, action) => {

  let clearedLatestValues = {};

  // Set all latest values to be no value
  _.forEach(_.keys(state.latest), (key) => {
    clearedLatestValues[key] = '-'
  })

  return {
    ...state,
    xValues: initialState.xValues,
    yValues: initialState.yValues,
    latest: clearedLatestValues
  }
}

const onSelectTab = (state, action) => {
  return {
    ...state,
    configTabIndex: action.tabIndex
  }
}

const onSetAxisPropertyValue = (state, action) => {
  let newAxes = _.cloneDeep(state.definition.primary.axes);
  if (action.property.toLowerCase() === 'min' || action.property.toLowerCase() === 'max') {
    if (action.value.length === 1 && action.value === '-') {
      newAxes[action.axisId][action.property.toLowerCase()] = action.value;
    } else {
      newAxes[action.axisId][action.property.toLowerCase()] = _.isEmpty(action.value) ? '' : parseInt(action.value).toString();
    }
  }

  if (action.property.toLowerCase() === 'min' && !_.isEmpty(newAxes[action.axisId].max) && (Number(action.value) > Number(newAxes[action.axisId].max))) {
    newAxes[action.axisId].errors.min = 'Minimum value must be equal to or less than Maximum value';
  }
  else if (action.property.toLowerCase() === 'max' && !_.isEmpty(newAxes[action.axisId].min) && (Number(action.value) < Number(newAxes[action.axisId].min))) {
    newAxes[action.axisId].errors.max = 'Maximum value must be equal to or greater than Minimum value';
  }
  else {
    // Need to reset the errors here if the value is valid otherwise the error will persist
    newAxes[action.axisId].errors = {};
  }

  return {
    ...state,
    definition: {
      ...state.definition,
      primary: {
        ...state.definition.primary,
        axes: newAxes
      }
    }
  }
}

const onSetAxisAuto = (state, action) => {
  let newAxes = _.cloneDeep(state.definition.primary.axes);
  newAxes[action.axisId].min = '';
  newAxes[action.axisId].max = '';
  newAxes[action.axisId].errors = {};
  return {
    ...state,
    definition: {
      ...state.definition,
      primary: {
        ...state.definition.primary,
        axes: newAxes
      }
    }
  }
}

const onSetDefinitionAxes = (state, action) => {
  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.axes = action.axes;

  return {
    ...state,
    definition: newDefinition,
  }
}

const onProcessWebSocketData = (state, action) => {

  const latest = _.cloneDeep(state.latest);
  const currentTruck = state.definition.primary.defaultTruck;
  
  const latestX = action.data.payload[0].ts;
  const xValues = _.cloneDeep(state.xValues);
  const yValues = _.cloneDeep(state.yValues);
  const definition = _.cloneDeep(state.definition);

  if (!_.isNil(action.data) && !_.isEmpty(action.data.payload) && !_.isNil(currentTruck)) {
    action.data.payload.forEach((payload) => {
      if (!_.isEmpty(payload.sensorSetIds) && currentTruck.pid == payload.truckPid) {
        payload.sensorSetIds.forEach((sensorSetId, index) => {
          latest[sensorSetId] = parseFloat(payload.values[index].toFixed(1)).toLocaleString('en');
        });
      }
    });
  }
  
  // Check to make sure the latest value is at a newer timestamp
  // Add new data points when window is not full
  // Remove data points once max window size is exceeded, and move the start time forward as well
  if (latestX > state.xValues[state.xValues.length - 1]) {
    xValues.push(latestX);
    
    const momentLatest = moment.unix(latestX);
    const newStartTime = momentLatest.subtract(state.definition.primary.timeRange.duration, 'minutes').unix();
    definition.primary.timeRange.startTime = newStartTime;

    const timestampNearestNewStartTime = _.orderBy(_.filter(state.xValues, (value) => value < newStartTime), null, ['desc'])[0];

    const indexToRemove = xValues.indexOf(timestampNearestNewStartTime);
    
    xValues.splice(0, indexToRemove);
    
    Object.keys(yValues).forEach(key => {
      yValues[key].push(parseFloat(latest[key].replaceAll(",", "")));
      yValues[key].splice(0, indexToRemove);
    });
  }

  
  return {
    ...state,
    latest: latest,
    xValues: xValues,
    yValues: yValues,
    definition: definition
  };
}

export default unitUserChartReducer;