import { fleetTypes } from '../../../common/services/fleetTypeService';
import componentTypes from "../../../../components/componentTypes";
import OWNERS from '../../../../components/common/owners';
import { RFC_2822 } from 'moment';


const fetchFleetMapSummary = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        fleetDashboardFleetSummary: simData.fleets
      })
    }, 1000)
  });
};

const simData = {
  fleets: [
    {
      id: 6,
      name: 'ETX-1',
      type: fleetTypes.FRAC,
      datavanId: 1,
      unitTypes: [
        "Blender",
        "FracPumper",
        "Datavan_Main"
      ]
    },
    {
      id: 10,
      name: 'WTX-2',
      type: fleetTypes.FRAC,
      datavanId: 2,
      unitTypes: [
        "Blender",
        "FracPumper",
        "Datavan_Main",
        "eBlend",
        "eFracPumper",
        "Chemvan"
      ]
    },
    {
      id: 21,
      name:'WTX-7',
      type: fleetTypes.PUMPONLY,
      datavanId: 3,
      unitTypes: [
        "Blender",
        "FracPumper",
        "Datavan_Main"
      ]
    },
    {
      id: 12,
      name: 'MC-2',
      type: fleetTypes.PUMPONLY,
      datavanId: 4,
      unitTypes: [
        "Blender",
        "FracPumper",
        "Datavan_Main"
      ]
    },
  ]
};

const fetchFleetDashboardCards = (ownerId) => {

  let cardsList = [
    { type: componentTypes.FLEET_ACTIVITY, w: 2, h: 1, metaConfiguration: { configurable: false, canPeriodicRefresh: true }  },
    { type: componentTypes.FLEET_ALARM_COUNT, w: 2, h: 1, 
      metaConfiguration: { configurable: true, canPeriodicRefresh: true},
      configuration: {
        columns: [
            {name: "Severity", visible: true},
            {name: "Details", visible: true},
            {name: "State", visible: true},
            {name: "Age", visible: true},
            {name: "Truck Name", visible: true},
            {name: "Component", visible: false},
            {name: "Type", visible: false},
            {name: "SubType", visible: false}
        ]

      },  
    },
    { type: componentTypes.FLEET_CLIENT_STATUS, w: 2, h: 1, metaConfiguration: { configurable: false, canPeriodicRefresh: true}  },
    { type: componentTypes.UNIT_USER_CHART, w: 4, h: 2, minW: 3, minH: 2, metaConfiguration: { configurable: true, canPeriodicRefresh: false }, configuration: { canFreeSize: true } },
    { type: componentTypes.FLEET_COMPONENTS, w: 2, h: 3, metaConfiguration: { configurable: false, canPeriodicRefresh: true}  },
    { type: componentTypes.FLEET_OPERATION, w: 2, h: 3, metaConfiguration: { configurable: false, canPeriodicRefresh: true} },
    { type: componentTypes.FLEET_AUDIT_LOG, w: 4, h: 3, minW: 4, minH: 2,
        metaConfiguration: { configurable: true, canPeriodicRefresh: true} ,
        configuration: {
            canFreeSize: true,
            columns: [
                {name: "ActionType", visible: true},
                {name: "NewState", visible: true},
                {name: "Age", visible: true},
                {name: "TruckName", visible: false},
                {name: "DatavanName", visible: false},
                {name: "RaisedTimestamp", visible: false},
                {name: "OldState", visible: false},
                {name: "Source", visible: false},
                {name: "Detail", visible: false},
                {name: "SubType", visible: false},
                {name: "UiComponent", visible: false}
            ],
            rowSizes: [25, 50, 100, 200],
            rowSize: 25
        },
    },
    { type: componentTypes.DATA_GRID, w: 2, h: 2, minW: 1, minH: 1, metaConfiguration: { configurable: true, canPeriodicRefresh: true}, configuration: { canFreeSize: true } },
  ]
  
  if (ownerId === OWNERS.NEXTIER) {
    cardsList.push({ type: componentTypes.FLEET_VIBRATION, w: 2, h: 3, metaConfiguration: { configurable: false, canPeriodicRefresh: true }  });
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(JSON.stringify(cardsList))
    }, 1000)
  });
};

export {
  fetchFleetMapSummary,
  fetchFleetDashboardCards
}
