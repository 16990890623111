import _ from "lodash";
import React, {Component} from 'react';
import moment from "moment";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from "prop-types";
import DataExplorerTicks from './dataExplorerTicks';
import { Typography, Button, ButtonBase, CircularProgress, Box, TextField, Tooltip } from '@mui/material';

import * as dataExplorerService from './dataExplorerService';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AutoCompleteMDT, DateTimePickerMDT } from "../../controls/mdtMuiControls";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

class DataExplorer extends Component {

  constructor(props) {
    super(props);

    dataExplorerService.resetSpecs();

    if (props.customSpecs) {
      dataExplorerService.updateSpecs(props.customSpecs);
    }

    this.specs = dataExplorerService.SPECS;

    this.styles = {
      chartContainer: {
        width: this.specs.CHART_WIDTH,
        height: this.specs.CHART_HEIGHT,
        overflowX:'hidden',
        overflowY:'hidden',
        userSelect: 'none',
        cursor:'ew-resize',
        backgroundColor: 'background.default',
        MozUserSelect: '-moz-none',
        KhtmlUserSelect: 'none',
        WebkitUserSelect: 'none',
        OUserSelect: 'none',
      },
      centerLine: {
        width: this.specs.CHART_WIDTH,
        height: '1px',
        backgroundColor: 'grey.500',
        top: (this.specs.CHART_HEIGHT - 35) / 2
      },
      block: {
        height: '40px',
        backgroundColor:"#355B9C"
      },
      selectionWindow: {
        width: this.specs.CHART_WIDTH / 3,
        height: this.specs.CHART_HEIGHT - 35,
        pointerEvents:'none',
        backgroundColor: 'grey.500',
        opacity:0.2
      },
      selectionWindowBorder: {
        width: '1px',
        height: this.specs.CHART_HEIGHT - 35,
        pointerEvents:'none',
        backgroundColor: 'grey.500',
      },
      allWindowBorder: {
        selectionWindowStart: this.specs.CHART_WIDTH / 3 - 1,
        selectionWindowEnd: this.specs.CHART_WIDTH / 3 * 2 - 1,
      },
      hoverButton: {
        width: this.specs.HOVER_BUTTON_WIDTH,
        height: this.specs.CHART_HEIGHT - 35,
        display:'flex',
        alignItems:'center',
        justifyContent: 'center',
        rightScrollOffset: this.specs.CHART_WIDTH - this.specs.HOVER_BUTTON_WIDTH,
        '&:hover': {
          backgroundColor: 'grey.800',
        },
        '& svg': {
          color: 'grey.500'
        }
      },
      durationButtonContainer: {
        display:'flex',
        alignItems: 'center',
        paddingBottom: 1,
        paddingLeft: 3,
      },
      busyOverlay: {
        width: this.specs.CHART_WIDTH,
        height: this.specs.CHART_HEIGHT,
        backgroundColor: 'background.default',
        opacity: 0.50
      },
      loadingCircle: {
        width: this.specs.CHART_HEIGHT - 35,
      },
      blockTop: ((this.specs.CHART_HEIGHT - 35) - this.specs.DATA_LINE_HEIGHT) / 2,
      timeLabel: {
        marginTop: '5px'
      }
    };

    this.state = {
      dragScrolling: false,
      clientX: 0,
      verticalLines: [], // NEW: Stores positions for vertical lines
      dataInSelection: [],
    }
  }

  onStartDragScroll = (event) => {

    this.setState({
      dragScrolling: true,
      clientX: event.type === 'touchstart' ? event.touches[0].clientX : event.clientX,
    });

  };

  onDragScroll = (event) => {

    if (!_.isNil(this.state) && this.state.dragScrolling) {

      this.setState({
        verticalLines: [],
        dataInSelection: [],
      });

      let clientX = event.type === 'touchmove' ? event.touches[0].clientX : event.clientX;
      this.setState({clientX: clientX});

      let diff =  this.state.clientX - clientX;
      let minutesAdjustment = diff * (this.props.selectedDuration.value/(dataExplorerService.SPECS.CHART_WIDTH / 3));

      this.onSelectDateTime(moment(this.props.selectedDateTime).add(minutesAdjustment, 'minutes'));
    }
  };

  onStopDragScroll = () => {
    this.setState({
      dragScrolling: false,
      clientX: 0,
    });
    this.onSetChartStartTime(moment(this.props.selectedDateTime))
  };

  onSelectDateTime(dateTime) {
    if (!_.isNil(dateTime) && !_.isNil(this.props.onDateTimeChange)) {
      this.props.onDateTimeChange(dateTime);
    }
  }

  onSelectDuration(duration) {
    if (!_.isNil(duration) && !_.isNil(this.props.onDurationChange)) {
      this.props.onDurationChange(duration);
    }
  };

  onSetChartStartTime(chartStartTime) {
    if (!_.isNil(chartStartTime) && !_.isNil(this.props.setChartStartTime)) {
      this.props.setChartStartTime(chartStartTime);
    }
  }
  onFindNext() {
    if (!_.isNil(this.props.onFindNext)) {
      this.setState({ verticalLines: [] });
      this.props.onFindNext();
    }
  };

  onFindPrevious() {
    if (!_.isNil(this.props.onFindPrevious)) {
      this.setState({ verticalLines: [] });
      this.props.onFindPrevious();
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.zoomStats && !_.isEqual(prevProps.zoomStats, this.props.zoomStats)) {
      const zoomStartTime = moment(this.props.zoomStats.min * 1000);
      const zoomEndTime = moment(this.props.zoomStats.max * 1000);

      // Determine if the user is zoomed in
      const isZoomedIn = zoomEndTime.diff(zoomStartTime, 'minutes') < this.props.selectedDuration.value;

      // If not zoomed in, clear verticalLines and exit
      if (!isZoomedIn) {
        this.setState({ verticalLines: [] });
        return;
      } else {
        const leftTime = dataExplorerService.getLeftTime(this.props.selectedDateTime, this.props.selectedDuration);
        const pixelsPerMinute = dataExplorerService.getPixelsPerMinute(this.props.selectedDuration);

        this.setState(
          {
            dataInSelection: dataExplorerService.calculateDataInSelectionWindow(
              this.props.selectedDateTime,
              this.props.selectedDuration,
              this.props.dataRanges
            ),
          },
          () => {
            // duplicate array of data ranges in selection window for further calculations
            let duplicateDataInSelection = this.state.dataInSelection
              .filter(
                (item) =>
                  item.rangeEnd.isAfter(zoomStartTime) && item.rangeStart.isBefore(zoomEndTime)
              )
              .map((item) => ({ ...item }));

            if (duplicateDataInSelection.length > 0) {
              // Use Math.max to ensure rangeStart is not earlier than zoomStartTime
              duplicateDataInSelection[0].rangeStart = moment.max(duplicateDataInSelection[0].rangeStart, zoomStartTime);

              // Use Math.min to ensure rangeEnd is not later than zoomEndTime
              duplicateDataInSelection[duplicateDataInSelection.length - 1].rangeEnd = moment.min(
                duplicateDataInSelection[duplicateDataInSelection.length - 1].rangeEnd,
                zoomEndTime
              );
            }

            // Convert rangeStart and rangeEnd to pixel positions
            duplicateDataInSelection = duplicateDataInSelection.map((item) => ({
              ...item,
              rangeStart: item.rangeStart.diff(leftTime, 'minutes') * pixelsPerMinute,
              rangeEnd: item.rangeEnd.diff(leftTime, 'minutes') * pixelsPerMinute,
            }));

            this.setState({ verticalLines: duplicateDataInSelection });
          }
        );
      }
    }
  }

  renderContent = () => {

    let blocks = dataExplorerService.getBlocksToRender(this.props.selectedDateTime, this.props.dataRanges, this.props.selectedDuration, this.props.customSpecs);
    let moreDataLeft = _.isNil(this.props.selectedDateTime) ? false : dataExplorerService.getPreviousBlockEdge(this.props.selectedDateTime, this.props.dataRanges) != null;
    let moreDataRight = _.isNil(this.props.selectedDateTime) ? false : dataExplorerService.getNextBlockEdge(this.props.selectedDateTime, this.props.dataRanges) != null;

    return (
          <div>
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'row nowrap',
                paddingBottom: 2,
              }}
            >
              {/* Optional Start Time Selector */}
              {this.props.selectedCustomStartTimeDisplay &&
                this.props.setCustomStartTime &&
                this.props.setChartStartTime &&
                this.props.setCustomStartTimeDisplay && (
                  <Box sx={{ width: '200px', marginBottom: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DateTimePickerMDT
                        value={this.props.selectedCustomStartTimeDisplay}
                        onAccept={(value) => {
                          if (!_.isNil(value)) {
                            this.props.setCustomStartTime(value);
                            this.props.setChartStartTime(value);
                          }
                        }}
                        onChange={(value) => {
                          if (!_.isNil(value)) {
                            this.props.setCustomStartTimeDisplay(value);
                          }
                        }}
                      />
                      <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between'}}> 
                        <Typography sx={this.styles.timeLabel} variant="caption">
                          Time *
                        </Typography>
                        <Tooltip title="Set to current date and time">
                          <Button sx={{marginRight: '18px', minWidth: '48px'}} variant="text" size="small" 
                            onClick={() => {
                              this.props.setCustomStartTime(moment());
                            }}>
                            Now
                          </Button>
                        </Tooltip>
                      </Box>
                    </LocalizationProvider>
                  </Box>
                )}

              {/* Optional Time Zone Selector */}
              {this.props.groupedTimezones && this.props.selectedTimezone && (
                <Box sx={{ width: '320px', paddingLeft: 3, marginBottom: 2 }}>
                  <AutoCompleteMDT
                    groupBy={(option) => option.group}
                    options={_.sortBy(this.props.groupedTimezones, ['order', 'label'])}
                    value={this.props.selectedTimezone}
                    onChange={(event, value) => this.props.selectTimezone(value)}
                    noOptionsText={'No time zone found...'}
                    disableClearable
                    renderInput={(params) => <TextField {...params} variant="standard" />}
                  />
                  <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between'}}> 
                    <Typography sx={this.styles.timeLabel} variant="caption">
                      Export Time Zone
                    </Typography>
                  </Box>
                </Box>
              )}
              {/* Render the duration buttons */}
              <Box sx={this.styles.durationButtonContainer}>
                <Typography variant={'caption'}>Duration:</Typography>
                {this.props.durations.map((duration, index) => {
                  return (
                    <Button
                      key={index}
                      color={
                        duration.value === this.props.selectedDuration.value
                          ? 'primary'
                          : 'inherit'
                      }
                      size="small"
                      onClick={() => {
                        return this.onSelectDuration(duration);
                      }}
                    >
                      {duration.label}
                    </Button>
                  );
                })}
              </Box>
            </Box>

            {/* Render the main chart area */}
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  ...this.styles.chartContainer,
                  height: this.specs.CHART_HEIGHT,
                }}
                // Desktop event handlers
                onMouseDown={this.onStartDragScroll}
                onMouseMove={this.onDragScroll}
                onMouseUp={this.onStopDragScroll}
                onMouseLeave={this.onStopDragScroll}
                // Touch screen event handlers
                onTouchStart={this.onStartDragScroll}
                onTouchMove={this.onDragScroll}
                onTouchEnd={this.onStopDragScroll}
              >
                <Box
                  sx={{
                    position: 'relative',
                    width: '1500px',
                    height: this.specs.CHART_HEIGHT,
                  }}
                >
                  {/* Render the ticks */}
                  <Box
                    sx={{ position: 'absolute', bottom: '0px', left: '0px' }}
                  >
                    <DataExplorerTicks
                      selectedDateTime={this.props.selectedDateTime}
                      selectedDuration={this.props.selectedDuration}
                    />
                  </Box>
                  {/* Render the center line of the chart */}
                  <Box
                    sx={{
                      ...this.styles.centerLine,
                      position: 'relative',
                      left: '0px',
                    }}
                  />
                  {/* Render any blocks */}
                  {blocks &&
                    blocks.map((block, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            ...this.styles.block,
                            position: 'absolute',
                            top: this.styles.blockTop,
                            left: block.left,
                            width: block.width,
                            height: this.specs.DATA_LINE_HEIGHT,
                          }}
                        />
                      );
                    })}
                </Box>
              </Box>
              {/* Render the selection window */}
              <Box
                sx={{
                  ...this.styles.selectionWindowBorder,
                  position: 'absolute',
                  top: '0px',
                  left: this.styles.allWindowBorder.selectionWindowStart,
                }}
              />
              {/* Render vertical lines */}
              {this.state.verticalLines.map((range, index) => (
                <Box
                  key={index}
                  sx={{
                    position: 'absolute',
                    top: this.styles.blockTop,
                    left: range.rangeStart, // Start of the range in pixels
                    width: `${range.rangeEnd - range.rangeStart}px`, // Width of the range
                    height: this.specs.DATA_LINE_HEIGHT,
                    backgroundColor: 'rgba(206, 67, 0, 0.8)', // 20% transparent
                    pointerEvents: 'none',
                  }}
                />
              ))}
              <Box
                sx={{
                  ...this.styles.selectionWindowBorder,
                  position: 'absolute',
                  top: '0px',
                  left: this.styles.allWindowBorder.selectionWindowEnd,
                }}
              />
              <Box
                sx={{
                  ...this.styles.selectionWindow,
                  position: 'absolute',
                  top: '0px',
                  left: this.styles.allWindowBorder.selectionWindowStart,
                }}
              />
              {/* Render the previous button */}
              {moreDataLeft && (
                <ButtonBase
                  focusRipple
                  onClick={() => this.onFindPrevious()}
                  sx={{
                    ...this.styles.hoverButton,
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                  }}
                >
                  <ChevronLeftIcon fontSize={'large'} />
                </ButtonBase>
              )}
              {/* Render the next button */}
              {moreDataRight && (
                <ButtonBase
                  focusRipple
                  onClick={() => this.onFindNext()}
                  sx={{
                    ...this.styles.hoverButton,
                    position: 'absolute',
                    top: '0px',
                    left: this.styles.hoverButton.rightScrollOffset,
                  }}
                >
                  <ChevronRightIcon fontSize={'large'} />
                </ButtonBase>
              )}
              {/* Render the busy overlay */}
              {this.props.isBusy && (
                <div>
                  <Box
                    sx={{
                      ...this.styles.busyOverlay,
                      position: 'absolute',
                      top: '0px',
                      left: '0px',
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress size={this.styles.loadingCircle.width} />
                  </Box>
                </div>
              )}
              {/* Show a message when we have no data ranges to render */}
              {!this.props.isBusy &&
                !_.isNil(this.props.dataRanges) &&
                _.isEmpty(this.props.dataRanges) && (
                  <div>
                    <Box
                      sx={{
                        ...this.styles.busyOverlay,
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                      }}
                    />
                    <Typography
                      variant={'h4'}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, calc(-50% - 17.5px))',
                      }}
                    >
                      No Data
                    </Typography>
                  </div>
                )}
            </Box>
          </div>
    )

  }

  render() {
    return this.props.useAccordion ? (
      <Accordion sx={{ 
        '& .MuiAccordionSummary-root': { 
          minHeight: '36px',
        },
        '& .MuiAccordionSummary-root.Mui-expanded': { 
          minHeight: '36px',
        }
      }} 
      onChange={(event, expanded) => this.setState({ expanded })}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} 
          sx={{ 
            alignItems: 'center', 
            '& .MuiAccordionSummary-content': { 
              marginTop: '3px', 
              marginBottom: '3px',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
              marginTop: '3px', 
              marginBottom: '3px',
            }
          }}>
          {this.state.expanded ? (
            <Typography variant="body2">Current Settings</Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center', // Vertical alignment
                flexWrap: 'wrap', // Allow wrapping if content is long
              }}
            >
              {[
                this.props.selectedCustomStartTimeDisplay && {
                  label: 'Start Time',
                  value: moment(this.props.selectedDateTime).format('MMM D, YYYY, h:mm A'),
                },
                this.props.selectedTimezone && {
                  label: 'Timezone',
                  value: this.props.selectedTimezone.label,
                },
                this.props.selectedDuration && {
                  label: 'Duration',
                  value: this.props.selectedDuration.label,
                },
              ]
                .filter(Boolean) // Filter out any falsy values (e.g., null or undefined)
                .map((item, index) => (
                  <Typography
                    key={item.label}
                    variant="body2"
                    sx={{
                      display: 'inline',
                      marginLeft: index === 0 ? 0 : 3, // Dynamically calculate left margin
                    }}
                  >
                    {item.label}: {item.value}
                  </Typography>
                ))}
            </Box>
          )}
        </AccordionSummary>
        <AccordionDetails>
          {this.renderContent()}
        </AccordionDetails>
      </Accordion>
    ) : (
        this.renderContent()
    );
  }
}

DataExplorer.defaultProps = {
  useAccordion: false,
};

DataExplorer.propTypes = {

  // Properties
  selectedDateTime: PropTypes.object,
  selectedDuration: PropTypes.object,
  durations: PropTypes.array,
  dataRanges: PropTypes.array,
  isBusy: PropTypes.bool,
  useAccordion: PropTypes.bool,

  // Actions
  onDateTimeChange: PropTypes.func,
  onDurationChange: PropTypes.func,
  onFindNext: PropTypes.func,
  onFindPrevious: PropTypes.func,

  customSpecs: PropTypes.object,

  // New props for Date-Time Picker
  selectedCustomStartTimeDisplay: PropTypes.object,
  setCustomStartTime: PropTypes.func,
  setChartStartTime: PropTypes.func,
  setCustomStartTimeDisplay: PropTypes.func,

  // Props for Time Zone Selector
  groupedTimezones: PropTypes.array,
  selectedTimezone: PropTypes.object,
  selectTimezone: PropTypes.func,

  zoomStats: PropTypes.object,
};

export default DataExplorer;