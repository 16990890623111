import actionTypes from "../dataExplorationChartActionTypes";
import reduceReducers from "reduce-reducers";
import {
  calculateSeriesStats,
} from "../services/dataExplorationChartService";
import {
  defnUpdateConfig,
  defnUpdateSensors,
  defnUpdateTemplate,
} from "../services/dataExplorationChartDefinitionService";
import {
  generateXYValues,
  setSensorData,
  initializeData,
} from "../services/dataExplorationChartDataManager";
import {dataExplorationChartState} from "../dataExplorationChartSelectors";
import _ from "lodash";

const initialState = dataExplorationChartState();

const chartReducer = (state = initialState, action) => {
  const reducer = reduceReducers(
    inputsReducer,
    queryReducer,
  );

  return reducer(state, action);
};

const inputsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.DATA_EXPLORATION_CHART_SET_TEMPLATE:
      return onTemplateUpdated(state, action.template, action.initialLoad);
    case actionTypes.DATA_EXPLORATION_CHART_SET_CONFIG_YAXIS:
      return onConfigUpdatedYAxis(state, action.xAxisId, action.refIndex, action.yAxisId);
    case actionTypes.DATA_EXPLORATION_CHART_SET_CONFIG_COLOR:
      return onConfigUpdatedColor(state, action.xAxisId, action.refIndex, action.color);
    case actionTypes.DATA_EXPLORATION_CHART_SET_CONFIG_VISIBLE:
      return onConfigUpdatedVisible(state, action.xAxisId, action.refIndex, action.isVisible);
    case actionTypes.DATA_EXPLORATION_CHART_CALCULATE_STATS:
      return onAxisChange(state, action.xAxisId, action.min, action.max);
    case actionTypes.DATA_EXPLORATION_CHART_OPEN_SENSOR_SELECTOR:
      return updateSensorSelectorState(state, true);
    case actionTypes.DATA_EXPLORATION_CHART_CLOSE_SENSOR_SELECTOR:
      return updateSensorSelectorState(state, false);
    case actionTypes.DATA_EXPLORATION_CHART_SET_SELECTED_SENSORS:
      return onSelectedSensors(state, action.xAxisId, action.sensors);
    case actionTypes.DATA_EXPLORATION_CHART_SET_COLOR_PICKER_STATE:
      return onColorPickerState(state, action.xAxisId, action.refIndex, action.origColor);
    case actionTypes.DATA_EXPLORATION_CHART_ADJUST_DATA_RANGE:
      return onAdjustDataRange(state, action.xAxisId, action.min, action.max);
    case actionTypes.DATA_EXPLORATION_ROTATE: 
      return onRotate(state, action);
    case actionTypes.DATA_EXPLORATION_CHART_CLICK_SETTINGS_POPUP:
      return onClickSettingsPopup(state, action.chartSettingButtonAnchor);
    case actionTypes.DATA_EXPLORATION_CHART_TOGGLE_CLOSENANGAP:
      return onToggleCloseNaNGap(state);
    case actionTypes.DATA_EXPLORATION_CHART_SCREENSHOT_SETTINGS_POPUP:
      return onScreenShotSettingsPopup(state, action.screenShotButtonAnchor);
    case actionTypes.DATA_EXPLORATION_CHART_TOGGLE_DELAYED_SCREENSHOT:
      return onToggleDelayedScreenShot(state);
    case actionTypes.DATA_EXPLORATION_CHART_TOGGLE_YAXIS_ZOOM_LOCK:
      return onToggleYAxisZoomLock(state, action);
    default:
      return state;
  }
};

const queryReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.DATA_EXPLORATION_CHART_QUERY_RESET:
      return {...state, data: { primary: {}}};
    case actionTypes.DATA_EXPLORATION_CHART_QUERY_STARTING:
      let data = initializeData(state.definition, state.data);
      return {...state, data: data, queryRunning: true};
    case actionTypes.DATA_EXPLORATION_CHART_QUERY_SUCCESS:
      return onQuerySuccess(state, action);
    case actionTypes.DATA_EXPLORATION_CHART_QUERY_ERROR:
      return {...state, queryRunning: false};
    case actionTypes.DATA_EXPLORATION_CHART_PROGRESS_STATE:
      return {...state, queryRunning: action.state};
    default:
      return state;
  }
};

const onQuerySuccess = (state, action) => {
  // At this point, new data has come back from the query
  // We need to merge it into the existing data
  let newData = _.cloneDeep(state.data);
  if (!_.isEmpty(action.queryResults.chartSeries)) {
    _.forEach(['primary'], (xAxisId) => { 
      newData[xAxisId].yValues = setSensorData(state.definition[xAxisId], state.data[xAxisId].xValues, newData[xAxisId].yValues, action.queryResults.chartSeries);
    });
  }

  return {
    ...state,
    data: newData,
    queryRunning: false
  }
};

const onTemplateUpdated = (state, template, initialLoad) => {
  // updates the stored definition with any changes from the template
  let definition = _.cloneDeep(state.definition);
  definition = defnUpdateTemplate(definition, template, initialLoad);

  return {
    ...state,
    definition: definition,
    data: {primary: {}, secondary: {}},
    stats: {primary: {}, secondary: {}},
  }
};

const onConfigUpdatedYAxis = (state, xAxisId, refIndex, yAaxisId) => {
  let definition = _.cloneDeep(state.definition);
  definition = defnUpdateConfig(definition, xAxisId, refIndex, 'yAxisId', yAaxisId);
  return {
    ...state,
    definition: definition,
  }
};

const onConfigUpdatedColor = (state, xAxisId, refIndex, color) => {
  let definition = _.cloneDeep(state.definition);
  definition = defnUpdateConfig(definition, xAxisId, refIndex, 'color', color);
  return {
    ...state,
    definition: definition,
  }
};

const onConfigUpdatedVisible = (state, xAxisId, refIndex, isVisible) => {
  let definition = _.cloneDeep(state.definition);
  definition = defnUpdateConfig(definition, xAxisId, refIndex, 'isVisible', isVisible);
  return {
    ...state,
    definition: definition,
  }
};
/**
 *
 * @param state
 * @param axisId: the axis id: primary or secondary
 * @param min: the value of the left most visible timestamp on the axis
 * @param max: the value of the right most timestamp on the axis
 */
const onAxisChange = (state, axisId, min, max) => {
  const data = state.data[axisId];
  const results = calculateSeriesStats(data, min, max);
  let stats = state.stats;
  stats[axisId] = results;
  return {
    ...state,
    stats: stats,
  }
};

const onColorPickerState = (state, xAxisId, refIndex, origColor) => {
  return {
    ...state,
    colorPickerState: _.isNil(xAxisId) || _.isNil(refIndex) ? null : { xAxisId: xAxisId, refIndex: refIndex, origColor: origColor },
  }
};

const updateSensorSelectorState = (state, enabled) => {
  return {
    ...state,
    shouldOpenSensorSelector: enabled
  }
};

const onSelectedSensors = (state, xAxisId, newSensors) => {
  if (_.isEqual(state.definition[xAxisId].sensors, newSensors)) {
    return state;
  }

  let definition = _.cloneDeep(state.definition);
  definition = defnUpdateSensors(definition, xAxisId, newSensors);
  return {
    ...state,
    definition: definition,
  }
};

const onAdjustDataRange = (state, xAxisId, min, max) => {

  let newXYValues = generateXYValues(state.definition[xAxisId], min, max, state.data[xAxisId].xValues, state.data[xAxisId].yValues);
  let newData = {
    ...state.data,
    [xAxisId]: {
      ...state.data[xAxisId],
      xValues: newXYValues.xValues,
      yValues: newXYValues.yValues,
      lastUpdate: Date.now()
    },
  };
  newXYValues = null;

  return {
    ...state,
    data: newData,
    queryRunning: false
  }
};

const onClickSettingsPopup = (state, chartSettingButtonAnchor) => {
  return {
    ...state,
    openChartSettingsPopup: !state.openChartSettingsPopup,
    chartSettingButtonAnchor: chartSettingButtonAnchor
  }
};

const onToggleCloseNaNGap = (state) => {
  return {
    ...state,
    closeNaNGap: !state.closeNaNGap
  }
};

const onRotate = (state, action) => {
  return {
    ...state,
    isRotated: action.rotate
  }
}

const onScreenShotSettingsPopup = (state, screenShotButtonAnchor) => {
  return {
    ...state,
    openScreenShotSettingsPopup: !state.openScreenShotSettingsPopup,
    screenShotButtonAnchor: screenShotButtonAnchor
  }
};

const onToggleDelayedScreenShot = (state) => {
  return {
    ...state,
    isDelayedScreenShot: !state.isDelayedScreenShot
  }
};

const onToggleYAxisZoomLock = (state, action) => {

  const newDefinition = _.cloneDeep(state.definition);
  newDefinition[action.xAxisId].axisConfig.y.zoomLock = !state.definition[action.xAxisId].axisConfig.y.zoomLock;

  return {
    ...state,
    definition: newDefinition
  }
}

export default chartReducer
