import _ from 'lodash';
import PropTypes from 'prop-types';

import { Container, Draggable } from "@edorivai/react-smooth-dnd";
import {SENSOR_CONFIG_CONTROL_MAX_RULES} from "../../common/layout/layoutConstants";

import {
  Typography,
  TextField,
  FormControl,
  Box,
  Select,
  MenuItem,
  IconButton,
  Button,
  Checkbox,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack, Badge
} from "@mui/material";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HelpIcon from '@mui/icons-material/Help';

import MdtColorPicker from "../../common/colorPicker/mdtColorPicker";
import { mdtPalette } from '../../common/styles/mdtPalette';
import { useEffect, useState } from 'react';


const styles = {
  formattingApplied: {
    display: 'flex',
    alignItems: 'center',
    m: '0px',
  },
  sensorHeader: {
    alignItems: 'center',
    display: 'flex'
  },
  sensorTitle: {
    fontSize: '1.25rem',
    flexGrow: 2,
    alignSelf: 'center',
    width: '280px',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  hiddenIcon: {
    marginRight: '10px',
    alignSelf: 'center',
    ml: 'auto'
  },
  dragIcon: {
    marginRight: '10px',
    "&:hover": {
      color: 'grey.400',
      transform: 'scale(1.1)'
    }
  },
  sensorDisplayText: {
    m: '10px 0',
    width: '100%'
  },
  sensorUom: {
    display: 'flex',
    m: '0px 0px 10px 0px',
    alignItems: 'center'
  },
  uomSelection: {
      flexGrow: 1,
      marginLeft: 1,
  },
  uomDropdown: {
      m: 1,
      minWidth: 120,
      width: 'calc(100% - 16px)'
  },
  conditionalFormattingColor: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '48px',
    height: '48px',
    overflow: 'visible',
    justifyContent: 'start',
    flexFlow: 'row nowrap',
    position: 'relative'
  },
  conditionSelection: {
    marginLeft: 1,
    marginRight: 1
  },
  conditionDropdown: {
      marginTop: 1,
      marginBottom: 1,
      width: '145px'
  },
  colorPickerPopover: {
    position: 'absolute',
    top: '-170px',
    left: '0px',
  },
  conditionNumber: {
    "& .MuiInputBase-root": {
      "& input": {
        textAlign: "right"
      }
    },
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      'WebkitAppearance': 'none',
      margin: 0
    },
    'input[type=number]': {
      'MozAppearance': 'textfield'
    }
  },
  textAndBorderColorText: {
    marginRight: 0.75,
  }
}

/**
 * This is a common component that can be used for anywhere you require configuration of sensors and formatting rules for each sensor.
 * Current usages are in the Data Grid Card Config Panel and the Live View Config Panel.
 * @param {*} props 
 * @returns 
 */
const SensorConfigControl = (props) => {
  return (
    <Container
      onDrop={_.isNil(props.onDragDropCallback) ? () => {} : props.onDragDropCallback}
      lockAxis='y'
      dragHandleSelector=".drag-handle"
    >
      {_.map(props.sensors, (sensor, sensorIndex) => {
        const nonFormatColorPickerState = props.colorPickerStates[sensorIndex];
        const showNonFormatColorPicker = !_.isNil(nonFormatColorPickerState) && nonFormatColorPickerState.sensor === sensor.sensorSetId+'_'+sensor.alias+'_unconditional';
        const borderAndTextColorRules = sensor?.borderAndTextColorRules;

        return (
          <Draggable key={sensor.sensorSetId+'_'+sensor.alias} style={{padding: '8px'}}>
            <Accordion elevation={6} disableGutters>
              <AccordionSummary sx={styles.sensorHeader} expandIcon={<ExpandMoreIcon />}>
                <Tooltip title={sensor.alias} disableInteractive>
                  <Badge badgeContent={sensor.conditionalFormatting.applied? 1: 0}  variant="dot" color={"secondary"} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}>
                    <Typography sx={styles.sensorTitle}>{sensor.alias}</Typography>
                  </Badge>
                </Tooltip>
                <Box sx={{display:'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end', flexGrow: 1}}>
                  {!_.isNil(props.canRemoveSensor) && (props.canRemoveSensor === true) &&
                    <IconButton
                      sx={{ width: '40px', ml: !sensor.isVisible? '':'auto'}}
                      onClick={() => { props.onRemoveSensorCallback(sensor.sensorSetId, sensor.alias, props.cardWidth * props.cardHeight);}}
                      size='small'>
                      <Tooltip title='Remove Sensor' disableInteractive>
                        <DeleteOutlineIcon/>
                      </Tooltip>
                    </IconButton>
                  }
                  <IconButton className="drag-handle" sx={styles.dragIcon} disableRipple>
                    <Tooltip title='Reorder sensor' disableInteractive>
                      <DragHandleIcon />
                    </Tooltip>
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                    error={sensor.displayName === ""}
                    sx={styles.sensorDisplayText}
                    id="display-text"
                    size="small"
                    helperText="Display Text"
                    variant="standard"
                    value={sensor.displayName}
                    onChange={(event) => {
                      props.onChangeSensorDisplayNameCallback(sensor.sensorSetId, sensor.alias, event.target.value);
                    }}
                />
                <Box sx={styles.sensorUom}>
                  <Typography variant={'subtitle1'}>Unit of Measure:</Typography>
                  <Box sx={styles.uomSelection}>
                    <FormControl sx={styles.uomDropdown} size="small">
                      <Select
                        MenuProps={{ disableScrollLock: true }}
                        value={sensor.uom}
                        onChange={(event) => {
                          props.onChangeSensorUOMCallback(sensor.sensorSetId, sensor.alias, event.target.value);
                        }}
                        displayEmpty
                      >
                        {_.isEmpty(sensor.targetUoms) ? (
                          <MenuItem key={sensor.sensorSetId + '_' + sensor.uom} value={sensor.uom}>{sensor.uom}</MenuItem>
                        ) : (
                          sensor.targetUoms.map(uom => (
                            <MenuItem key={sensor.sensorSetId + '_' + uom} value={uom}>{uom}</MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={styles.conditionalFormattingColor}>
                  <Checkbox
                    defaultChecked={!_.isNil(borderAndTextColorRules) ? borderAndTextColorRules.applyRules : false}
                    sx={styles.formattingCheckbox}
                    onClick={(event) => props.onChangeSensorBorderAndTextColor(sensor.sensorSetId, sensor.alias, {...borderAndTextColorRules, applyRules: event.target.checked})}
                  />
                  <Typography sx={styles.textAndBorderColorText}> Text Color </Typography>
                  <MdtColorPicker
                    key={`nonFormatColorPicker_${sensor.sensorSetId}_${sensor.alias}_${sensorIndex}`}
                    showColorPicker={showNonFormatColorPicker}
                    id={sensor.sensorSetId+'_'+sensor.alias+'_unconditional'}
                    color={!_.isNil(borderAndTextColorRules?.color) ? borderAndTextColorRules.color : mdtPalette().typography.color}
                    origColor={!_.isNil(nonFormatColorPickerState) ? nonFormatColorPickerState.origColor : null}
                    onSetColorPickerState={(id, color) => props.onSetColorPickerStateCallback(id, sensorIndex, color)}
                    onConfigChangedColor={(alias, color) => props.onChangeSensorBorderAndTextColor(sensor.sensorSetId, sensor.alias, {...borderAndTextColorRules, color: color})}
                    colorPreviewStyles={{ padding: 0 }}
                  /> 
                </Box>
                <Box>
                  <Box sx={styles.formattingApplied}>
                    <Checkbox
                      checked={sensor.conditionalFormatting.applied}
                      sx={styles.formattingCheckbox}
                      onChange={(event) => props.onChangeSensorPropertyCallback(sensor.sensorSetId, sensor.alias, null, 'applied', event.target.checked)}
                    />
                    <Typography sx={{width: '115px'}}>Format Rules</Typography>
                    <Tooltip title="Rules are applied top to bottom. When multiple rules are satisfied, only the color defined in the last rule will be applied." disableInteractive>
                      <IconButton sx={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '1px'}} size='small'>
                        <HelpIcon/>
                      </IconButton>
                    </Tooltip>
                    <Button onClick={() => { props.onAddRuleCallback(sensor.sensorSetId, sensor.alias);}} color={'inherit'} sx={{ml: 'auto'}} disabled={sensor.conditionalFormatting.rules.length >= SENSOR_CONFIG_CONTROL_MAX_RULES}>
                      <AddIcon/>
                      ADD
                    </Button>
                  </Box>
                  <Stack>
                  {
                    sensor.conditionalFormatting.rules.map((rule, index)=>{
                      const colorPickerState = props.colorPickerStates[index];
                      const showColorPicker = !_.isNil(colorPickerState) && colorPickerState.sensor === sensor.sensorSetId+'_'+sensor.alias+'';
                      return ( 
                        <Box sx={styles.conditionalFormattingColor} key={`conditionalFormatter_${sensor.sensorSetId}_${sensor.alias}_${index}`}>
                          <MdtColorPicker
                              key={`conditionalFormatterColorPicker_${sensor.sensorSetId}_${sensor.alias}_${index}`}
                              showColorPicker={showColorPicker}
                              id={sensor.sensorSetId+'_'+sensor.alias+''}
                              color={rule.color}
                              origColor={!_.isNil(colorPickerState) ? colorPickerState.origColor : null}
                              onSetColorPickerState={(id, color) => props.onSetColorPickerStateCallback(id, index, color)}
                              onConfigChangedColor={(alias, color) => props.onChangeSensorPropertyCallback(sensor.sensorSetId, sensor.alias, index, 'color', color)}
                              colorPreviewStyles={{ padding: 0 }}
                          />
                          <Typography sx={{ marginLeft: showColorPicker ? '29px' : '5px' }}>when value is</Typography>
                          <Box sx={styles.conditionSelection}>
                            <FormControl sx={styles.conditionDropdown} size="small">
                              <Select
                                MenuProps={{ disableScrollLock: true }}
                                value={rule.condition}
                                onChange={(event) => {
                                  props.onChangeSensorPropertyCallback(sensor.sensorSetId, sensor.alias, index, 'condition', event.target.value)
                                }}
                                displayEmpty
                              >
                                {props.conditionOptions.map(condition => (
                                  <MenuItem key={sensor.sensorSetId + '_' + condition} value={condition}>{condition}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                          <TextField
                            sx={{ ...styles.conditionNumber,width: rule.condition.includes("between") ? '45px' : '110px', marginRight: '5px'}}
                            type="number"
                            id="value-one"
                            size="small"
                            variant="standard"
                            value={rule.value1}
                            onChange={(event) => {
                              props.onChangeSensorPropertyCallback(sensor.sensorSetId, sensor.alias, index, 'value1', _.isEmpty(event.target.value) ? '' : parseFloat(event.target.value));
                            }}
                          />
                          {sensor.conditionalFormatting.rules[index].condition.includes("between") && 
                            (
                              <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ marginRight: '5px' }}>and</Typography>
                                <TextField
                                  sx={{ ...styles.conditionNumber, width: '45px'}}
                                  type="number"
                                  id="value-two"
                                  size="small"
                                  variant="standard"
                                  value={rule.value2}
                                  onChange={(event) => {
                                    props.onChangeSensorPropertyCallback(sensor.sensorSetId, sensor.alias, index, 'value2', _.isEmpty(event.target.value) ? '' : parseFloat(event.target.value));
                                  }}
                                />
                              </Box>
                            )
                          }
                          <IconButton
                            sx={{ml: 'auto'}}
                            onClick={() => { props.onRemoveRuleCallback(sensor.sensorSetId, sensor.alias, index); }}
                            size='small'>
                            <Tooltip title='Remove rule' disableInteractive>
                              <DeleteOutlineIcon/>
                            </Tooltip>
                          </IconButton>
                        </Box>
                      )
                    })
                  }
                  </Stack>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Draggable>
        )
      })} 
    </Container>
  )
}

SensorConfigControl.propTypes = {
  sensors: PropTypes.array.isRequired,

  onDragDropCallback: PropTypes.func.isRequired,

  sensorNotVisibleText: PropTypes.string,

  canRemoveSensor: PropTypes.bool.isRequired,
  onRemoveSensorCallback: PropTypes.func,
  cardWidth: PropTypes.number,
  cardHeight: PropTypes.number,

  onChangeSensorDisplayNameCallback: PropTypes.func.isRequired,
  onChangeSensorUOMCallback: PropTypes.func.isRequired,

  onChangeSensorPropertyCallback: PropTypes.func.isRequired,
  onChangeSensorBorderAndTextColor: PropTypes.func.isRequired,

  onAddRuleCallback: PropTypes.func.isRequired,

  colorPickerStates: PropTypes.array.isRequired,
  onSetColorPickerStateCallback: PropTypes.func.isRequired,

  onRemoveRuleCallback: PropTypes.func.isRequired,

  conditionOptions: PropTypes.array.isRequired
}

export default SensorConfigControl;