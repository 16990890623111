import _ from 'lodash';
import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import historyHelper from '../../helpers/historyHelper';
import Layout from './appLayout';
import store from '../../helpers/storeHelper';
import { initializeTracking } from '../../helpers/googleAnalyticsHelper';
import { setLicenses } from './appLicenses';
import { closeWebSocket } from '../../state/app/actions/appWebsocketActions';

const beforeUnloadCallback = (event) => {
  if (!_.isNil(store.getState()?.app.live.intervalId)) {
    // Clear the interval when the component is unmounted
    clearInterval(store.getState().app.live.intervalId);
  }
  store.dispatch(closeWebSocket());
}

class App extends Component {

  componentDidMount() {
    if(process.env.REACT_APP_OFFLINE_MODE !== "true") {
      initializeTracking();

    }
    setLicenses();

    // If we aren't running in test, then setup the beforeunload event listener
    if (_.isNil(process.env.CI) || process.env.CI.trim() !== 'true') {
      window.addEventListener('beforeunload', beforeUnloadCallback);
    }

  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', beforeUnloadCallback);
  }

  render() {
    return (
      <Provider store={store}>
        <React.Fragment>
          <CssBaseline enableColorScheme />
          <ConnectedRouter history={historyHelper}>
            <Layout/>
          </ConnectedRouter>
        </React.Fragment>
      </Provider>
    );
  }
}

export default App;
