import React, {Component} from 'react';

import {
  Box,
  Typography
} from '@mui/material';

import ComponentTypes from '../../componentTypes';
import getDetailsPageStyles from '../../common/styles/detailsPageStyles';
import {connect} from "react-redux";
import {compose} from "redux";
import {withProps} from "recompose";
import _ from "lodash";

const detailsPageStyles = getDetailsPageStyles();

const styles = {
  ...detailsPageStyles,
  detailsPageHeaderTitle: {
    display: 'flex',
    alignItems: 'left',
    width: '165px',
  },
};

class TreatmentOverview extends Component {



  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }


  render() {

    return (
        <Box sx={styles.detailsPage}>
          <Box sx={styles.detailsPageContent} >
            <Box sx={styles.detailsPageHeader}>
              <Box sx={styles.detailsPageHeaderTitle}>
                <Typography variant={'h6'}>Treatment Overview</Typography>
              </Box>
              <Box sx={styles.detailsPageHeaderTitle}><Typography variant={'h6'}>Not implemented</Typography></Box>
            </Box>
          </Box>
        </Box>
    );
  }
}


const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.TREATMENT_OVERVIEW : props.stateKey,
      type: ComponentTypes.TREATMENT_OVERVIEW,
    }
  }
};

const mapStateToProps = (state, props) => {

  const { stateDef } = props;

  return {
  }
};

const mapDispatchToProps = (dispatch, props) => {

  return {
  }
};

export default compose (
    withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(TreatmentOverview));