import _ from 'lodash';

const fracOpDGBMaintenanceOverviewCardState = state => {

  return {
    queryRunning: _.isNil(state) ? false : state.queryRunning,
    openMenu: _.isNil(state) ? false : state.openMenu,
    menuTargetElement: _.isNil(state) ? null : state.menuTargetElement,
    fleetFracOpDGBMainOverview: _.isNil(state) ? {} : state.fleetFracOpDGBMainOverview
  };

};

export {
  fracOpDGBMaintenanceOverviewCardState
};