import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { SketchPicker  } from "react-color";
import { Box } from "@mui/system";
import { mdtPaletteChartingColors } from "../styles/mdtPalette";
import { IconButton, Popover } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";

/**
 * MdtColorPicker is a color picker component that uses the react-color library to provide a color picker interface.
 * 
 * The SketchPicker is used to allow the user to select a color.
 * 
 * See https://casesandberg.github.io/react-color/ for an example of the SketchPicker.
 */
class MdtColorPicker extends Component {

    constructor(props) {
      super(props);
      this.state ={
        anchorEl: null
      }
    }

    render() {
        return (
            <>
              <Popover
                open={this.props.showColorPicker}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                anchorEl={this.state.anchorEl}
                sx={{
                  '.MuiPaper-root': {
                    boxShadow: '0px 10px 10px 3px rgb(0 0 0 / 50%)'
                  }
                }}
                >
                <Box sx={{zIndex: 2,
                          // For most cases, we don't need to use !important to override the default styles
                          // However the author of the react-color library has made it impossible to override the default styles
                          // without using !important so we have to use it here.
                          '& div.sketch-picker ': {
                            backgroundColor: 'unset !important',
                            boxShadow: 'unset !important',
                          },
                          '& div.sketch-picker .flexbox-fix label': {
                            color: 'unset !important',
                          } 
                        }}
                    // Close the picker on mouse leave
                    // Click to close does not work here because the user can click around to pick colors and we 
                    // want to enable exploration of colors
                    onMouseLeave={() => {
                      this.props.onSetColorPickerState(null, null);
                    }}
                    >
                    <SketchPicker
                      onChangeComplete={(color) => {
                        // permanently update the color
                        this.props.onConfigChangedColor(this.props.id, color.hex);
                      }}
                      color={this.props.color}
                      presetColors={mdtPaletteChartingColors()}
                      disableAlpha={true}
                    />
                </Box>
              </Popover>
              <IconButton
                  onClick={(event) => 
                    {
                      this.setState({ anchorEl: event.currentTarget });
                      this.props.onSetColorPickerState(this.props.id, this.props.color)
                    }
                  }
                  sx={this.props.colorPreviewStyles}
                  size="large">
                  <FiberManualRecord colorRendering={this.props.color} style={{ color: this.props.color }} />
              </IconButton>
            </>
        );
    }
}

MdtColorPicker.propTypes = {
    showColorPicker: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    origColor: PropTypes.string,
    onSetColorPickerState: PropTypes.func.isRequired,
    onConfigChangedColor: PropTypes.func.isRequired,
    colorPreviewStyles: PropTypes.object,
};

export default MdtColorPicker;