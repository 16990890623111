import dataExplorationDisplayActionTypes from "../dataExplorationDisplayActionTypes.js"
import { dataExplorationDisplayState } from '../dataExplorationDisplaySelector';
import * as timeService from "../services/timeService";

const initialState = dataExplorationDisplayState();

const formReducer = (state=initialState, action) => {
    switch (action.type) {
        case dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SELECT_DURATION:
            return onSelectDuration(state, action);
        case dataExplorationDisplayActionTypes.DATA_EXPLORATION_DISPLAY_SET_CHART_START_TIME:
            return onSetChartStartTime(state, action);
        default: return state;
    }
};

const onSelectDuration = (state, action) => {
  let newState = {
    ...state,
    selectedCustomDuration: action.duration,
  };
  newState.exceededMaximumDuration = timeService.hasExceededMaximumDuration(newState);
  newState.maximumDurationLabel = timeService.getMaximumDurationLabel(newState);
  return newState;
};

const onSetChartStartTime = (state, action) => {
  let newState = {
    ...state,
    selectedChartStartTime: action.chartStartTime,
  };
  return newState;
};
export default formReducer;