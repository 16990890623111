import { v4 as uuidv4 } from 'uuid';
import fracOpDGBMaintenanceOverviewCardActionTypes from './fracOpDGBMaintenanceOverviewCardActionTypes';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import * as fracOpDGBMaintenanceOverviewCardQueries from './fracOpDGBMaintenanceOverviewCardQueries';
import {handleError} from '../../app/actions/appErrorActions';
import errorMessages from '../../common/errorMessages';

const queryDataStarting = defaultActionFactory(fracOpDGBMaintenanceOverviewCardActionTypes.FRAC_OP_DGB_MAINTENANCE_OVERVIEW_CARD_QUERY_DATA_STARTING, 'stateDef', 'transactionId');
const queryDataSuccess = defaultActionFactory(fracOpDGBMaintenanceOverviewCardActionTypes.FRAC_OP_DGB_MAINTENANCE_OVERVIEW_CARD_QUERY_DATA_SUCCESS, 'stateDef', 'transactionId', 'queryResults');
const queryDataError = defaultActionFactory(fracOpDGBMaintenanceOverviewCardActionTypes.FRAC_OP_DGB_MAINTENANCE_OVERVIEW_CARD_QUERY_DATA_ERROR, 'stateDef');

const openMenu = defaultActionFactory(fracOpDGBMaintenanceOverviewCardActionTypes.FRAC_OP_DGB_MAINTENANCE_OVERVIEW_CARD_OPEN_MENU, 'stateDef', 'menuTargetElement');
const closeMenu = defaultActionFactory(fracOpDGBMaintenanceOverviewCardActionTypes.FRAC_OP_DGB_MAINTENANCE_OVERVIEW_CARD_CLOSE_MENU, 'stateDef');

const queryFracOpDGBMaintenanceOverviewData = (stateDef, client) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    let transactionId = uuidv4();

    try {
      await dispatch(queryDataStarting(stateDef, transactionId));
      queryResults = await fracOpDGBMaintenanceOverviewCardQueries.fetchFleetFracOpDGBMaintenanceOverview(client);
    } catch(e) {
      // We want to ensure multiple errors coming back only
      // result in 1 error dialog (the last error), rather than for all errors
      if (getState()[stateDef.key].queryTransactionId === transactionId) {
        await dispatch(queryDataError(stateDef));
        return dispatch(handleError(errorMessages.ERROR_RETRIEVING_FLEET_COMPONENT_CARD_DATA, e.message));
      }
      return dispatch(queryDataError(stateDef, transactionId));
    }
    return dispatch(queryDataSuccess(stateDef, transactionId, queryResults));
  }
};

export {
  queryDataStarting,
  queryDataSuccess,
  queryDataError,
  queryFracOpDGBMaintenanceOverviewData,
  openMenu,
  closeMenu
}