import React, {Component} from 'react';
import {compose, withProps} from "recompose";
import moment from 'moment';

import PropTypes from 'prop-types';
import ComponentTypes from '../../../componentTypes';

import { appState as applicationState } from '../../../../state/app/appSelectors';
import * as appUserConfigActions from '../../../../state/app/actions/appUserConfigActions';
import * as pumpDashboardActions from '../../../../state/displays/pumpDashboard/pumpDashboardActions';

import LayoutFixedWidth from '../../../controls/layout/layoutFixedWidth';

import { trackPage } from '../../../../helpers/googleAnalyticsHelper';
import {connect} from "react-redux";
import _ from "lodash";

import { getCardElementsFromLayout, addResizeHandle } from '../../../../helpers/cardElementsHelper';
import { CustomResizeHandle } from '../../../cards/mdtCard/controls/resizeHandle';

class CustomView extends Component {

    componentDidMount() {
        // Track GA View
        trackPage(ComponentTypes.PUMP_DASHBOARD + '/' + this.props.viewConfig.id, this.props.user);
    }

    render() {
        let viewConfiguration = this.props.viewConfig ?? {};

        let startTime, endTime;

        if (this.props.selectedTimeFrame.label !== 'Custom') {
            endTime = moment().startOf('minute').unix();
            startTime = moment.unix(endTime).subtract(this.props.selectedTimeFrame.value, 'minutes').unix();
        } else {
            startTime = moment(this.props.selectedCustomStartTime).unix();
            endTime = moment.unix(startTime).add(this.props.selectedCustomDuration.value, 'minutes').unix();
        }

        let cardElements = getCardElementsFromLayout(ComponentTypes.PUMP_DASHBOARD, viewConfiguration.id, viewConfiguration.cards, { truck: this.props.pump, startTime:startTime, endTime:endTime, liveDataEnabled: !_.isNil(this.props.selectedCustomStartTime) ? false : true, selectedTimeFrame: this.props.selectedTimeFrame.value, selectedCustomDuration: this.props.selectedCustomDuration.value, selectedCustomStartTime: this.props.selectedCustomStartTime }, this.props.editMode, this.props.selectedCard?.type, this.props.contextData, this.props.onCardsQueryRunning, this.props.cardsMetaconfig, null);

        let showResizeHandles = (!_.isNil(this.props.editMode) && this.props.editMode === true);
        addResizeHandle(showResizeHandles, viewConfiguration.cards);

        return (
            <LayoutFixedWidth layout={viewConfiguration.cards} cols={11} rowHeight={160} width={1880} isDraggable={_.isNil(this.props.editMode) ? false : this.props.editMode}
                              compactType={_.isNil(this.props.editMode) ? null : 'vertical'} preventCollision={!!_.isNil(this.props.editMode)}
                              onLayoutChange={(layout) => this.props.onUpdateDashboardLayout(ComponentTypes.PUMP_DASHBOARD, viewConfiguration, layout)}
                              resizeHandle={<CustomResizeHandle />} isEditMode={this.props.editMode}>
                {
                    cardElements.map((card) => {
                        return (
                            <div key={card.key}>{card.element}</div>
                        )
                    })
                }
            </LayoutFixedWidth>
        )
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.PUMP_DASHBOARD : props.stateKey,
            type: ComponentTypes.PUMP_DASHBOARD,
        }
    }
};

CustomView.propTypes = {
    editMode: PropTypes.bool,
    cardsMetaconfig: PropTypes.array,
};

const mapStateToProps = (state, props) => {
    let appState = applicationState(state);

    return {
        dashboards: appState.user.dashboards,
        selectedCard: appState.user.dashboards[ComponentTypes.PUMP_DASHBOARD]?.selectedCard,
        contextData:appState.context.contextData
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onUpdateDashboardLayout: (dashboard, view, newLayout) => { dispatch(appUserConfigActions.updateDashboardLayout(dashboard, view, newLayout)); },
        onCardsQueryRunning: (queryRunning) => { dispatch(pumpDashboardActions.onCardsQueryRunning(props.stateDef, queryRunning)); }
    }
}

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(CustomView));
