import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import { compose } from 'recompose';
import {withProps} from 'recompose';

import {Typography, Box, Paper, CircularProgress, ButtonBase, Grid, Tooltip} from '@mui/material';
import ActionIcon from '@mui/icons-material/MoreHoriz';

import StackedThreeBar from '../../controls/charts/stackedThreeBar/stackedThreeBar';
import * as fracJobOverviewCardActions from '../../../state/cards/fracJobOverviewCard/fracJobOverviewCardActions';
import CriticalAlarmBadge from '../../controls/badges/criticalAlarmBadge';
import InfoAlarmBadge from '../../controls/badges/infoAlarmBadge';
import WarningAlarmBadge from '../../controls/badges/warningAlarmBadge';

import ComponentTypes from "../../componentTypes";
import {fleetTypes} from "../../../state/common/services/fleetTypeService";
import getTypographyStyles from "../../common/styles/typographyStyles";
import Carousel from "react-material-ui-carousel";
import {fracJobOverviewCardState} from "../../../state/cards/fracJobOverviewCard/fracJobOverviewCardSelectors";
import FracJobOverviewCardMenu from "./fracJobOverviewCardMenu";
import { mdtPalette } from "../../common/styles/mdtPalette";
const typographyStyles = getTypographyStyles();

const styles = {
  ...typographyStyles,
  card: {
    width: '277px',
    height: '401px',
    marginRight: '10px',
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  headerTitle: {
    display: 'block',
    width: '255px',
    textAlign: 'center',
    height: '50px'
  },
  headerProgress: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '30px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
    width: '30px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerBorder: {
    height: '3px',
    width: '90%',
    marginLeft: '13px',
    marginBottom: '5px',
    borderBottom: '1px solid #999999'
  },
  headerContent: {
    display: 'flex',
    flexFlow: 'column',
  },

  // Styles for card content
  cardContent: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingBottom: '20px',
    height: '353px' // Helps with alignment across cards
  },

  cardContentCharts: {
    display: 'flex',
    flexFlow: 'column',
  },

  // General styles for all categories
  category: {
    display: 'flex',
    flex: '0 0 40px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '0px',
  },
  categoryLabel: {
    transform: 'rotate(-90deg)',
    whiteSpace: 'nowrap',
  },
  categorySubLabel: {
    whiteSpace: 'nowrap',
    marginBottom: '2px'
  },
  categoryRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    paddingTop: '10px',
    minHeight: '60px',
    alignItems: 'center'
  },
  categoryBorder: {
    height: '10px',
    width: '80%',
    marginLeft: '30px',
    marginTop: '5px',
    borderBottom: '1px solid #999999'
  },
  categorySubsectionBorder: {
    height: '10px',
    width: '100%',
    marginTop: '5px',
    borderBottom: '1px solid #999999'
  },

  // Styles for Component and Health
  chartsContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
  },
  frontSideComponentContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    minHeight: '90px', // Helps with alignment across cards
  },
  stackedThreeBarChartContainer: {
    marginBottom: '0px'
  },
  emptyComponentContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: '127px', // Helps with alignment across cards
    width: '145px', // Helps with alignment across cards
  },
  frontSideNoDataLabelContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '72px' // Helps with alignment across cards
  },
  backSideComponentContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    minHeight: '36px' // Helps with alignment across cards
  },

  // Styles for Operation
  fracMetricsContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '145px',
    paddingTop: '10px',
  },
  statusContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '145px',
    height: '45px',
    alignContent: 'center'
  },
  statusLabel: {
    whiteSpace: 'nowrap',
  },
  statusSubLabel: {
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },
  labelContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center'
  },

  //Styles for Job
  jobCategoryRow: {
    display: 'flex',
    flexFlow: 'row nowrap',
    marginTop: '10px',
    height: '55px',
    alignItems: 'center'
  },
  jobCategory: {
    display: 'flex',
    flex: 'none',
    width: '70px',
    marginLeft:'-15px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '0px',
  },
  carouselContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '150px',
    alignContent: 'center'
  },
  wellDataLabel: {
    whiteSpace: 'nowrap',
    width: '100px',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  wellNoDataLabelContainer: {
    display: 'flex',
    width: '4vw',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90px' // Helps with alignment across cards
  },
  // Styles for Alarms
  alarmsContainer: {
    display: 'flex',
    width: '59px', // Ensures the container is the same size regardless if there are badges or not
    flexFlow: 'column nowrap',
    alignContent: 'space-between',
    alignItems: 'center',
    justifyContent: 'stretch',
    paddingTop: '10px',
    borderLeft: '1px solid #999999'
  },
  alarmsContainerLabel: {
    whiteSpace: 'nowrap',
  },
  alarmBadgeContainer: {
    paddingTop: '10px',
  },
  alarmsNoDataLabelContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: '337px' // Helps with alignment across cards
  },

  cardActionButton: {
    width: '24px',
    height: '24px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'grey.800',
    },
    '& svg': {
      fontSize: '20px',
      color: 'grey.500'
    }
  }
};

function componentHasNoData(component) {
  return (!_.isNil(component) && component.low === 0 && component.med === 0 && component.high === 0);
}

function alarmHasNoData(alarm) {
  return (!_.isNil(alarm) && alarm.criticalAlarmCount === 0 && alarm.warningAlarmCount === 0 && alarm.infoAlarmCount === 0);
}

class FracJobOverviewCard extends Component {

  componentDidMount() {
    if (!_.isNil(this.props.selectedContext)) {
      this.refresh();
    }
  }

  componentDidUpdate(prevProps) {
    // Refresh the card data when the context changes.
    if (prevProps.selectedContext !== this.props.selectedContext) {
      this.refresh();
    }
  }

  refresh() {
    if (!_.isNil(this.props.selectedContext)) {
      this.props.queryData(this.props.selectedContext.id);
    }
  }

  render() {

    let fleet = _.isNil(this.props.selectedContext) ? {} : this.props.selectedContext;
    let status = _.isNil(this.props.fleetFracJobOverview) || _.isNil(this.props.fleetFracJobOverview.state)
      ? '' : this.props.fleetFracJobOverview.state;
    let customerName = _.isNil(this.props.fleetFracJobOverview) || _.isNil(this.props.fleetFracJobOverview.customerName)
        ? '' : this.props.fleetFracJobOverview.customerName;
    let jobName = _.isNil(this.props.fleetFracJobOverview) || _.isNil(this.props.fleetFracJobOverview.jobName)
        ? 'Job' : this.props.fleetFracJobOverview.jobName;
    let fleetType = _.isNil(this.props.fleetFracJobOverview) || _.isNil(this.props.fleetFracJobOverview.fleetType)
      ? '-' : this.props.fleetFracJobOverview.fleetType;
    let flowRate = _.isNil(this.props.fleetFracJobOverview) || _.isNil(this.props.fleetFracJobOverview.flowRate)
      ? '-' : parseFloat(this.props.fleetFracJobOverview.flowRate).toFixed(1);
    let pressure = _.isNil(this.props.fleetFracJobOverview) || _.isNil(this.props.fleetFracJobOverview.pressure)
      ? '-' : parseFloat(this.props.fleetFracJobOverview.pressure).toFixed(0);
    let alarmCounts = _.isNil(this.props.fleetFracJobOverview) || _.isEmpty(this.props.fleetFracJobOverview.alarmCounts)
      ? {} : this.props.fleetFracJobOverview.alarmCounts;
    let frontSide = _.isNil(this.props.fleetFracJobOverview) || _.isNil(this.props.fleetFracJobOverview.frontSideCounts) ? [] :
        this.props.fleetFracJobOverview.frontSideCounts.reduce(function(result, component) {
          if (!componentHasNoData(component)) {
            result.push(component);
          }
          return result;
        }, []);
    let backSide = _.isNil(this.props.fleetFracJobOverview) || _.isNil(this.props.fleetFracJobOverview.backSideCounts) ? [] :
        this.props.fleetFracJobOverview.backSideCounts.reduce(function(result, component) {
          if (!componentHasNoData(component)) {
            result.push(component);
          }
          return result;
        }, []);
    let wells = _.isNil(this.props.fleetFracJobOverview) || _.isNil(this.props.fleetFracJobOverview.wells)
        ? '' : this.props.fleetFracJobOverview.wells;
    let pressureLabel = fleetType === fleetTypes.PUMPONLY ? "" : "@ " + pressure.toLocaleString() + " psi";
    let noData = 'No Data';
    return (
      <Paper sx={styles.card}>
        <Box sx={styles.headerContent}>
          <Box sx={styles.header}>
            <Box sx={styles.headerProgress}>
              {
                this.props.queryRunning === true &&
                <CircularProgress size={20}/>
              }
            </Box>
            <Box sx={styles.headerTitle}>
              <Box><Typography variant={'subtitle1'}>{fleet.name}</Typography></Box>
              <Box><Typography variant={'body2'}>{customerName}</Typography></Box>
            </Box>
            <Box sx={styles.headerActions}>
              <ButtonBase sx={styles.cardActionButton} focusRipple onClick={(event) => this.props.openMenu(event.currentTarget)}><ActionIcon/></ButtonBase>
            </Box>
          </Box>
          <Box sx={styles.headerBorder} />
        </Box>

        <Box sx={styles.cardContent}>
          <Box sx={styles.cardContentCharts}>

            <Box sx={styles.categoryRow}>
              <Box sx={styles.category}>
                <Typography sx={styles.categoryLabel} variant={'caption'}>Operation</Typography>
              </Box>
              <Box>
                <Box sx={styles.statusContainer}>
                  <Box sx={styles.labelContainer}>
                    <Typography sx={styles.statusLabel} variant={'subtitle1'}>{status}</Typography>
                  </Box>
                  <Box sx={styles.labelContainer}>
                    {
                      this.props.queryRunning === false &&
                      <Typography sx={styles.statusSubLabel} variant={'subtitle1'}>{flowRate} bpm {pressureLabel}</Typography>
                    }
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={styles.categoryBorder}/>
            {
              (!_.isEmpty(wells)) &&
              <Box sx={styles.jobCategoryRow}>
                <Box sx={styles.jobCategory}>
                  <Tooltip title={jobName} disableInteractive><Typography sx={{...styles.categoryLabel,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'}} variant={'caption'}>{jobName}</Typography></Tooltip>
                </Box>
                <Box>
                  <Box sx={styles.carouselContainer}>
                    <Carousel fullHeightHover={false} animation={'slide'} interval={5000}
                              autoPlay={false}
                              navButtonsAlwaysInvisible={wells.length === 1}
                              indicators={false}
                              indicatorContainerProps={{style: {marginTop: '50px'}}}
                              navButtonsWrapperProps={{style: {marginTop: '20px', marginRight: '10px'}}}
                    >
                      {
                        wells.map( well =>
                        {
                          return (
                              <Grid container>
                                <Grid item md={3}>
                                  <Typography variant={'body2'}>Pad</Typography>
                                </Grid>
                                <Grid item md={9}>
                                  <Tooltip title={well.padName} disableInteractive><Typography sx={styles.wellDataLabel} variant={'body2'}>{well.padName}</Typography></Tooltip>
                                </Grid>
                                <Grid item md={3}>
                                  <Typography variant={'body2'}>Well</Typography>
                                </Grid>
                                <Grid item md={9}>
                                  <Tooltip title={well.wellName} disableInteractive><Typography sx={styles.wellDataLabel} variant={'body2'}>{well.wellName}</Typography></Tooltip>
                                </Grid>
                                <Grid item md={3}>
                                  <Typography variant={'body2'}>Stage</Typography>
                                </Grid>
                                <Grid item md={9}>
                                  <Typography sx={styles.wellDataLabel} variant={'body2'}>--/{well.plannedStages}</Typography>
                                </Grid>
                              </Grid>
                          )
                        })
                      }
                    </Carousel>
                  </Box>
                </Box>
              </Box>
            }
            {
              (_.isEmpty(wells)) &&
              <Box sx={styles.jobCategoryRow}>
                <Box sx={styles.jobCategory}>
                  <Typography sx={{...styles.categoryLabel,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'}} variant={'caption'}>{jobName}</Typography>
                </Box>
                <Box sx={styles.wellNoDataLabelContainer}>
                  <Typography sx={styles.noDataLabel}
                              variant={'caption'}>{noData}</Typography>
                </Box>
              </Box>
            }
            <Box sx={styles.categoryBorder}/>
            {
              ((!_.isEmpty(frontSide)) || (!_.isEmpty(backSide))) &&
              <Box sx={styles.categoryRow}>
                <Box sx={styles.category}>
                  <Typography sx={styles.categoryLabel}
                              variant={'caption'}>Maintenance</Typography>
                </Box>
                <Box sx={styles.chartsContainer}>
                  {
                    (!_.isEmpty(frontSide)) &&
                    <Box sx={styles.frontSideComponentContainer}>
                      <div>
                        <Typography sx={styles.categorySubLabel}
                                    variant={'caption'}>Pumps</Typography>
                      </div>
                      {
                        frontSide.map((component, index) => {
                          return (
                              <Tooltip key={index} title={"Good/Upcoming/Overdue"} disableInteractive>
                                <Box sx={styles.stackedThreeBarChartContainer}>
                                  <StackedThreeBar label={component.label}
                                                   value1={component.low}
                                                   value2={component.med}
                                                   value3={component.high}
                                                   lowValueColor={mdtPalette().status.level2}
                                  />
                                </Box>
                              </Tooltip>
                          )
                        })
                      }
                    </Box>
                  }
                  {
                    (_.isEmpty(frontSide)) &&
                    <Box sx={styles.frontSideComponentContainer}>
                      <div>
                        <Typography sx={styles.categorySubLabel}
                                    variant={'caption'}>Frontside</Typography>
                      </div>
                      <Box sx={styles.frontSideNoDataLabelContainer}>
                        <Typography sx={styles.noDataLabel}
                                    variant={'caption'}>{noData}</Typography>
                      </Box>
                    </Box>
                  }
                  {
                    (!_.isEmpty(backSide)) &&
                    <Box sx={styles.backSideComponentContainer}>
                      <div>
                        <Typography sx={styles.categorySubLabel}
                                    variant={'caption'}>Backside</Typography>
                      </div>
                      {
                        // There is only 1 backside element at the moment but
                        // we keep the map so we can support more in the future
                        // without having to rework the UI
                        backSide.map((component, index) => {
                          return (
                              <Tooltip key={index} title={"Good/Upcoming/Overdue"} disableInteractive>
                                <Box key={index}
                                     sx={styles.stackedThreeBarChartContainer}>
                                    <StackedThreeBar label={component.label}
                                                     value1={component.low}
                                                     value2={component.med}
                                                     value3={component.high}
                                                     lowValueColor={mdtPalette().status.level2}
                                    />
                                </Box>
                              </Tooltip>
                          )
                        })
                      }
                    </Box>
                  }
                  {
                    (_.isEmpty(backSide)) &&
                    <Box sx={styles.backSideComponentContainer}>
                      <div>
                        <Typography sx={styles.categorySubLabel}
                                    variant={'caption'}>Backside</Typography>
                      </div>
                      <Typography sx={styles.noDataLabel}
                                  variant={'caption'}>{noData}</Typography>
                    </Box>
                  }

                </Box>
              </Box>
            }
            {
              ((_.isEmpty(frontSide)) && (_.isEmpty(backSide))) &&
              <Box sx={styles.categoryRow}>
                <Box sx={styles.category}>
                  <Typography sx={styles.categoryLabel}
                              variant={'caption'}>Maintenance</Typography>
                </Box>
                <Box sx={styles.emptyComponentContainer}>
                  <Typography sx={styles.noDataLabel}
                              variant={'caption'}>{noData}</Typography>
                </Box>
              </Box>
            }
          </Box>
          <Box sx={styles.alarmsContainer}>
            <Typography sx={styles.alarmsContainerLabel} variant={'caption'}>Alarms</Typography>
            {
              (!_.isEmpty(alarmCounts) && !_.isNil(alarmCounts.criticalAlarmCount) && alarmCounts.criticalAlarmCount > 0) &&
              <Box sx={styles.alarmBadgeContainer}>
                <CriticalAlarmBadge count={alarmCounts.criticalAlarmCount} isMedium={true} isLarge={false}/>
              </Box>
            }
            {
              (!_.isEmpty(alarmCounts) && !_.isNil(alarmCounts.warningAlarmCount) && alarmCounts.warningAlarmCount > 0) &&
              <Box sx={styles.alarmBadgeContainer}>
                <WarningAlarmBadge count={alarmCounts.warningAlarmCount} isMedium={true} isLarge={false}/>
              </Box>
            }
            {
              (!_.isEmpty(alarmCounts) && !_.isNil(alarmCounts.infoAlarmCount) && alarmCounts.infoAlarmCount > 0) &&
              <Box sx={styles.alarmBadgeContainer}>
                <InfoAlarmBadge count={alarmCounts.infoAlarmCount} isMedium={true} isLarge={false}/>
              </Box>
            }
            {
              (_.isEmpty(alarmCounts) || alarmHasNoData(alarmCounts)) &&
              <Box sx={styles.alarmsNoDataLabelContainer}>
                <Typography sx={styles.noDataLabel}
                            variant={'caption'}>{noData}</Typography>
              </Box>
            }
          </Box>
        </Box>
        <FracJobOverviewCardMenu stateKey={this.props.stateKey} fleetId={fleet.id} fleetName={fleet.name} fleetType={fleet.type}/>
      </Paper>
    )
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: props.stateKey,
      type: ComponentTypes.FRAC_JOB_OVERVIEW_CARD,
    }
  }
};

const mapStateToProps = (state, props) => {
  const {stateDef} = props;
  let componentState = fracJobOverviewCardState(state[stateDef.key]);
  return {
    queryRunning: componentState.queryRunning,
    fleetFracJobOverview: componentState.fleetFracJobOverview,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    queryData: (context) => { dispatch(fracJobOverviewCardActions.queryFracJobOverviewData(props.stateDef, context)) },
    openMenu: (menuTargetElement) => { dispatch(fracJobOverviewCardActions.openMenu(props.stateDef, menuTargetElement)) },
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(FracJobOverviewCard));