import _ from 'lodash';
import {v4 as uuidv4} from 'uuid';

const appState = state => {

  return {

    // State information for the currently logged in user.
    user: _.isNil(state) ? {
      ownersVisible: _.isNil(state) ? false : state.app.user.ownersVisible,
      loaded: _.isNil(state) ? false : state.app.user.loaded,
      defaultRoute: _.isNil(state) ? null : state.app.user.defaultRoute,
      routes: _.isNil(state) ? {
        groups : _.isNil(state) ? [] : state.app.user.routes.groups,
        routes : _.isNil(state) ? [] : state.app.user.routes.routes,
        pinPages: _.isNil(state) ? [] : state.app.user.routes.pinPages,
      } : state.app.user.routes,
      name: _.isNil(state) ? null : state.app.user.name,
      email: _.isNil(state) ? null : state.app.user.email,
      ownerId: _.isNil(state) ? null : state.app.user.ownerId,
      role: _.isNil(state) ? null : state.app.user.role,
      hasViewDevelopmentContentPermission: _.isNil(state) ? false : state.app.user.hasViewDevelopmentContentPermission,
      hasViewPreReleaseContentPermission: _.isNil(state) ? false : state.app.user.hasViewPreReleaseContentPermission,
      hasFleetManagementPermission: _.isNil(state) ? false : state.app.user.hasFleetManagementPermission,
      showDevelopmentContent: _.isNil(state) ? false : state.app.user.showDevelopmentContent,
      showPreReleaseContent: _.isNil(state) ? false : state.app.user.showPreReleaseContent,
      showWhatsNew: _.isNil(state) ? false : state.app.user.showWhatsNew,
      whatsNewPanelExpanded: _.isNil(state) ? 1 : state.app.user.whatsNewPanelExpanded,
      userId: _.isNil(state) ? null : state.app.user.userId,
      
      availableDisplays: _.isNil(state) ? [] : state.app.user.availableDisplays,
      profileSettings: _.isNil(state) ? 
      {
        lastWhatsNewDate: null,
        landingPage: null,
      } : state.app.user.profileSettings,
      isUserAdmin: _.isNil(state) ? false : state.app.user.isUserAdmin,
      dashboards: _.isNil(state) ? {} : state.app.user.dashboards,
    } : state.app.user,

    // Common collection of owner. Important this will only get populated when the
    // logged in user has the correct permissions.
    owners: _.isNil(state) ? [] : state.app.owners,
    selectedOwner: _.isNil(state) ? null : state.app.selectedOwner,

    // State for the main application navigation (app drawer and user menu)
    navigation: _.isNil(state) ? {
      drawerOpen: _.isNil(state) ? false : state.app.navigation.drawerOpen,
      userMenuOpen: _.isNil(state) ? false : state.app.navigation.userMenuOpen,
      userMenuTargetElement: _.isNil(state) ? null : state.app.navigation.userMenuTargetElement,
      context: _.isNil(state) ? {} : state.app.navigation.context
    } : state.app.navigation,

    // State for the main application error handling (displaying errors in the snack bar)
    errorHandling : _.isNil(state) ? {
      openErrorUI: _.isNil(state) ? false : state.app.errorHandling.openErrorUI,
      errorUIOpening: _.isNil(state) ? false : state.app.errorHandling.errorUIOpening,
      errorUIClosing: _.isNil(state) ? false : state.app.errorHandling.errorUIClosing,
      error: _.isNil(state) ? null : state.app.errorHandling.error,
      displayedError: _.isNil(state) ? null : state.app.errorHandling.displayedError,
    } : state.app.errorHandling,   
    
    notifications: _.isNil(state) ? {
      showSubscriptionDialog: _.isNil(state) ? false : state.app.notifications.showSubscriptionDialog,
      subscriptionDialogContext: _.isNil(state) ? null : state.app.notifications.subscriptionDialogContext,
    } : state.app.notifications,

    context: _.isNil(state) ? 
    {
      contextDrawerOpen: _.isNil(state) ? false : state.app.context.contextDrawerOpen,
      contextType: _.isNil(state) ? null : state.app.context.contextType,
      contextData: _.isNil(state) ? {} : state.app.context.contextData,
      contextDrawerMinimized: _.isNil(state) ? false : state.app.context.contextDrawerMinimized,
      contextDrawerWidth: _.isNil(state) ? 250 : state.app.context.contextDrawerWidth,
    } : state.app.context,

    queryRunning: _.isNil(state) ? false : state.app.queryRunning,

    live: _.isNil(state) ?  
    {
      clientId: getWebSocketClientID(),
      connected: _.isNil(state) ? false : state.app.live.connected,
      data: _.isNil(state) ? {} : state.app.live.data,
      intervalId: _.isNil(state) ? null : state.app.live.intervalId,
      // Any subscriptions created should have a corresponding "unsub" messsage saved in this subscriptions list
      subscriptions: _.isNil(state) ? [] : state.app.live.subscriptions,
    } : state.app.live,
    
    refreshTimestamp: _.isNil(state) ? null : state.app.refreshTimestamp,
    manualRefreshTimestamp: _.isNil(state) ? null : state.app.manualRefreshTimestamp
  };

};

// TODO: this will be retired once we have all dashboards loading configs from the Cloud
const displayConfigurationState = (state, componentType) => {
  let applicationState = appState(state);
  let route = _.find(applicationState.user.routes.routes, ['type', componentType]);
  return _.isNil(route) ? null : route.configuration;
};

const getWebSocketClientID = () => {

  let clientId = uuidv4();
  const localStorageClientId = localStorage.getItem('web_socket_client_id');
  if (!_.isNil(localStorageClientId)) {
    clientId = localStorageClientId;
  } else {
    localStorage.setItem('web_socket_client_id', clientId);
  }
  return clientId;
}

export {
  appState,
  displayConfigurationState
};