import * as fleetMapSimulation from './fleetMapSimulation';
import {performDisplayFetch} from '../../../common/fetch';
import useSimulationData from '../../../../helpers/simulationHelper';

const fetchFleetMapSummary = () => {

  if (useSimulationData('fleetMap')) {
    return fleetMapSimulation.fetchFleetMapSummary();
  }
  

  return performDisplayFetch({
    'query': '{  fleetDashboardFleetSummary { id name type datavanId datavanName datavanPid unitTypes} }'
  })
};

const fetchSaveUserDashboard = (input) => {
  return performDisplayFetch(
    {
      'query': 'mutation SaveUserDashboardConfiguration($input:UserDashboardConfigurationInput!) { saveUserDashboardConfiguration(input: $input) { success } }',
      'variables': {
        'input': input
      }
    });
};

const fetchSaveOwnerDashboard = (input) => {
  return performDisplayFetch(
    {
      'query': 'mutation SaveOwnerDashboardConfiguration($input:OwnerDashboardConfigurationInput!) { saveOwnerDashboardConfiguration(input: $input) { success message } }',
      'variables': {
        'input': input
      }
    });
};

const fetchOwnerDefaultDashboardConfiguration = () => {
  return performDisplayFetch(
    {
      'query': 'query OwnerDefaultDashboardConfiguration { ownerDefaultDashboardConfiguration { name config } }'
    });
};

const fetchFleetDashboardCards = (ownerId) => {
  return fleetMapSimulation.fetchFleetDashboardCards(ownerId);
}

export {  
  fetchFleetMapSummary,
  fetchSaveUserDashboard, 
  fetchSaveOwnerDashboard,
  fetchFleetDashboardCards,
  fetchOwnerDefaultDashboardConfiguration,
}


