import _ from "lodash";
import defaultActionFactory from '../../../common/factories/defaultActionFactory';
import { handleError } from '../../../app/actions/appErrorActions';
import errorMessages from '../../../common/errorMessages';

import jobStageChartActionTypes from './jobStageChartActionTypes';
import * as jobStageChartQueries from './jobStageChartQueries';

const queryDataStarting = defaultActionFactory(jobStageChartActionTypes.JOB_STAGE_CHART_QUERY_STARTING, 'stateDef');
const queryDataSuccess = defaultActionFactory(jobStageChartActionTypes.JOB_STAGE_CHART_QUERY_SUCCESS, 'stateDef', 'queryResults', 'stage');
const queryDataError = defaultActionFactory(jobStageChartActionTypes.JOB_STAGE_CHART_QUERY_ERROR, 'stateDef');

const queryData = (stateDef, stage, startTime, endTime, sensors, cardQueryRunningCallback) => {
  return async (dispatch, getState) => {
    try {

      let queryResults = null;
      await dispatch(queryDataStarting(stateDef));
      // Tell whover is displaying us that we have started running a query
      if (!_.isNil(cardQueryRunningCallback)) {
        cardQueryRunningCallback(true);
      }

      queryResults = await jobStageChartQueries.fetchJobStageChart(stage.stage, startTime, endTime, sensors);

      await dispatch(queryDataSuccess(stateDef, queryResults, stage.stage));
      // Tell whover is displaying us that we are done running a query
      if (!_.isNil(cardQueryRunningCallback)) {
        cardQueryRunningCallback(false);
      }

    } catch (e) {
      await dispatch(queryDataError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_JOB_STAGE_CHART_DATA, e.message));
    }
  }
};

const setPrimaryDefinitionStage = defaultActionFactory(jobStageChartActionTypes.JOB_STAGE_CHART_PRIMARY_DEFINITION_SET_STAGE, 'stateDef', 'stage');
const setPrimaryDefinitionTimeRange = defaultActionFactory(jobStageChartActionTypes.JOB_STAGE_CHART_PRIMARY_DEFINITION_SET_TIME_RANGE, 'stateDef', 'startTime', 'endTime');

const setSecondaryDefinitionStage = defaultActionFactory(jobStageChartActionTypes.JOB_STAGE_CHART_SECONDARY_DEFINITION_SET_STAGE, 'stateDef', 'stage');
const setSecondaryDefinitionTimeRange = defaultActionFactory(jobStageChartActionTypes.JOB_STAGE_CHART_SECONDARY_DEFINITION_SET_TIME_RANGE, 'stateDef', 'startTime', 'endTime');

const clearCompare = defaultActionFactory(jobStageChartActionTypes.JOB_STAGE_CHART_CLEAR_COMPARE, 'stateDef');

const onRollover = defaultActionFactory(jobStageChartActionTypes.JOB_STAGE_CHART_ON_ROLLOVER, 'stateDef', 'xValue', 'yValues');

const toggleSensorVisibility = defaultActionFactory(jobStageChartActionTypes.JOB_STAGE_CHART_SENSOR_VISIBILITY_TOGGLE, 'stateDef', 'xAxis', 'sensorSetId');

export {
  queryData,
  setPrimaryDefinitionStage,
  setPrimaryDefinitionTimeRange,
  setSecondaryDefinitionStage,
  setSecondaryDefinitionTimeRange,
  clearCompare,
  onRollover,
  toggleSensorVisibility
};