import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionCustomFactory from '../../common/factories/queryActionCustomFactory';
import clientStatusActionTypes from './clientStatusActionTypes';
import * as fleetMapActions from '../fleetMap/display/fleetMapActions';
import * as pumpDashboardActions from '../pumpDashboard/pumpDashboardActions';
import * as coilDashboardActions from '../coilDashboard/coilDashboardActions';
import * as navActions from '../../app/actions/appNavActions';

import * as fetchClientQueries from './clientStatusQueries';
import errorMessages from '../../common/errorMessages';
import componentTypes from "../../../components/componentTypes";
import {filterDefinitions} from "./services/clientService";
import * as appUserConfigActions from "../../app/actions/appUserConfigActions";
import * as filterActions from "../../common/filtering/filterActions";
import { parseUserConfigurationForPage } from '../../app/services/userConfigService';

const closeDetailsDialog = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_CLOSE_DETAILS_DIALOG, 'stateDef');
const refreshRelativeTime = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_REFRESH_RELATIVE_TIME, 'stateDef');
const showDataSourceDetails = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_SHOW_DATASOURCE_DETAILS, 'stateDef', 'dataSourceId');
const hideDataSourceDetails = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_HIDE_DATASOURCE_DETAILS, 'stateDef', 'dataSourceId');

const sortDataSourceTrucks = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_SORT_DATASOURCE_TRUCKS, 'stateDef', 'dataSourceId', 'sortContext');
const setDataSourceTruckItemsPerPage = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_SET_DATASOURCE_TRUCKS_ITEMS_PER_PAGE, 'stateDef', 'dataSourceId', 'itemsPerPage');
const setDataSourceTruckCurrentPage = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_SET_DATASOURCE_TRUCKS_CURRENT_PAGE, 'stateDef', 'dataSourceId', 'currentPage');

const queryDataStarting = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_QUERY_DATA_STARTING, 'stateDef');
const queryDataSuccess = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_QUERY_DATA_SUCCESS, 'stateDef', 'queryResults');
const queryDataError = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_QUERY_DATA_ERROR, 'stateDef');

const queryData = queryActionCustomFactory(
  queryDataStarting,
  queryDataSuccess,
  queryDataError,
  errorMessages.ERROR_RETRIEVING_CLIENT_STATUS_DATA,
  fetchClientQueries.fetchClientStatus
);

const queryDetailsStarting = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_QUERY_DETAILS_STARTING, 'stateDef', 'transactionId');
const queryDetailsSuccess = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_QUERY_DETAILS_SUCCESS, 'stateDef', 'transactionId', 'queryResults');
const queryDetailsError = defaultActionFactory(clientStatusActionTypes.CLIENT_STATUS_QUERY_DETAILS_ERROR, 'stateDef', 'transactionId');

/**
 * load client status data for display, applied user configuration/filter for the page
 */
const loadDisplay = (stateDef, userId) => {
  return async (dispatch, getState) => {

    //load if any user display configuration for this page
    const userConfigForPage = await dispatch(appUserConfigActions.queryUserConfigurationForPage(stateDef, userId, componentTypes.CLIENT_STATUS));
    const appliedFilters = parseUserConfigurationForPage(userConfigForPage, filterDefinitions());
    await dispatch(filterActions.onSetAppliedFilters(stateDef, appliedFilters));

    // Execute query to get the fleet data
    await dispatch(queryData(stateDef));

  }
};

const queryDetails = (stateDef, client) => {
  return async (dispatch, getState) => {
    let queryResults = null;
    let transactionId = uuidv4();
    try {
      await dispatch(queryDetailsStarting(stateDef, transactionId));
      queryResults = await fetchClientQueries.fetchClientDetails(client);
    } catch(e) {
      // NOTE: We do not dispatch the common error action as we do not want the default behavior
      return dispatch(queryDetailsError(stateDef, transactionId));
    }
    return dispatch(queryDetailsSuccess(stateDef, transactionId, queryResults));
  }
};

const navigateToFleetDashboardFromDialog = (stateDef, fleetId) => {
  return async (dispatch, getState) => {
    await dispatch(closeDetailsDialog(stateDef));
    return dispatch(fleetMapActions.navigate(fleetId));
  }
};

const navigateToPumpDashboardFromDialog = (stateDef, unitId) => {
  return async (dispatch, getState) => {
    await dispatch(closeDetailsDialog(stateDef));
    return dispatch(pumpDashboardActions.navigate(unitId));
  }
};

const navigateToCoilDashboardFromDialog = (stateDef, unitId) => {
  return async (dispatch, getState) => {
    await dispatch(closeDetailsDialog(stateDef));
    return dispatch(coilDashboardActions.navigate(unitId));
  }
};

const navigate = (fleetId) => {
  return async (dispatch, getState) => {
    const url = '/communication-status';
    const context = _.isNil(fleetId) ? null : { fleetId: fleetId };
    return dispatch(navActions.navigate(url, context));
  }
};

export {
  queryDataStarting,
  queryDataSuccess,
  queryDataError,
  queryData,
  queryDetailsStarting,
  queryDetailsSuccess,
  queryDetailsError,
  queryDetails,
  closeDetailsDialog,
  refreshRelativeTime,
  showDataSourceDetails,
  hideDataSourceDetails,
  sortDataSourceTrucks,
  setDataSourceTruckItemsPerPage,
  setDataSourceTruckCurrentPage,
  navigateToFleetDashboardFromDialog,
  navigateToPumpDashboardFromDialog,
  navigateToCoilDashboardFromDialog,
  navigate,
  loadDisplay
};
