import defaultActionFactory from '../../common/factories/defaultActionFactory';
import errorMessages from '../../common/errorMessages';
import { handleError } from './appErrorActions';

import {
  fetchUserConfigurationForDashboardLayout,
  fetchUserConfigurationForPage,
  saveUserPageConfiguration
} from "../appQueries";
import userConfigActionTypes from "../appActionTypes";
import _ from 'lodash';
import * as appContextActions from "./appContextActions";
import {getCardFromLayoutConfigViews} from "../../../components/common/layout/layoutHelper";

/**
 * common actions to support user pages configuration retrievals/updates
 */

const queryUserConfigurationForPageStarting = defaultActionFactory(userConfigActionTypes.USER_CONFIG_QUERY_PAGE_CONFIG_STARTING, 'stateDef');
const queryUserConfigurationForPageSuccess = defaultActionFactory(userConfigActionTypes.USER_CONFIG_QUERY_PAGE_CONFIG_SUCCESS, 'stateDef', 'queryResults');
const queryUserConfigurationForPageError = defaultActionFactory(userConfigActionTypes.USER_CONFIG_QUERY_PAGE_CONFIG_ERROR, 'stateDef');

const queryUserConfigurationForPage = (stateDef, userId, pageName) => {
  return async (dispatch, getState) => {

    let result = null;

    try {
      await dispatch(queryUserConfigurationForPageStarting(stateDef));

      const queryResult = await fetchUserConfigurationForPage(userId, pageName);
      await dispatch(queryUserConfigurationForPageSuccess(stateDef, queryResult));

      if (!_.isEmpty(queryResult.userConfigurationForPage.value)){
        result = JSON.parse(queryResult.userConfigurationForPage.value);        
      }
      return result;
    } catch (e) {
      await dispatch(queryUserConfigurationForPageError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_USER_CONFIG_FOR_PAGE, e.message));
    }
  }
};

const queryUserConfigurationForDashboardLayoutStarting = defaultActionFactory(userConfigActionTypes.USER_CONFIG_QUERY_DASHBOARD_LAYOUT_CONFIG_STARTING, 'stateDef');
const queryUserConfigurationForDashboardLayoutSuccess = defaultActionFactory(userConfigActionTypes.USER_CONFIG_QUERY_DASHBOARD_LAYOUT_CONFIG_SUCCESS, 'stateDef', 'queryResults');
const queryUserConfigurationForDashboardLayoutError = defaultActionFactory(userConfigActionTypes.USER_CONFIG_QUERY_DASHBOARD_LAYOUT_CONFIG_ERROR, 'stateDef');

const queryUserConfigurationForDashboardLayout = (stateDef, userId) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(queryUserConfigurationForDashboardLayoutStarting(stateDef));

      const queryResult = await fetchUserConfigurationForDashboardLayout(userId);
      await dispatch(queryUserConfigurationForDashboardLayoutSuccess(stateDef, queryResult));
    } catch (e) {
      await dispatch(queryUserConfigurationForDashboardLayoutError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_RETRIEVING_USER_CONFIG_FOR_DASHBOARD_LAYOUT, e.message));
    }
  }
};

const saveUserPageConfigurationStarting = defaultActionFactory(userConfigActionTypes.USER_CONFIG_SAVE_PAGE_CONFIG_STARTING, 'stateDef');
const saveUserPageConfigurationSuccess = defaultActionFactory(userConfigActionTypes.USER_CONFIG_SAVE_PAGE_CONFIG_SUCCESS, 'stateDef', 'queryResults');
const saveUserPageConfigurationError = defaultActionFactory(userConfigActionTypes.USER_CONFIG_SAVE_PAGE_CONFIG_ERROR, 'stateDef');

const saveUserConfigurationForPage = (stateDef, userId, pageName, configValue) => {
  return async (dispatch, getState) => {

    let result = null;

    try {
      await dispatch(saveUserPageConfigurationStarting(stateDef));

      result = await saveUserPageConfiguration(userId, pageName, JSON.stringify(configValue));

      await dispatch(saveUserPageConfigurationSuccess(stateDef, result));

    } catch (e) {
      await dispatch(saveUserPageConfigurationError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_USER_CONFIG_FOR_PAGE, e.message));
    }

  }
};

const updateDashboardLayout = defaultActionFactory(userConfigActionTypes.USER_CONFIG_UPDATE_DASHBOARD_LAYOUT, 'dashboard', 'view', 'newLayout');
const addCardToDashboardLayout = defaultActionFactory(userConfigActionTypes.USER_CONFIG_ADD_CARD_TO_DASHBOARD_LAYOUT, 'dashboard', 'view', 'selectedCard');
const removeCardFromLayout = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_REMOVE_CARD, 'dashboard', 'view', 'cardKey');
const onEditDashboard = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_EDIT, 'isEdit', 'dashboard');
const onManageDashboardCustomViews = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_MANAGE_CUSTOM_VIEW, 'isManage', 'dashboard');
const onSelectedCard = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_EDIT_LAYOUT_SELECT_CARD,'dashboard', 'selectedCard');
const onConfigPanelDiscardChanges = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_CONFIG_PANEL_CHANGES_DISCARD,'dashboard', 'view', 'cardKey');
const onChangeConfig = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG, 'dashboard', 'view', 'cardKey', 'configuration');
const onUpgradeCardAddCanFreeSize = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_UPGRADE_CARD_ADD_CANFREESIZE, 'dashboard', 'view', 'cardKey');
const onManageCustomViewsDiscardChanges = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_DISCARD_CUSTOM_VIEW_CHANGES,'dashboard');

const editCardConfigFromLayout= (dashboard, view, cardKey, cardDefaultTitle, stateKey) => {
  return async(dispatch, getState) => {
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[dashboard]?.views, view, cardKey);
    await dispatch(appContextActions.setContext(card.type, { dashboard: dashboard, view:view, card: card, cardDefaultTitle: cardDefaultTitle, stateKey: stateKey }));
    await dispatch(appContextActions.openContextDrawer(true));
  }
};

const onCloseCardConfiguration= (dashboard, view, cardKey) => {
  return async(dispatch, getState) => {
    const card = getCardFromLayoutConfigViews(getState().app.user.dashboards[dashboard]?.views, view, cardKey);
    await dispatch(onChangeConfig(dashboard, view, cardKey, card.configuration));
    await dispatch(appContextActions.setContext(dashboard, {}));
    await dispatch(appContextActions.openContextDrawer(true, getState()[dashboard].configPanelWidth));
  }
};
const onSaveCustomerViewConfiguration= (dashboard) => {
  return async(dispatch, getState) => {
    await dispatch(appContextActions.openContextDrawer(false));
    await dispatch(onManageDashboardCustomViews(false, dashboard));
  }
};

const onDiscardCardConfiguration= (dashboard, view, cardKey) => {
  return async (dispatch, getState) => {
    await dispatch(onConfigPanelDiscardChanges(dashboard, view, cardKey));
    await dispatch(appContextActions.setContext(dashboard, {}));
    await dispatch(appContextActions.openContextDrawer(true, getState()[dashboard].configPanelWidth));
  }
};

const onDiscardCustomViewConfiguration= (dashboard) => {
  return async (dispatch, getState) => {
    await dispatch(onManageCustomViewsDiscardChanges(dashboard));
    await dispatch(onSaveCustomerViewConfiguration(dashboard));
  }
};

const onChangeCardTitle = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG_CHANGE_TITLE, 'dashboard', 'view', 'cardKey', 'title', 'defaultTitle');
const onSetDefaultCardTitle = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG_SET_DEFAULT_TITLE, 'dashboard', 'view', 'cardKey', 'defaultTitle');
const onChangeCardSize = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_EDIT_CARD_CONFIG_CHANGE_SIZE, 'dashboard', 'view', 'cardKey', 'size');

const onAddCustomView = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_ADD_CUSTOM_VIEW, 'dashboard');
const onRemoveCustomView = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_REMOVE_CUSTOM_VIEW, 'dashboard', 'viewId');
const onToggleFavoriteView = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_TOGGLE_FAVORITE_VIEW, 'dashboard', 'viewId');

const onChangeCustomViewName = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_CHANGE_CUSTOM_VIEW_NAME, 'dashboard', 'viewId', 'name');

const onReorderCustomViews = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_REORDER_CUSTOM_VIEW, 'dashboard', 'fromIndex', 'toIndex');
const onSelecteIcon = defaultActionFactory(userConfigActionTypes.USER_CONFIG_DASHBOARD_LAYOUT_SELECT_CUSTOM_VIEW_ICON, 'dashboard', 'viewId', 'icon');

const onRestoreDefaultView = defaultActionFactory(userConfigActionTypes.USER_CONFIG_RESTORE_DEFAULT_VIEW, 'dashboard', 'view', 'queryResults');

export {
  queryUserConfigurationForPage,
  saveUserConfigurationForPage,
  queryUserConfigurationForDashboardLayout,
  updateDashboardLayout,
  addCardToDashboardLayout,
  removeCardFromLayout,
  onEditDashboard,
  onSelectedCard,
  editCardConfigFromLayout,
  onCloseCardConfiguration,
  onDiscardCardConfiguration,
  onSaveCustomerViewConfiguration,
  onChangeCardTitle,
  onChangeConfig,
  onUpgradeCardAddCanFreeSize,
  onSetDefaultCardTitle,
  onChangeCardSize,
  onConfigPanelDiscardChanges,
  onManageDashboardCustomViews,
  onAddCustomView,
  onToggleFavoriteView,
  onRemoveCustomView,
  onChangeCustomViewName,
  onSelecteIcon,
  onDiscardCustomViewConfiguration,
  onReorderCustomViews,
  onRestoreDefaultView
}