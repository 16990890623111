export default {
  DATA_EXPLORATION_CHART_SET_TEMPLATE: "DATA_EXPLORATION_CHART_SET_TEMPLATE",
  DATA_EXPLORATION_CHART_SET_CONFIG_YAXIS: "DATA_EXPLORATION_CHART_SET_CONFIG_YAXIS",
  DATA_EXPLORATION_CHART_SET_CONFIG_COLOR: "DATA_EXPLORATION_CHART_SET_CONFIG_COLOR",
  DATA_EXPLORATION_CHART_SET_CONFIG_VISIBLE: "DATA_EXPLORATION_CHART_SET_CONFIG_VISIBLE",
  DATA_EXPLORATION_CHART_CALCULATE_STATS: "DATA_EXPLORATION_CHART_CALCULATE_STATS",
  DATA_EXPLORATION_CHART_QUERY_RESET: "DATA_EXPLORATION_CHART_QUERY_DISPLAY_RESET",
  DATA_EXPLORATION_CHART_QUERY_STARTING: "DATA_EXPLORATION_CHART_QUERY_DISPLAY_STARTING",
  DATA_EXPLORATION_CHART_QUERY_SUCCESS: "DATA_EXPLORATION_CHART_QUERY_DISPLAY_SUCCESS",
  DATA_EXPLORATION_CHART_QUERY_ERROR: "DATA_EXPLORATION_CHART_QUERY_DISPLAY_ERROR",
  DATA_EXPLORATION_CHART_OPEN_SENSOR_SELECTOR: "DATA_EXPLORATION_CHART_OPEN_SENSOR_SELECTOR",
  DATA_EXPLORATION_CHART_CLOSE_SENSOR_SELECTOR: "DATA_EXPLORATION_CHART_CLOSE_SENSOR_SELECTOR",
  DATA_EXPLORATION_CHART_SET_SELECTED_SENSORS: "DATA_EXPLORATION_CHART_SET_SELECTED_SENSORS",
  DATA_EXPLORATION_CHART_SET_COLOR_PICKER_STATE: "DATA_EXPLORATION_CHART_SET_COLOR_PICKER_STATE",

  DATA_EXPLORATION_CHART_DOWNLOAD: "DATA_EXPLORATION_CHART_DOWNLOAD",
  DATA_EXPLORATION_CHART_ADJUST_DATA_RANGE: "DATA_EXPLORATION_CHART_ADJUST_DATA_RANGE",
  DATA_EXPLORATION_CHART_QUERY_BY_CHUNKS: "DATA_EXPLORATION_CHART_QUERY_BY_CHUNKS",

  DATA_EXPLORATION_ROTATE: "DATA_EXPLORATION_ROTATE",
  DATA_EXPLORATION_CHART_CLICK_SETTINGS_POPUP: "DATA_EXPLORATION_CHART_CLICK_SETTINGS_POPUP",
  DATA_EXPLORATION_CHART_TOGGLE_CLOSENANGAP: "DATA_EXPLORATION_CHART_TOGGLE_CLOSENANGAP",

  DATA_EXPLORATION_CHART_PROGRESS_STATE: "DATA_EXPLORATION_CHART_PROGRESS_STATE",
  DATA_EXPLORATION_CHART_SCREENSHOT_SETTINGS_POPUP: "DATA_EXPLORATION_CHART_SCREENSHOT_SETTINGS_POPUP",
  DATA_EXPLORATION_CHART_TOGGLE_DELAYED_SCREENSHOT: "DATA_EXPLORATION_CHART_TOGGLE_DELAYED_SCREENSHOT",

  DATA_EXPLORATION_CHART_TOGGLE_YAXIS_ZOOM_LOCK: "DATA_EXPLORATION_CHART_TOGGLE_YAXIS_ZOOM_LOCK",
};
