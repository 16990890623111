import * as fleetOverviewSimulation from './fleetOverviewSimulation';
import {performDisplayFetch} from '../../common/fetch';
import useSimulationData from '../../../helpers/simulationHelper';

const fetchFleetOverview = () => {

  if (useSimulationData('fleetOverview')) {
    return fleetOverviewSimulation.fetchFleetOverview();
  }

  return performDisplayFetch({
    'query': '{  fracOverview { id name region division type} }'
  })
};

export {
  fetchFleetOverview,
}


