export default {
  PUMP_DASHBOARD_QUERY_DATA_STARTING: 'PUMP_DASHBOARD_QUERY_DATA_STARTING',
  PUMP_DASHBOARD_QUERY_DATA_SUCCESS: 'PUMP_DASHBOARD_QUERY_DATA_SUCCESS',
  PUMP_DASHBOARD_QUERY_DATA_ERROR: 'PUMP_DASHBOARD_QUERY_DATA_ERROR',
  PUMP_DASHBOARD_SELECT_CONTEXT: 'PUMP_DASHBOARD_SELECT_CONTEXT',
  PUMP_DASHBOARD_SELECT_NAVIGATION_CONTEXT: 'PUMP_DASHBOARD_SELECT_NAVIGATION_CONTEXT',

  PUMP_DASHBOARD_QUERY_DATA_AGE_STARTING: 'PUMP_DASHBOARD_QUERY_DATA_AGE_STARTING',
  PUMP_DASHBOARD_QUERY_DATA_AGE_SUCCESS: 'PUMP_DASHBOARD_QUERY_DATA_AGE_SUCCESS',
  PUMP_DASHBOARD_QUERY_DATA_AGE_ERROR: 'PUMP_DASHBOARD_QUERY_DATA_AGE_ERROR',
  PUMP_DASHBOARD_DATA_AGE_CLEAR_DATA: 'PUMP_DASHBOARD_DATA_AGE_CLEAR_DATA',
  PUMP_DASHBOARD_DATA_AGE_REFRESH_RELATIVE: 'PUMP_DASHBOARD_DATA_AGE_REFRESH_RELATIVE',

  PUMP_DASHBOARD_SELECT_VIEW: 'PUMP_DASHBOARD_SELECT_VIEW',
  PUMP_DASHBOARD_UPDATE_CUSTOM_VIEW_INPUT: 'PUMP_DASHBOARD_UPDATE_CUSTOM_VIEW_INPUT',
  PUMP_DASHBOARD_SET_TIME_FRAME: 'PUMP_DASHBOARD_SET_TIME_FRAME',
  PUMP_DASHBOARD_SET_CUSTOM_START_TIME: 'PUMP_DASHBOARD_SET_CUSTOM_START_TIME',
  PUMP_DASHBOARD_SET_CUSTOM_DURATION: 'PUMP_DASHBOARD_SET_CUSTOM_DURATION',

  PUMP_DASHBOARD_SELECT_NEXT_PUMP_START: 'PUMP_DASHBOARD_SELECT_NEXT_PUMP_START',
  PUMP_DASHBOARD_SELECT_NEXT_PUMP_END: 'PUMP_DASHBOARD_SELECT_NEXT_PUMP_END',
  PUMP_DASHBOARD_SELECT_PREV_PUMP_START: 'PUMP_DASHBOARD_SELECT_PREV_PUMP_START',
  PUMP_DASHBOARD_SELECT_PREV_PUMP_END: 'PUMP_DASHBOARD_SELECT_PREV_PUMP_END',

  PUMP_DASHBOARD_SET_CUSTOM_START_TIME_DISPLAY: 'PUMP_DASHBOARD_SET_CUSTOM_START_TIME_DISPLAY',

  PUMP_DASHBOARD_SHOW_DIALOG: 'PUMP_DASHBOARD_SHOW_DIALOG',

  PUMP_DASHBOARD_SAVE_DASHBOARD_TO_OWNER_STARTING: 'PUMP_DASHBOARD_SAVE_DASHBOARD_TO_OWNER_STARTING',
  PUMP_DASHBOARD_SAVE_DASHBOARD_TO_OWNER_SUCCESS: 'PUMP_DASHBOARD_SAVE_DASHBOARD_TO_OWNER_SUCCESS',
  PUMP_DASHBOARD_SAVE_DASHBOARD_TO_OWNER_ERROR: 'PUMP_DASHBOARD_SAVE_DASHBOARD_TO_OWNER_ERROR',

  PUMP_DASHBOARD_SAVE_DASHBOARD_TO_USER_STARTING: 'PUMP_DASHBOARD_SAVE_DASHBOARD_TO_USER_STARTING',
  PUMP_DASHBOARD_SAVE_DASHBOARD_TO_USER_SUCCESS: 'PUMP_DASHBOARD_SAVE_DASHBOARD_TO_USER_SUCCESS',
  PUMP_DASHBOARD_SAVE_DASHBOARD_TO_USER_ERROR: 'PUMP_DASHBOARD_SAVE_DASHBOARD_TO_USER_ERROR',

  PUMP_DASHBOARD_LOAD_CARDS_STARTING: 'PUMP_DASHBOARD_LOAD_CARDS_STARTING',
  PUMP_DASHBOARD_LOAD_CARDS_SUCCESS: 'PUMP_DASHBOARD_LOAD_CARDS_SUCCESS',
  PUMP_DASHBOARD_LOAD_CARDS_ERROR: 'PUMP_DASHBOARD_LOAD_CARDS_ERROR',

  PUMP_DASHBOARD_DISCARD_CUSTOM_VIEW_CONFIGURATION: 'PUMP_DASHBOARD_DISCARD_CUSTOM_VIEW_CONFIGURATION',
  PUMP_DASHBOARD_CARDS_QUERY_RUNNING: 'PUMP_DASHBOARD_CARDS_QUERY_RUNNING',

  PUMP_DASHBOARD_RESTORE_DEFAULTS_STARTING: 'PUMP_DASHBOARD_RESTORE_DEFAULTS_STARTING',
  PUMP_DASHBOARD_RESTORE_DEFAULTS_SUCCESS: 'PUMP_DASHBOARD_RESTORE_DEFAULTS_SUCCESS',
  PUMP_DASHBOARD_RESTORE_DEFAULTS_ERROR: 'PUMP_DASHBOARD_RESTORE_DEFAULTS_ERROR',
};
