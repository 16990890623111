import _ from 'lodash';
import {fleetTypes} from "../../../../common/services/fleetTypeService";

const processFleets = (fleets) => {

  let processedFleets = [];

  if (!_.isEmpty(fleets)) {
    // Filter out any fleet type not pumper only or frac fleet and sort fleets by name
    processedFleets = _.orderBy(fleets.filter(fleet => fleet.type === fleetTypes.FRAC || fleet.type === fleetTypes.PUMPONLY), 'name').map(fleet => {
    if (!_.isNil(fleet.unitTypes)) {
      fleet.unitTypes.sort();
    }
      return {
        ...fleet,
        value: fleet.name,
        label: fleet.name,
      }
    });
  }

  return processedFleets;
};

export {
  processFleets
}