import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withProps } from 'recompose';

import { Typography, Box, Card, Tooltip } from '@mui/material';

import ComponentTypes from '../../componentTypes';

import * as dataGridActions from '../../../state/cards/dataGrid/dataGridActions';
import * as appUserConfigActions from '../../../state/app/actions/appUserConfigActions';
import { dataGridState } from "../../../state/cards/dataGrid/dataGridSelectors";
import { appState as applicationState } from '../../../state/app/appSelectors';

import getCardStyles from '../cardStyles';
import getLayoutStyles from '../../controls/layout/layoutStyles';
import getTypographyStyles from '../../common/styles/typographyStyles';
import { MDTCard, mdtCardMapDispatchToProps, mdtCardPropTypes, mdtCardMapStateToProps } from "../mdtCard/mdtCard";
import { mdtPalette } from '../../common/styles/mdtPalette';
import { sanitizeDefinition } from "../../../state/cards/dataGrid/services/dataGridService";

const cardStyles = getCardStyles();
const layoutStyles = getLayoutStyles();
const typographyStyles = getTypographyStyles();

const getFormattingColor = (latestValue, conditionalFormattingRules) => {
  let formattingColor = null;
  let normalizedLatestValue = latestValue?.replace(new RegExp(',', 'g'), '');  //remove the unprocessable char from localized number string
  conditionalFormattingRules.forEach((conditionalFormattingRule) => {
    let { color, condition, value1, value2 } = conditionalFormattingRule;
    value1 = _.isEmpty(value1?.toString()) ? 0 : value1;
    value2 = _.isEmpty(value2?.toString()) ? 0 : value2;

    if (normalizedLatestValue !== "-") {
      normalizedLatestValue = parseFloat(normalizedLatestValue);
      switch (condition) {
        case "greater than":
          formattingColor = normalizedLatestValue > value1 ? color : formattingColor;
          break;
        case "less than":
          formattingColor = normalizedLatestValue < value1 ? color : formattingColor;
          break;
        case "between":
          //lodash inRange is checking the boundaries as lowerValue <= latestValue < higherValue
          formattingColor = _.inRange(normalizedLatestValue, value1, value2) ? color : formattingColor;
          break;
        case "not between":
          formattingColor = !_.inRange(normalizedLatestValue, value1, value2) ? color : formattingColor;
          break;
      }
    }
  });
  return formattingColor;
};

const styles = (sensor, sensorsData) => {
  let formattedColor = null;
  if (!_.isNil(sensor) && !_.isNil(sensorsData) && sensor.conditionalFormatting.applied === true) {
    formattedColor = getFormattingColor(sensorsData[sensor.sensorSetId], sensor.conditionalFormatting.rules);
  };

  const borderAndTextColorRules = sensor?.borderAndTextColorRules;
  const applyTextColorRules = borderAndTextColorRules?.applyRules;

  const showFormatting = !_.isNil(formattedColor);
  return {
    ...cardStyles,
    ...layoutStyles,
    ...typographyStyles,

    cardContent: {
      display: 'flex',
      height: '100%',
      overflow: 'hidden'
    },
    noSensorsContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%'
    },
    noSensorsLabel: {
      ...typographyStyles.noDataLabel
    },
    dataGridContent: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      gap: 1,
      height: 'calc(100%-16px)',
      m: 1,
      overflow: 'auto',
      alignContent: 'flex-start',
      width: '100%'
    },
    sensorBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      border: '1px solid ' + (applyTextColorRules ? borderAndTextColorRules.color : mdtPalette().typography.color),
      backgroundColor: showFormatting ? formattedColor : null,
    },
    latestValue: {
      color: applyTextColorRules ? borderAndTextColorRules.color : mdtPalette().typography.color,
    },
    sensorName: {
      color: applyTextColorRules ? borderAndTextColorRules.color : mdtPalette().typography.color,
      width: '90%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      wordBreak: 'break-word',
      lineHeight: '1.2'
    },
    listViewStyles: {
      flexDirection: 'column',
      width: "100%",
      marginLeft: 1,
      marginRight: 1,
      marginTop: 2,
      overflow: "auto"
    },
    listViewItemStyles: {
      display: 'flex',
      flexDirection: 'row',
      color: applyTextColorRules ? borderAndTextColorRules.color : mdtPalette().typography.color,
      backgroundColor: showFormatting ? formattedColor : null,
      border: '1px solid ' + (applyTextColorRules ? borderAndTextColorRules.color : mdtPalette().typography.color),
      marginBottom: "8px",
      padding: "12px 4px",
      alignItems: "center",
      minWidth: "320px"
    },
    listViewDataContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: 1,
      alignItems: "center"
    },
    ellipsisText: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }

  };
};

class DataGrid extends MDTCard {

  getName() {
    return 'DATA GRID';
  };

  isContextReady() {
    return !_.isNil(this.props.context) && !_.isNil(this.props.context.truck);
  };

  refresh() {
    if (!this.isContextReady()) {
      this.props.clearData();
    } else {
      this.props.queryData(this.props.context.truck, this.props.card.configuration.sensors, this.props.dashboard);
    };

    if (!this.isInEditMode()) {
      // If edit mode is exited, the card should reflect the most recently saved configuration
      this.props.setDataGridDefinitionSensors(_.isNil(this.props.card.configuration.sensors) ? [] : this.props.card.configuration.sensors);
    }
  }

  componentDidMount() {
    // Initialize the truck, time range and sensors
    if (!_.isNil(this.props.context.truck)) {
      this.props.setDataGridDefinitionDefaultTruck(this.props.context.truck, this.props.dashboard);
    };
    if (!_.isNil(this.props.context.startTime) && !_.isNil(this.props.context.endTime)) {
      const duration = (this.props.context.endTime - this.props.context.startTime) / 60;
      this.props.setDataGridDefinitionStartTime(this.props.context.startTime, duration);
    };
    if (!_.isNil(this.props.card.configuration.sensors)) {
      this.props.setDataGridDefinitionSensors(this.props.card.configuration.sensors);
    } else {
      //Set the card sensors default value if not existing
      this.props.initializeDefinition(this.props.dashboard, this.props.view, this.props.cardKey, sanitizeDefinition(this.props.definition));
    };

    if (!_.isNil(this.props.card.configuration.dataView)) {
      this.props.setCardView(this.props.card.configuration.dataView);
    }

    // Clear latest values if we are in edit mode, so there is no confusion between configured vs displayed values
    if (this.isInEditMode()) {
      this.props.onSetupEditMode(this.props.dashboard, this.props.view, this.props.cardKey);
    };

    //Create live data update subscription
    if (!_.isNil(this.props.context.truck)) {
      this.props.createSubscription(this.props.context.truck, this.props.card.configuration.sensors, this.props.dashboard);
    }
    super.componentDidMount();
  };

  componentDidUpdate(prevProps) {
    // Truck context may not loaded up yet in componentDidMount. 
    // Load the definition here instead
    if (!_.isNil(this.props.context.truck) && _.isNil(prevProps.context.truck)) {
      this.props.setDataGridDefinitionDefaultTruck(this.props.context.truck, this.props.dashboard);
      this.props.createSubscription(this.props.context.truck, this.props.card.configuration.sensors, this.props.dashboard);
    }
    // If Truck has changed, change the default truck on the definition
    if (!_.isNil(prevProps.context.truck) && !_.isEqual(prevProps.context.truck, this.props.context.truck)) {     
      this.props.setDataGridDefinitionDefaultTruck(this.props.context.truck, this.props.dashboard);
      //Close the previous subscription and create live data update subscription
      this.props.closeSubscription(prevProps.context.truck, this.props.dashboard);
      this.props.createSubscription(this.props.context.truck, this.props.card.configuration.sensors, this.props.dashboard);
    };
    // If Start Time or duration have changed, change the start time and duration on the definition
    if ((!_.isEqual(prevProps.context.startTime, this.props.context.startTime)) || (!_.isEqual(prevProps.context.endTime, this.props.context.endTime))) {
      const duration = (this.props.context.endTime - this.props.context.startTime) / 60;
      this.props.setDataGridDefinitionStartTime(this.props.context.startTime, duration);
    };
    // If sensors change in configuration, then update the definition
    // This helps when discarding changes and we restore the configuration object before the definition, since the comparison is done at the configuration level,
    // which exists only with the layout object
    if (!_.isNil(this.props.card.configuration.sensors) && (!_.isEqual(prevProps.card.configuration.sensors, this.props.card.configuration.sensors))) {
      this.props.setDataGridDefinitionSensors(this.props.definition.primary.sensors);
      if (!_.isNil(this.props.context.truck)) {
        this.props.createSubscription(this.props.context.truck, this.props.card.configuration.sensors, this.props.dashboard);
      }
    };
    
    if(!_.isNil(this.props.card.configuration.dataView) && (!_.isEqual(prevProps.card.configuration.dataView, this.props.card.configuration.dataView))) {
      this.props.setCardView(this.props.card.configuration.dataView);
    }
    // Clear latest values if we are in edit mode, so there is no confusion between configured vs displayed values
    if (this.isInEditMode() && (_.isNil(prevProps.editMode) || !prevProps.editMode)) {
      this.props.onSetupEditMode(this.props.dashboard, this.props.view, this.props.cardKey);
    };

    super.componentDidUpdate(prevProps);
  };

  componentWillUnmount() {
    if (!_.isNil(this.props.context.truck)) {
        this.props.closeSubscription(this.props.context.truck, this.props.dashboard);
      }
  }

  getRenderedContent() {
    const getSize = () => {
      const { definition, card } = this.props;
      // Width of parent is less than 100% since there are 8px gaps between boxes
      // This total width is split into equal portions depending on the dimensions of the card
      if (definition.primary.dataView === "listView") {
        return {
          width: "100%",
          height: `calc((100% - ${(card.h * 3.5 - 1) * 8}px)/${card.h * 3.5})`
        };
      }

      return {
        width: `calc((100% - ${(card.w - 1) * 8}px)/${card.w})`,
        height: `calc((100% - ${(card.h - 1) * 8}px)/${card.h})`
      };

    };

    const getContentsByDataView = () => {
      const dataView = this.props.definition.primary.dataView;

      switch (dataView) {
        case this.props.dataViewOptions.gridView:
          return (
            <Box sx={styles(null).dataGridContent}>
              {_.map(this.props.definition.primary.sensors, sensor => {
                return (
                  <Card sx={{ ...styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).sensorBox, ...getSize() }} key={sensor.alias}>
                    <Typography sx={styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).latestValue} variant={this.props.card.h === 1 ? 'h5' : 'h4'}>
                      {_.isNil(this.props.sensorsData[sensor.sensorSetId]) ? '-' : this.props.sensorsData[sensor.sensorSetId]}
                    </Typography>
                    <Tooltip title={`${sensor.displayName} (${sensor.uom})`} disableInteractive>
                      <Typography sx={styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).sensorName} variant={'subtitle1'}>
                        {`${sensor.displayName}`}
                      </Typography>
                    </Tooltip>
                    <Typography sx={styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).sensorName} variant={'subtitle1'}>
                      {` (${sensor.uom})`}
                    </Typography>
                  </Card>
                )
              })}
            </Box>
          )
        case this.props.dataViewOptions.listView:
          return (
            <Box sx={{ ...styles(null).listViewStyles }}>
              {_.map(this.props.definition.primary.sensors, sensor => {
                return (
                  <Card sx={{ ...styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).listViewItemStyles, ...getSize(), minWidth: '314px' }} key={sensor.alias}>
                    <Box sx={{ flex: "0 0 50%", ...styles().ellipsisText }}>
                      <Tooltip title={`${sensor.displayName} (${sensor.uom})`} disableInteractive>
                        <Typography sx={{ ...styles().ellipsisText }} variant={'button'} fontWeight={400}>
                          {`${sensor.displayName}`}
                        </Typography>
                      </Tooltip>
                    </Box>
                    <Box sx={{ ...styles().listViewDataContainer }}>
                      <Typography sx={{ textAlign: 'right', flex: "0 0 60%" }} variant={'button'} fontWeight={400}>
                        {_.isNil(this.props.sensorsData[sensor.sensorSetId]) ? '-' : this.props.sensorsData[sensor.sensorSetId]}
                      </Typography>
                      <Typography sx={{ textAlign: 'right', flex: 1, ...styles().ellipsisText }} variant={'button'} fontWeight={400} fontSize={'0.775rem'}>
                        {`${sensor.uom}`}
                      </Typography>
                    </Box>

                  </Card>
                )
              })}
            </Box>
          )
        default:
          return (
            <Box sx={styles(null).dataGridContent}>
              {_.map(this.props.definition.primary.sensors, sensor => {
                return (
                  <Card sx={{ ...styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).sensorBox, ...getSize() }} key={sensor.alias}>
                    <Typography sx={styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).latestValue} variant={this.props.card.h === 1 ? 'h5' : 'h4'}>
                      {_.isNil(this.props.sensorsData[sensor.sensorSetId]) ? '-' : this.props.sensorsData[sensor.sensorSetId]}
                    </Typography>
                    <Tooltip title={`${sensor.displayName} (${sensor.uom})`} disableInteractive>
                      <Typography sx={styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).sensorName} variant={'subtitle1'}>
                        {`${sensor.displayName}`}
                      </Typography>
                    </Tooltip>
                    <Typography sx={styles(this.isInEditMode() ? null : sensor, this.props.sensorsData).sensorName} variant={'subtitle1'}>
                      {` (${sensor.uom})`}
                    </Typography>
                  </Card>
                )
              })}
            </Box>
          )
      }
    }

    return (
      <Box key='body' sx={styles(null).cardContent} >
        {
          _.isEmpty(this.props.definition.primary.sensors) ? (
            <Box sx={styles(null).noSensorsContainer}>
              <Typography variant={'caption'} sx={styles(null).noSensorsLabel}>No Sensors Selected</Typography>
            </Box>
          ) : (
            getContentsByDataView()
          )
        }

      </Box>
    )
  }
}

DataGrid.propTypes = mdtCardPropTypes;

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.DATA_GRID : props.stateKey,
      type: ComponentTypes.DATA_GRID,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = dataGridState(state[stateDef.key]);
  let appState = applicationState(state);

  return {
    ...mdtCardMapStateToProps(state, props),
    sensorsData: componentState.latestValues,
    definition: componentState.definition,
    dashboards: appState.user.dashboards,
    dataViewOptions: componentState.dataViewOptions,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...mdtCardMapDispatchToProps(dispatch, props),
    queryData: (truck, sensors, dashboard) => { dispatch(dataGridActions.queryData(props.stateDef, truck, sensors, dashboard)); },
    clearData: () => { dispatch(dataGridActions.clearData(props.stateDef)); },
    setDataGridDefinitionDefaultTruck: (truck, dashboard) => { dispatch(dataGridActions.setDefinitionDefaultTruck(props.stateDef, truck, dashboard)); },
    setDataGridDefinitionStartTime: (startTime, duration) => { dispatch(dataGridActions.setDefinitionStartTime(props.stateDef, startTime, duration)); },
    setDataGridDefinitionSensors: (sensors) => { dispatch(dataGridActions.setDefinitionSensors(props.stateDef, sensors)); },
    setCardView: (dataView) => { dispatch(dataGridActions.setCardView(props.stateDef, dataView)); },
    initializeDefinition: (dashboard, view, cardKey, definition) => { dispatch(appUserConfigActions.onChangeConfig(dashboard, view, cardKey, definition)); },
    createSubscription: (truck, sensors, dashboard) => { dispatch(dataGridActions.createSubscription(props.stateDef, truck, sensors, dashboard)); },
    closeSubscription: (truck, dashboard) => { dispatch(dataGridActions.closeSubscription(props.stateDef, truck, dashboard)); },
    onSetupEditMode: (dashboard, view, cardKey) => {dispatch(dataGridActions.onSetupEditMode(props.stateDef, dashboard, view, cardKey))},
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(DataGrid));