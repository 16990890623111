export default {
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_STARTING: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_STARTING',
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_SUCCESS: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_SUCCESS',
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_ERROR: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_ERROR',
  DATA_EXPLORATION_DISPLAY_SELECT_CONTEXT: 'DATA_EXPLORATION_DISPLAY_SELECT_CONTEXT',

  DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_STARTING: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_STARTING',
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_SUCCESS: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_SUCCESS',
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_ERROR: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_AGE_ERROR',
  DATA_EXPLORATION_DISPLAY_DATA_AGE_CLEAR_DATA: 'DATA_EXPLORATION_DISPLAY_DATA_AGE_CLEAR_DATA',
  DATA_EXPLORATION_DISPLAY_DATA_AGE_REFRESH_RELATIVE: 'DATA_EXPLORATION_DISPLAY_DATA_AGE_REFRESH_RELATIVE',

  DATA_EXPLORATION_DISPLAY_SET_TIME_FRAME: 'DATA_EXPLORATION_DISPLAY_SET_TIME_FRAME',
  DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME: 'DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME',
  DATA_EXPLORATION_DISPLAY_SET_CUSTOM_DURATION: 'DATA_EXPLORATION_DISPLAY_SET_CUSTOM_DURATION',

  DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_START: 'DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_START',
  DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_END: 'DATA_EXPLORATION_DISPLAY_SELECT_NEXT_TRUCK_END',
  DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_START: 'DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_START',
  DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_END: 'DATA_EXPLORATION_DISPLAY_SELECT_PREV_TRUCK_END',

  DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME_DISPLAY: 'DATA_EXPLORATION_DISPLAY_SET_CUSTOM_START_TIME_DISPLAY',
  DATA_EXPLORATION_DISPLAY_SHOW_SENSOR_SELECTOR: 'DATA_EXPLORATION_DISPLAY_SHOW_SENSOR_SELECTOR',
  DATA_EXPLORATION_DISPLAY_SET_SELECTED_SENSORS: 'DATA_EXPLORATION_DISPLAY_SET_SELECTED_SENSORS',
  DATA_EXPLORATION_DISPLAY_QUERY_TRUCKS_STARTING: 'DATA_EXPLORATION_DISPLAY_QUERY_TRUCKS_STARTING',
  DATA_EXPLORATION_DISPLAY_QUERY_TRUCKS_SUCCESS: 'DATA_EXPLORATION_DISPLAY_QUERY_TRUCKS_SUCCESS',
  DATA_EXPLORATION_DISPLAY_QUERY_TRUCKS_ERROR: 'DATA_EXPLORATION_DISPLAY_QUERY_TRUCKS_ERROR',
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_EXPLORATION_DISPLAY_BY_TRUCK_INFO_STARTING: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_EXPLORATION_DISPLAY_BY_TRUCK_INFO_STARTING',
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_EXPLORATION_DISPLAY_BY_TRUCK_INFO_SUCCESS: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_EXPLORATION_DISPLAY_BY_TRUCK_INFO_SUCCESS',
  DATA_EXPLORATION_DISPLAY_QUERY_DATA_EXPLORATION_DISPLAY_BY_TRUCK_INFO_ERROR: 'DATA_EXPLORATION_DISPLAY_QUERY_DATA_EXPLORATION_DISPLAY_BY_TRUCK_INFO_ERROR',
  DATA_EXPLORATION_DISPLAY_QUERY_TRUCK_DETAILS_STARTING: 'DATA_EXPLORATION_DISPLAY_QUERY_TRUCK_DETAILS_STARTING',
  DATA_EXPLORATION_DISPLAY_QUERY_TRUCK_DETAILS_SUCCESS: 'DATA_EXPLORATION_DISPLAY_QUERY_TRUCK_DETAILS_SUCCESS',
  DATA_EXPLORATION_DISPLAY_QUERY_TRUCK_DETAILS_ERROR: 'DATA_EXPLORATION_DISPLAY_QUERY_TRUCK_DETAILS_ERROR',
  DATA_EXPLORATION_DISPLAY_SELECT_TRUCK: 'DATA_EXPLORATION_DISPLAY_SELECT_TRUCK',
  DATA_EXPLORATION_DISPLAY_SELECT_DURATION: 'DATA_EXPLORATION_DISPLAY_SELECT_DURATION',
  DATA_EXPLORATION_DISPLAY_SELECT_TIMEZONE: 'DATA_EXPLORATION_DISPLAY_SELECT_TIMEZONE',
  DATA_EXPLORATION_DISPLAY_INIT_TIMEZONES: 'DATA_EXPLORATION_DISPLAY_INIT_TIMEZONES',
  DATA_EXPLORATION_DISPLAY_FIND_NEXT_RANGE: 'DATA_EXPLORATION_DISPLAY_FIND_NEXT_RANGE',
  DATA_EXPLORATION_DISPLAY_FIND_PREVIOUS_RANGE: 'DATA_EXPLORATION_DISPLAY_FIND_PREVIOUS_RANGE',
  DATA_EXPLORATION_DISPLAY_SET_CHART_START_TIME: 'DATA_EXPLORATION_DISPLAY_SET_CHART_START_TIME',
  DATA_EXPLORATION_DISPLAY_UPDATE_CHART_AXIS: 'DATA_EXPLORATION_DISPLAY_UPDATE_CHART_AXIS',
  DATA_EXPLORATION_DISPLAY_SET_IS_QUERY_DATA_AGE: 'DATA_EXPLORATION_DISPLAY_SET_IS_QUERY_DATA_AGE'
};
