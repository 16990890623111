import ComponentTypes from '../../../components/componentTypes';
import { assetTypes } from './assetTypeService';

/**
* Given the type of dashboard we are on, determine the truck payload to use
*/
const determineTruckFromDashboard = (truck, dashboard) => {
  switch (dashboard) {
    case ComponentTypes.PUMP_DASHBOARD:
      return truck;
    case ComponentTypes.FLEET_DASHBOARD:
      return {
        id: truck.datavanId,
        truckPid: truck.datavanPid,
        name: truck.datavanName,
        unitType: assetTypes.DATAVAN
      };
    }
}

export {
  determineTruckFromDashboard
}