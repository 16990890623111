import React, {Component} from 'react';
import moment from "moment";
import {connect} from "react-redux";
import { Typography, Box, List, ListItem, ListItemText, ListItemIcon, Accordion, AccordionSummary, AccordionDetails, Button, 
  DialogTitle, DialogActions, Dialog, DialogContent } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { appState } from '../../state/app/appSelectors';
import * as userActions from '../../state/app/actions/appUserActions';
import {getGroupings, getWhatsNewRecords} from "./updateHistory";
import _ from 'lodash';

const styles = {
  dialogTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  sectionHeaderLabel: {
    width: '500px',
    borderStyle: 'solid',
    borderWidth: '0px 0px 1px 0px', // Top, Right, Bottom, Left
    borderBottomColor: 'primary.main',
  },
  sectionBody: {
    width: '500px',
    marginTop: 1,
    marginBottom: 2,
  },
  sectionItemDescription: {
    marginLeft: '56px',
    marginTop: '-8px',
    display: 'flex',
    whiteSpace: 'pre',
    textWrap: 'wrap'
  },
  sectionIssueItemDescription: {
    marginLeft: '40px',
    marginTop: '-8px',
    display: 'flex',
    whiteSpace: 'pre',
    textWrap: 'wrap'
  },
  sectionItemWorkAroundContainer: {
    marginTop: '-16px',
    marginLeft: '-16px',
  },
  sectionItemWorkAroundText: {
    marginLeft: '64px',
    fontStyle: 'italic',
    display: 'flex',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 2,
  },
  dateLabel: {
    fontStyle: 'italic',
    fontSize: '14px'
  },
  accordionSummaryContainer: {
    backgroundColor: 'grey.800'
  },
  accordionDetailsContainer: {
    display: 'flex',
    flexFlow: 'column nowrap'
  }
};

class WhatsNewDialog extends Component {

  render() {

    const handleChange = (panel) => (event, newExpanded) => {
      this.props.onPanelExpanded(newExpanded ? panel : null);
    };  

    const updateContents = (whatsNewRecords, groupings) => {

      const pastGroups = _.filter(groupings, (group) => group < moment().year());

      return (
        <div>
          {whatsNewRecords?.filter(record => moment(record.date, 'MMMM D YYYY HH:mm:ss').year() === moment().year()).map((record, index) => (
            whatsNewContents(itemId+'', index, assignPanelId(), assignAriaControls(), record)
          ))}
          {
            !_.isEmpty(pastGroups) &&
            <div style={{marginTop: '16px'}}>
              {
                pastGroups.map((group, index) => (
                  <Accordion key={index+'_'+group} expanded={(this.props.panelExpanded+'').includes('_'+group)} onChange={handleChange(assignPanelId()+'-group'+'_'+group)}>
                    <AccordionSummary sx={styles.accordionSummaryContainer} expandIcon={<ExpandMoreIcon />} id={assignPanelId()+'-group'+'_'+group} aria-controls={assignAriaControls()+'-group'+'_'+group} >
                      <Typography variant={'subtitle1'}>{group}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={styles.accordionDetailsContainer}>
                      {whatsNewRecords?.filter(record => moment(record.date, 'MMMM D YYYY HH:mm:ss').year() === group).map((record, index) => (
                        whatsNewContents(itemId+'_'+group, index, assignPanelId()+'_'+group, assignAriaControls()+'_'+group, record)
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))
              }
            </div>
          }
        </div>
      );
    }

    const whatsNewContents = (itemId, index, id, ariaControlsId, item) => {
      return (
        <Accordion key={index} expanded={this.props.panelExpanded+'' === itemId} onChange={handleChange(itemId)}>
          <AccordionSummary sx={styles.accordionSummaryContainer} expandIcon={<ExpandMoreIcon />} id={id} aria-controls={ariaControlsId} >
            <Typography variant={'subtitle1'}>{item.date}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={styles.accordionDetailsContainer}>
            <Typography sx={styles.sectionHeaderLabel} variant={'subtitle1'}>New Features</Typography>
            {newFeaturesContents(item.updates)}

            <Typography sx={styles.sectionHeaderLabel} variant={'subtitle1'}>Known Issues</Typography>
            {knownIssueContents(item.issues)}

          </AccordionDetails>
        </Accordion>
      )
    }

    const newFeaturesContents = (updates) => (
      <Box sx={styles.sectionBody}>
        {updates?.map((update, index) => (
          <List key={index}>
            <ListItem>
              <ListItemIcon>
                <ChevronRightIcon color={'primary'}/>
              </ListItemIcon>
              <ListItemText>
                <Typography variant={'body2'}>{update.title}</Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography sx={styles.sectionItemDescription} variant={'caption'} component={'p'}>
                  {update.desc}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        ))}
      </Box>
    );


    const knownIssueContents = (issues) => (
        <Box sx={styles.sectionBody}>
          {issues?.map((issue, index) => (
            <List key={index}>
              <ListItem>
                <ListItemIcon>
                  <ChevronRightIcon color={'primary'}/>
                </ListItemIcon>
                <ListItemText>
                  <Typography variant={'body2'}>{issue.title}</Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                {knownIssueList(issue.issueList)}
              </ListItem>
            </List>
          ))}
        </Box>
    );

    const knownIssueList = (issueList) => (
      <div>
        {issueList?.map((issue, index) => (
          <List key={index}>
            <ListItem>
              <ListItemText>
                <Typography sx={styles.sectionIssueItemDescription} variant={'caption'} component={'p'}>
                  {issue.issue}
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem sx={styles.sectionItemWorkAroundContainer}>
              <ListItemText>
                <Typography sx={styles.sectionItemWorkAroundText} variant={'caption'} component={'p'}>
                  {issue.suggestion}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        ))}
      </div>
    );

    let itemId = 1;
    function assignPanelId(){
       return itemId + '-header';
    }

    function assignAriaControls(){
      const ariaControls = itemId + '-content';
      itemId = itemId + 1; //for next accordion element
      return ariaControls;
    }
    const whatsNewHistoryContents = getWhatsNewRecords();
    const groupings = getGroupings();

    return (
      <Dialog open={this.props.showWhatsNew} maxWidth={'lg'} disableEscapeKeyDown>
        <DialogTitle>
          <Box sx={styles.dialogTitleContainer}>
            <Typography variant={'subtitle1'}>What's New</Typography>
            <Typography sx={styles.dateLabel} variant={'subtitle1'}>as of {whatsNewHistoryContents[0].date}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {updateContents(whatsNewHistoryContents, groupings)}
        </DialogContent>

        <DialogActions sx={styles.buttonsContainer}>
          <Button variant={'contained'} color={'primary'} onClick={() => { this.props.onShowWhatsNew(false)}}>OK</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

const mapStateToProps = (state) => {
  let componentState = appState(state);

  return {
    showWhatsNew: componentState.user.showWhatsNew,
    panelExpanded: componentState.user.whatsNewPanelExpanded
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowWhatsNew: (show) => dispatch(userActions.onShowWhatsNew(show)),
    onPanelExpanded: (panel) => dispatch(userActions.whatsNewPanelExpanded(panel)),
  }
};

export default (connect(mapStateToProps,mapDispatchToProps)(WhatsNewDialog));