import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from '../../common/factories/queryActionFactory';

import fleetOverviewActionTypes from './fleetOverviewActionTypes';
import * as fleetOverviewQueries from './fleetOverviewQueries';
import ComponentTypes from '../../../components/componentTypes';
import * as rootActions from '../../common/rootActions';

import errorMessages from '../../common/errorMessages';
import * as appUserConfigActions from "../../app/actions/appUserConfigActions";
import * as filterActions from "../../common/filtering/filterActions";

const setCurrentPage = defaultActionFactory(fleetOverviewActionTypes.FLEET_OVERVIEW_SET_CURRENT_PAGE, 'stateDef', 'currentPage');

const queryData = queryActionFactory(
  fleetOverviewActionTypes.FLEET_OVERVIEW_QUERY_DATA_STARTING,
  fleetOverviewActionTypes.FLEET_OVERVIEW_QUERY_DATA_SUCCESS,
  fleetOverviewActionTypes.FLEET_OVERVIEW_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_FLEET_OVERVIEW_DATA,
  fleetOverviewQueries.fetchFleetOverview
);

/**
 * load fleet data for display, applied user configuration/filter for the page
 */
const loadDisplay = (stateDef, userId) => {
  return async (dispatch, getState) => {

    //load if any user display configuration for this page
    const userConfigForPage = await dispatch(appUserConfigActions.queryUserConfigurationForPage(stateDef, userId, ComponentTypes.FLEET_OVERVIEW));
    if (!_.isEmpty(userConfigForPage)){
      try{
        // load user saved configuration for the page to state
        await dispatch(filterActions.onSetAppliedFilters(stateDef, userConfigForPage));
      } catch (err){
        //just log the error and let it continue, as once page loaded then user can do filter again which can trigger save with valid data.
        // console.log(`Error when try to retrieve user configuration for fleetOverview page, for user: ${userId} , with error:${err.toString()}`);
      }
    }

    // Execute query to get the fleet data
    await dispatch(queryData(stateDef));

  }
};

const cleanUpState = (stateDef, oldFleets, newFleets) => {
  return async (dispatch, getState) => {
    if (_.isEmpty(oldFleets)) {
      return;
    }
    // Resolve the list of fleets that we no longer care about.
    let fleetsToRemove = _.differenceBy(oldFleets, newFleets, 'id');
    // Resolve the names of the state keys for the fleets we no longer care about.
    let keysToRemove = _.map(fleetsToRemove, fleet => {
      return ComponentTypes.FLEET_OVERVIEW + '-' + fleet.id
    });
    // Dispatch the app action to remove the state properties from the state
    return dispatch(rootActions.removeState(keysToRemove));
  }
};


export {
  queryData,
  cleanUpState,
  setCurrentPage,
  loadDisplay
}