import _ from 'lodash';

const parseUserConfigurationForPage = (userConfigForPage, filterDefinitions) => {
  //Get user display configuration for this page
  let result = [];
 
  if (!_.isEmpty(userConfigForPage)){
    try {
      result = userConfigForPage; // load user saved configuration for the page to state

      //for case when there is expression FilterDefinition
      //Since it's rare case to have expression filters, instead of complicate the config save logic to impact performance on each save,
      //current logic not save it in DB but retrerive it with below logic when loading
      result.filter(i => i.evaluation == null).forEach(i => {
        const mainLabel = i.chipLabel.split(':')[0]; //e.g. "Problems"
        i.evaluation = filterDefinitions
            .find(f => f.label === mainLabel).expressionDefinitions
            .find(f => f.label === i.label).expression;
      });

    } catch (err) {
      //just skip the json error and let it continue, as once page loaded then user can do filter again which can trigger save with valid data.
    }
  }
  
  return result;
}

export {
  parseUserConfigurationForPage
}