import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { appState as applicationState } from '../../../../state/app/appSelectors';
import * as appUserConfigActions from '../../../../state/app/actions/appUserConfigActions';

import * as pumpDashboardActions from '../../../../state/displays/pumpDashboard/pumpDashboardActions';
import pumpDashboardViews from '../../../../state/displays/pumpDashboard/pumpDashboardViews';
import ComponentTypes from '../../../componentTypes';
import LayoutFixedWidth from '../../../controls/layout/layoutFixedWidth';
import { trackPage } from '../../../../helpers/googleAnalyticsHelper';

import { getCardElementsFromLayout, addResizeHandle } from '../../../../helpers/cardElementsHelper';
import {compose, withProps} from "recompose";

import { CustomResizeHandle } from '../../../cards/mdtCard/controls/resizeHandle';

class DashboardView extends Component {

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.PUMP_DASHBOARD, this.props.user);
  }

  render() {

    let viewConfiguration = _.isNil(this.props.dashboards) || _.isNil(this.props.dashboards[ComponentTypes.PUMP_DASHBOARD]) ?
      {} : _.find(this.props.dashboards[ComponentTypes.PUMP_DASHBOARD].views.views, ['id', pumpDashboardViews.DASHBOARD]) || {};
    // Determine the start/end time for the 6 hour charts since this view does not have a duration picker.
    let endTime = moment().startOf('minute').unix();
    let startTime = moment.unix(endTime).subtract(360, 'minutes').unix();

    let cardElements = getCardElementsFromLayout(ComponentTypes.PUMP_DASHBOARD, viewConfiguration.id, viewConfiguration.cards, { truck: this.props.pump, startTime:startTime, endTime:endTime, liveDataEnabled: true, selectedTimeFrame: 360 }, this.props.editMode, this.props.selectedCard?.type, this.props.contextData, this.props.onCardsQueryRunning, this.props.cardsMetaconfig, null);

    let showResizeHandles = (!_.isNil(this.props.editMode) && this.props.editMode === true);

    addResizeHandle(showResizeHandles, viewConfiguration.cards);

    return (
      <LayoutFixedWidth layout={viewConfiguration.cards} cols={11} rowHeight={160} width={1880} isDraggable={this.props.editMode === true}
                        compactType={_.isNil(this.props.editMode) ? null : 'vertical'} preventCollision={this.props.editMode !== true}
                        onLayoutChange={(layout) => this.props.onUpdateDashboardLayout(ComponentTypes.PUMP_DASHBOARD, viewConfiguration, layout)}
                        resizeHandle={<CustomResizeHandle />} isEditMode={this.props.editMode}>
                        
        {
          cardElements.map((card) => {
            return (
              <div key={card.key}>{card.element}</div>
            )
          })
        }
      </LayoutFixedWidth>
    )
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.PUMP_DASHBOARD : props.stateKey,
      type: ComponentTypes.PUMP_DASHBOARD,
    }
  }
};

DashboardView.propTypes = {
  pump: PropTypes.object,
  editMode: PropTypes.bool,
  cardsMetaconfig: PropTypes.array,
};

const mapStateToProps = (state, props) => {
  let appState = applicationState(state);

  return {
    dashboards: appState.user.dashboards,
    selectedCard: appState.user.dashboards[ComponentTypes.PUMP_DASHBOARD]?.selectedCard,
    contextData:appState.context.contextData
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    onUpdateDashboardLayout: (dashboard, view, newLayout) => { dispatch(appUserConfigActions.updateDashboardLayout(dashboard, view, newLayout)); },
    onCardsQueryRunning: (queryRunning) => { dispatch(pumpDashboardActions.onCardsQueryRunning(props.stateDef, queryRunning)); }
  }
}

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(DashboardView));