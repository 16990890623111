import { performDisplayFetch } from '../../common/fetch'
import * as pumpDashboardSimulation from './pumpDashboardSimulation';

/**
 * Method to perform the remote query for the pump dashboard display data
 */
const fetchPumpDashboardDisplayData = () => {
  return performDisplayFetch({
    'query': '{ pumpDashboard { id truckPid name fleetId fleetName region division lastModified } }'
  })
};

const fetchPumpDashboardDataAge = (truckId) => {
  return performDisplayFetch({
    'query': 'query TruckDataAge($truckId:Long!){ truckDataAge(truckId: $truckId) { timestamp }}',
    'variables': {
      'truckId': truckId
    }
  })
};

const fetchSaveUserDashboard = (input) => {
  return performDisplayFetch(
    {
      'query': 'mutation SaveUserDashboardConfiguration($input:UserDashboardConfigurationInput!) { saveUserDashboardConfiguration(input: $input) { success } }',
      'variables': {
        'input': input
      }
    });
};

const fetchSaveOwnerDashboard = (input) => {
  return performDisplayFetch(
    {
      'query': 'mutation SaveOwnerDashboardConfiguration($input:OwnerDashboardConfigurationInput!) { saveOwnerDashboardConfiguration(input: $input) { success message } }',
      'variables': {
        'input': input
      }
    });
};

const fetchOwnerDefaultDashboardConfiguration = () => {
  return performDisplayFetch(
    {
      'query': 'query OwnerDefaultDashboardConfiguration { ownerDefaultDashboardConfiguration { name config } }'
    });
};

const fetchPumpDashboardCards = () => {
    return pumpDashboardSimulation.fetchPumpDashboardCards();
}

export {
  fetchPumpDashboardDisplayData,
  fetchPumpDashboardDataAge,
  fetchSaveUserDashboard,
  fetchSaveOwnerDashboard,
  fetchPumpDashboardCards,
  fetchOwnerDefaultDashboardConfiguration
};
