import _ from 'lodash';

const fetchFracOpDGBMaintenanceOverview = (fleetId) => {
    return new Promise((resolve) => {

        // Randomize the length of the simulation query so all the cards do not complete their
        // queries at once
        let queryDuration = _.random(500, 3000);

        setTimeout(() => {
            resolve({
                fleetFracOpDGBMainOverview: simOpDGBMaintenanceFracOverviewData()
            })
        }, queryDuration)
    });
};

const simOpDGBMaintenanceFracOverviewData = () =>
{
    return {
        state: 'Pumping',
        flowRate: _.round(_.random(1.1, 100.01), 2),
        pressure: _.random(10000, 20000),
        fleetType: _.sample([null, 'Frac Fleet', 'Pumper Only']),
        alarmCounts: (_.random(1, 50) % 15 > 0 ?
            {
                criticalAlarmCount: (_.random(0, 25) % 5 === 0 ? 0 : _.random(0, 25)),
                infoAlarmCount: (_.random(0, 100) % 20 === 0 ? null : _.random(0, 100)),
                warningAlarmCount: (_.random(0, 50) % 15 === 0 ? 0 : _.random(0, 50))
            } : null),
        avgEngineLoad: _.random(0,100,true),
        avgEngineTorque: _.random(0,100,true),
        avgGasSubstitution: _.random(0,100,true),
        dgbBlendingRatio: {numerator:_.random(0,50,false),denominator:_.random(50,100,false)},
        backSideCounts: (_.random(1, 50) % 2 > 0 ?
            [
                {
                    label: 'All',
                    low: _.random(1, 10),
                    med: _.random(1, 10),
                    high: (_.random(1, 10) % 3 === 0 ? 0 :  _.random(1, 10))
                }
            ] : null),
        frontSideCounts: (_.random(1, 50) % 15 > 0 ?
            _.random(1, 10) % 2 === 0 ?
                [
                    {
                        label: 'Eng',
                        low: _.random(1, 10),
                        med: (_.random(1, 10) % 3 === 0 ? null :  _.random(1, 10)),
                        high: _.random(1, 10)
                    },
                    {
                        label: 'Tran',
                        low: _.random(1, 10),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    },
                    {
                        label: 'PE',
                        low: (_.random(1, 10) % 3 === 0 ? 0 :  _.random(1, 10)),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    },
                    (_.random(1, 10) % 2 === 0 ? {
                        label: 'FE',
                        low: _.random(1, 10),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    } : {})
                ]:
                [
                    {
                        label: 'Eng',
                        low: _.random(1, 10),
                        med: (_.random(1, 10) % 3 === 0 ? null :  _.random(1, 10)),
                        high: _.random(1, 10)
                    },
                    {
                        label: 'Tran',
                        low: _.random(1, 10),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    },
                    {
                        label: 'PE',
                        low: (_.random(1, 10) % 3 === 0 ? 0 :  _.random(1, 10)),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    },
                    {
                        label: 'PEL',
                        low: _.random(1, 10),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    },
                    (_.random(1, 10) % 2 === 0 ? {
                        label: 'EO',
                        low: _.random(1, 10),
                        med: _.random(1, 10),
                        high: _.random(1, 10)
                    } : {})
                ]
             : null)
    }
};
export {
    fetchFracOpDGBMaintenanceOverview
}
