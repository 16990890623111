import updateRecords from "./updateHistory.json";
import moment from "moment-timezone";
import _ from "lodash";

const updateHistoryDateFormat = ['MMMM DD YYYY hh:mm:ss', 'MMMM DD YYYY'];// [August 10 2022 18:00:00, August 10 2022]
const updateHistoryTz = 'America/Edmonton';
const epochMoment = moment(new Date(0));
const whatsNewDialogDisplayDateFormat = 'MMMM DD YYYY';

const whatsNewRecords = () => {
    if (!_.isNil(updateRecords) && Array.isArray(updateRecords) && updateRecords.length > 0) {
        return updateRecords
            .filter(upd => upd.hasOwnProperty('date'))
            .map(update => ({...update, date: moment.tz(update.date, updateHistoryDateFormat, updateHistoryTz)}));
    }
    return [];
}

const getLatestWhatsNewRecordDate = () => {
    let updateHistory = whatsNewRecords()
        .map(update => update.date)
        .sort((a, b) => b.diff(a));

    return updateHistory.length > 0 ? updateHistory[0] : epochMoment;
}

const getWhatsNewRecords = () => {
    return whatsNewRecords()
        .map(update => ({...update, date: update.date.format(whatsNewDialogDisplayDateFormat)}));
}

const getGroupings = () => {
  const groupings = [];
  whatsNewRecords().forEach(record => {
    if (!_.includes(groupings, moment(record.date).year())) {
      groupings.push(moment(record.date).year());
    }
  })
  return groupings;
}

export {
    getLatestWhatsNewRecordDate,
    getWhatsNewRecords,
    getGroupings
}
