import _ from 'lodash';
import dataGridActionTypes from './dataGridActionTypes';
import { dataGridState } from "./dataGridSelectors";
import { updateDefn } from './services/dataGridService';
import { getCardFromLayoutConfigViews } from '../../../components/common/layout/layoutHelper';
import { determineTruckFromDashboard } from '../../common/services/truckService';
import appActionTypes from "../../app/appActionTypes";

const initialState = dataGridState();

const dataGridReducer = (state = initialState, action) => {
  switch (action.type) {
    case dataGridActionTypes.DATA_GRID_QUERY_DATA_STARTING:
      return { ...state, queryRunning: true };
    case dataGridActionTypes.DATA_GRID_QUERY_DATA_ERROR:
      return { ...state, queryRunning: false };
    case dataGridActionTypes.DATA_GRID_QUERY_DATA_SUCCESS:
      return onQuerySuccess(state, action);
    case dataGridActionTypes.DATA_GRID_QUERY_SENSORS_SUCCESS:
      return onQuerySensorSuccess(state, action);
    case dataGridActionTypes.DATA_GRID_CLEAR_DATA:
      return onClearData(state, action);

    case dataGridActionTypes.DATA_GRID_DEFINITION_SET_DEFAULT_TRUCK:
      return onSetDefinitionDefaultTruck(state, action);
    case dataGridActionTypes.DATA_GRID_DEFINITION_SET_START_TIME:
      return onSetDefinitionStartTime(state, action);
    case dataGridActionTypes.DATA_GRID_DEFINITION_SET_SENSORS:
      return onSetDefinitionSensors(state, action);
    case dataGridActionTypes.DATA_GRID_DEFINITION_SET_VIEW:
      return  onSetDefinitionView(state, action);

    case dataGridActionTypes.DATA_GRID_CONFIG_OPEN_SENSOR_SELECTOR:
      return updateConfigSensorSelectorState(state, true);
    case dataGridActionTypes.DATA_GRID_CONFIG_CLOSE_SENSOR_SELECTOR:
      return updateConfigSensorSelectorState(state, false);
    case dataGridActionTypes.DATA_GRID_CONFIG_SET_SELECTED_SENSORS:
      return onSelectedSensors(state, action.xAxisId, action.sensors);

    case dataGridActionTypes.DATA_GRID_CONFIG_UPDATE_DISPLAY_NAME:
      return updateSensorDisplayName(state, action);
    case dataGridActionTypes.DATA_GRID_CONFIG_UPDATE_UOM:
      return updateSensorUOM(state, action);
    case dataGridActionTypes.DATA_GRID_CONFIG_UPDATE_CONDITIONAL_FORMATTING:
      return updateSensorConditionalFormatting(state, action);
    case dataGridActionTypes.DATA_GRID_CONFIG_SET_COLOR_PICKER_STATE:
      return setColorPickerState(state, action);

    case dataGridActionTypes.DATA_GRID_CONFIG_DISCARD_DEFINITION:
      return onDiscardDefinition(state, action);

    case dataGridActionTypes.DATA_GRID_CONFIG_UPDATE_ORDER:
      return onUpdateSensorsOrder(state, action);

    case dataGridActionTypes.DATA_GRID_CONFIG_SET_BORDER_AND_TEXT_COLOR:
      return updateSensorBorderAndTextColor(state, action);

    case dataGridActionTypes.DATA_GRID_SETUP_EDIT_MODE:
      return onSetupEditMode(state, action);

    case dataGridActionTypes.DATA_GRID_CONFIG_ADD_CONDITIONAL_FORMATTING:
      return onAddDataGridSensorConditionalFormatting(state, action);

    case dataGridActionTypes.DATA_GRID_CONFIG_REMOVE_CONDITIONAL_FORMATTING:
      return onRemoveDataGridSensorConditionalFormatting(state, action);

    case dataGridActionTypes.DATA_GRID_CONFIG_REMOVE_SENSOR:
      return onRemoveDataGridSensor(state, action);

    case appActionTypes.APP_PROCESS_WEBSOCKET_DATA:
      return onProcessWebSocketData(state, action);
    default: return state;
  }
};

const onClearData = (state, action) => {

  return {
    ...state,
    latestValues: {}
  };

};

const onQuerySuccess = (state, action) => {
  let latest = {};
  let definition = _.cloneDeep(state.definition);

  _.map(definition.primary.sensors, (sensor) => {
    const series = _.find(action.queryResults.dataGrid.series, function (series) { return sensor.sensorSetId === series.sensorSetId; })

    if (_.isNil(series)) {
      latest[sensor.sensorSetId] = '-';
    }
    else {
      latest[sensor.sensorSetId] = parseFloat(series.latest.toFixed(1)).toLocaleString('en');
    }
  })

  return {
    ...state,
    queryRunning: false,
    latestValues: latest,
    definition: definition,
  };
};

const onQuerySensorSuccess = (state, action) => {
  const sensors = action.queryResults.sensorForAssets;
  let selectedSensors = _.cloneDeep(state.definition.primary.sensors);
  if (!_.isNil(sensors)) {
    const targetUoms = _.chain(sensors).keyBy('sensorSetId').mapValues('targetUoms').value();
    selectedSensors.forEach(sensor => {
      sensor.targetUoms = targetUoms[sensor.sensorSetId];
    });
  }
  return {
    ...state,
    queryRunning: false,
    definition: {
      ...state.definition,
      primary: {
        ...state.definition.primary,
        sensors: selectedSensors,
      }
    },
    allSensors: sensors ?? [],
  }
}

const onSetDefinitionDefaultTruck = (state, action) => {
  let newDefinition = _.cloneDeep(state.definition);

  let truck = determineTruckFromDashboard(action.truck, action.dashboard);

  newDefinition.primary.defaultTruck =
  {
    id: truck.id,
    pid: truck.truckPid,
    name: truck.name,
    unitType: truck.unitType
  };
  newDefinition.primary.trucks = Array(newDefinition.primary.sensors.length).fill(newDefinition.primary.defaultTruck);

  return {
    ...state,
    definition: newDefinition,
  };
};

const onSetDefinitionStartTime = (state, action) => {
  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.timeRange.startTime = action.startTime;
  newDefinition.primary.timeRange.duration = action.duration;
  return {
    ...state,
    definition: newDefinition,
  };
};

const onSetDefinitionSensors = (state, action) => {
  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.sensors = action.sensors;

  newDefinition.primary.trucks = Array(action.sensors.length).fill(newDefinition.primary.defaultTruck);

  return {
    ...state,
    definition: newDefinition,
  };
};

const onSetDefinitionView = (state, action) => {
  let newDefinition = _.cloneDeep(state.definition);
  newDefinition.primary.dataView = action.dataView;

  return {
    ...state,
    definition: newDefinition,
  };
}

const updateConfigSensorSelectorState = (state, enabled) => {
  return {
    ...state,
    shouldOpenConfigSensorSelector: enabled
  };
};

const onSelectedSensors = (state, xAxisId, newSensors) => {
  if (_.isEqual(state.definition[xAxisId].sensors, newSensors)) {
    return state;
  }

  let definition = updateDefn(state.definition, newSensors, xAxisId);

  return {
    ...state,
    definition: definition,
  };
};

const updateSensorDisplayName = (state, action) => {
  const definition = _.cloneDeep(state.definition);
  _.find(definition.primary.sensors, function (sensor) { return sensor.sensorSetId === action.sensorSetId && sensor.alias === action.alias; }).displayName = action.displayName;
  return {
    ...state,
    definition: definition,
  };
};

const updateSensorUOM = (state, action) => {
  const definition = _.cloneDeep(state.definition);
  _.find(definition.primary.sensors, function (sensor) { return sensor.sensorSetId === action.sensorSetId && sensor.alias === action.alias; }).uom = action.uom;
  return {
    ...state,
    definition: definition,
  };
};

const updateSensorConditionalFormatting = (state, action) => {
  const definition = _.cloneDeep(state.definition);
  const currentSensor = definition.primary.sensors.find((sensor) => { return sensor.sensorSetId === action.sensorSetId && sensor.alias === action.alias; });

  if (_.isNil(action.index)) {
    currentSensor.conditionalFormatting[action.property] = action.value;
    if (action.property === 'applied' && action.value === true && _.isEmpty(currentSensor.conditionalFormatting.rules)) {
      //Add default rule when there're no rules in the conditionalFormatting
      currentSensor.conditionalFormatting.rules.push({
        color: currentSensor.conditionalFormatting.defaultSensorColor,
        condition: "greater than",
        value1: 0,
        value2: 0
      });
    }
  } else {
    currentSensor.conditionalFormatting.rules[action.index][action.property] = action.value;
  }

  if (action.property === "condition" && !_.includes(action.value, "between")) {
    currentSensor.conditionalFormatting.rules[action.index].value2 = 0;
  }
  return {
    ...state,
    definition: definition,
  };
};

const updateSensorBorderAndTextColor = (state, action) => {
  const newDefinition = _.cloneDeep(state.definition);
  const currSensor = newDefinition.primary.sensors.find((sensor) => { return sensor.sensorSetId === action.sensorSetId && sensor.alias === action.alias });

  if (_.isNil(action.borderAndTextColorRules)) {
    currSensor.borderAndTextColorRules = {
      applyRule: false,
      borderAndTextColor: '#c6c6c6',
    }
  } else {
    currSensor.borderAndTextColorRules = action.borderAndTextColorRules;  
  }
  

  return {
    ...state,
    definition: newDefinition,
  };
}

const setColorPickerState = (state, action) => {
  const newColorPickerStates = [];

  if (!_.isNil(action.sensor)) {
    const colorPickerState = newColorPickerStates[action.index];
    if (_.isNil(colorPickerState)) {
      newColorPickerStates[action.index] = { sensor: action.sensor, origColor: action.origColor };
    } else {
      colorPickerState.origColor = action.origColor;
    }
  }
  return {
    ...state,
    colorPickerStates: newColorPickerStates
  };
};

const onDiscardDefinition = (state, action) => {
  const originalCard = getCardFromLayoutConfigViews(action.originalViews, action.view, action.cardKey);
  let newDefinition = _.cloneDeep(state.definition);
  if (!_.isNil(originalCard)) {
    newDefinition.primary.sensors = originalCard.configuration.sensors;
    newDefinition.primary.dataView = originalCard.configuration.dataView;
  }
  return {
    ...state,
    definition: newDefinition
  };
};

const onUpdateSensorsOrder = (state, action) => {
  const newOrder = _.cloneDeep(state.definition.primary.sensors);
  const [movedSensor] = newOrder.splice(action.removedIndex, 1);
  newOrder.splice(action.addedIndex, 0, movedSensor);

  const definition = _.cloneDeep(state.definition);
  definition.primary.sensors = newOrder;

  return {
    ...state,
    definition: definition
  };
};

const onSetupEditMode = (state, action) => {
  return {
    ...state,
    latestValues: initialState.latestValues,
  };
};

const onAddDataGridSensorConditionalFormatting = (state, action) => {
  const definition = _.cloneDeep(state.definition);
  const currentSensor = definition.primary.sensors.find((sensor) => { return sensor.sensorSetId === action.sensorSetId && sensor.alias === action.alias; });

  currentSensor.conditionalFormatting.rules.push({
    color: currentSensor.conditionalFormatting.defaultSensorColor,
    condition: "greater than",
    value1: 0,
    value2: 0
  });

  return {
    ...state,
    definition: definition
  };
};

const onRemoveDataGridSensorConditionalFormatting = (state, action) => {
  const definition = _.cloneDeep(state.definition);
  const currentSensor = definition.primary.sensors.find((sensor) => { return sensor.sensorSetId === action.sensorSetId && sensor.alias === action.alias; });

  currentSensor.conditionalFormatting.rules.splice(action.index, 1);
  if (_.isEmpty(currentSensor.conditionalFormatting.rules)) {
    currentSensor.conditionalFormatting.applied = false;
  }
  return {
    ...state,
    definition: definition
  };
};

const onRemoveDataGridSensor = (state, action) => {
  const definition = _.cloneDeep(state.definition);
  _.remove(definition.primary.sensors, {sensorSetId: action.sensorSetId, alias: action.alias});

  _.map(definition.primary.sensors, (sensor, index) => {
    if (index < action.area) {
      sensor.isVisible = true;
    } else {
      sensor.isVisible = false
    }
    return sensor;
  })

  return {
    ...state,
    definition: definition
  };
};

const onProcessWebSocketData = (state, action) => {
  const latest = _.cloneDeep(state.latestValues);
  const currentTruck = state.definition.primary.defaultTruck;

  //It will only update the sensor values for the current active truck and the sensorSetIds that are in the definition
  if(!_.isNil(action.data) && !_.isEmpty(action.data.payload) && !_.isNil(currentTruck)){
    action.data.payload.forEach((payload) => {
        if(!_.isEmpty(payload.sensorSetIds) && currentTruck.pid === payload.truckPid){
            payload.sensorSetIds.forEach((sensorSetId, index) => {
              latest[sensorSetId] = parseFloat(payload.values[index].toFixed(1)).toLocaleString('en');
            });
        }
    });
  }
  return {
    ...state,
    latestValues: latest
  };
};

export default dataGridReducer