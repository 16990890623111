import React, { Component, Fragment, useContext } from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@mui/material/Autocomplete';

import { IconButton, Typography, Tooltip, Box, Chip, Button, Divider, Icon, Grid } from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import GearsIcon from '../../controls/icons/gears';
import ShowChartIcon from "@mui/icons-material/ShowChart";
import FilterDialog from '../../common/filtering/filterDialog';
import { parse } from 'query-string';
import EditIcon from '@mui/icons-material/Edit';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

import Progress from "../../controls/progress";
import DashboardView from './views/dashboard';
import OperationView from './views/operation';
import DataExplorationView from "../../common/dataExploration/view/dataExploration";
import ComponentTypes from '../../componentTypes';
import * as pumpDashboardActions from '../../../state/displays/pumpDashboard/pumpDashboardActions';
import * as filterActions from '../../../state/common/filtering/filterActions';
import { pumpDashboardState } from '../../../state/displays/pumpDashboard/pumpDashboardSelectors';
import {appState as applicationState } from '../../../state/app/appSelectors';
import pumpDashboardViews from '../../../state/displays/pumpDashboard/pumpDashboardViews';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import getDisplayStyles from '../../common/styles/displayStyles';
import { AutoCompleteMDT, DateTimePickerMDT } from '../../controls/mdtMuiControls';
import * as appNavActions from '../../../state/app/actions/appNavActions';
import ConfirmationDialog from '../../controls/dashboards/confirmationDialog';
import * as contextActions from '../../../state/app/actions/appContextActions';
import * as appUserConfigActions from "../../../state/app/actions/appUserConfigActions";
import * as appUserActions from '../../../state/app/actions/appUserActions';
import CustomView from "./views/customView";

import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import { steps, tourOptions } from './shepherd/shepherdOptions';

const displayStyles = getDisplayStyles();
const styles = {
  ...displayStyles,
  contextSelection: {
    width: '150px',
    marginLeft: 1,
    paddingRight: 1,
  },
  dateTimeControl: {
    // NOTE: The padding below is a bit of a hack to
    // get the 3rd Party datetime picker to align
    // correctly with the other controls.
    paddingTop: 2,
  },
  pumpNextPrevActions: {
    marginRight: 6
  },
  pageHeaderActions: {
    ...displayStyles.pageHeaderActions,
    alignItems: 'center',
  },
  editModePageHeaderActions: {
    ...displayStyles.pageHeaderActions,
    alignItems: 'center',
    flexGrow: 1
  },
  editModeActions: {
    ...displayStyles.pageHeaderActions,
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center'
  },
  actionButton: {
    backgroundColor: 'grey.200',
    color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
    '&:hover': {
      backgroundColor: 'grey.400',
      color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
    }
  },
  actionsDivider: {
    height: '36px',
    marginLeft: '12px',
    marginRight: '12px'
  },
  filterContainer: {
    ...displayStyles.filterContainer,
    paddingLeft: '0px',
  },
  filterListContainer: {
    paddingTop: 0.4,
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'start',
    width: '650px',
    alignItems: 'flex-start',
  },
  pageHeaderButton: {
    ...displayStyles.pageHeaderButton,
    paddingLeft: '6px',
  },
  childFilterComponent: {
    ...displayStyles.childComponent,
    marginTop: 1
  }
};

const ShepherdButton = (props) => {
  let tour = useContext(ShepherdTourContext);

  return (
    <Box sx={{ marginLeft: '12px' }}>
      <Tooltip title="Assistance">
        <Box>
          <IconButton
            onClick={() => { tour.start(); }}
            size="large"
            disabled={props.isDisabled}
          >
            <LiveHelpIcon />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  )
}

class PumpDashboard extends Component {

  constructor(props) {
    super(props);
    this.manualRefreshTime = null;
  }

  componentDidMount() {
    // Load the dashboard data. NOTE: Loading the display data implicitly
    // handles the setting of the selected context which will trigger the
    // loading of the data age if necessary (see componentDidUpdate)
    this.props.loadDisplay();
    // Start the timer for refreshing the display.
    this.periodicDisplayRefreshId = this.periodicRefreshDisplay();
    // Start the timer for refreshing the relative time label
    this.periodicRelativeTimeRefreshId = this.periodicRefreshRelativeTime();

    // This will use the parse function from query-string package to parse out the 'unitNumber' parameter
    const parsedLocation = parse(location.search);
    this.foundParsedLocation = parsedLocation.unitNumber;

    // Show the Context Drawer if we left Pump Dashboard in Edit Mode, went somewhere else, and are coming back
    if (this.props.editMode === true) {
      this.props.onEditDashboard(true);
    }

    // Show the custom view drawer if we left Pump Dashboard and are coming back
    if (this.props.isManageCustomViews === true) {
      this.props.onManageCustomViews(true);
    }
  }

  componentWillUnmount() {
    // Stop the timer for refreshing the display.
    if (!_.isNil(this.periodicDisplayRefreshId)) {
      clearInterval(this.periodicDisplayRefreshId);
    }
    // Stop the timer for refreshing the relative time label
    if (!_.isNil(this.periodicRelativeTimeRefreshId)) {
      clearInterval(this.periodicRelativeTimeRefreshId);
    }
    // Hide the Context Drawer if it's opened and we are going away from the Pump Dashboard
    if ((this.props.editMode === true || this.props.isManageCustomViews) &&
      (this.props.contextDrawerOpen || this.props.contextDrawerMinimized)) {
      this.props.onOpenContextDrawer(false, this.props.configPanelWidth);
      this.props.onSelectCard(null);
    }
  }

  componentDidUpdate(prevProps) {
    //Check if selectedContext has been reset or not, it doesn't matter if it's been set to the exact same context(refreshing) or not.
    //Please avoid using clonedeep when updating the state in reducer to trigger unwanted loading dataage
    if (prevProps.selectedContext !== this.props.selectedContext) {
      // Load the data age if the selected context changes
      this.loadDataAge();
      // Load the context from the URL if possible
      this.parseAndSelectContext();
    }
  }

  periodicRefreshDisplay() {
    // Periodically refresh the display. NOTE: Loading the display data
    // implicitly handles the setting of the selected context which will
    // trigger the loading of the data age if necessary (see componentDidUpdate)
    return setInterval(() => {
      // If we are in Edit mode, do not perform automatic refreshes
      if (this.props.editMode !== true && this.props.isManageCustomViews !== true) {
        this.props.loadDisplay();
        this.props.onRefreshTimestamp(moment().unix());
      }
    }, 180000); // 3 minutes
  }

  periodicRefreshRelativeTime() {
    // Periodically refresh the label for the data age
    return setInterval(() => {
      // If we are in Edit mode, do not perform automatic refreshes
      if (this.props.editMode !== true && this.props.isManageCustomViews !== true) {
        this.props.refreshRelativeTime();
      }
    }, 60000); // 1 minute
  }

  manualRefreshDisplay() {
    // Refresh the display data when a user manual triggers a refresh.
    // NOTE: Loading the display data implicitly handles the setting of
    // the selected context which will trigger the loading of the data
    // age if necessary (see componentDidUpdate)
    this.props.loadDisplay();
    this.manualRefreshTime = moment().unix();
    this.props.onRefreshManualTimestamp(moment().unix());
  }

  loadDataAge() {
    if (!_.isNil(this.props.selectedContext) && !_.isNil(this.props.selectedContext.id)) {
      // Update the Data Age
      this.props.queryDataAge(this.props.selectedContext.id);
    } else {
      // When the selected owner has no trucks, we want to clear the existing data age
      this.props.clearDataAge();
    }
  }

  parseAndSelectContext() {

    if (!_.isNil(this.foundParsedLocation) && !_.isEmpty(this.props.contexts)) {
      // Find the given parameter in the collection of contexts
      let foundContext = _.find(this.props.contexts, ['name', this.foundParsedLocation]);

      // Whether or not the provided context is valid...
      // Reset the URL to not include the context - we need to do this otherwise it will remain and any further actions on
      // this display will not know this context is not applicable anymore (ie. user selects a new context via the context selector)
      this.props.resetUrl();

      if (!_.isNil(foundContext)) {
        // Set the context using the existing navigation context methods available to us
        this.props.setNavigationContext({ unitId: foundContext.id });
        this.foundParsedLocation = null;
      }
    }
  }

  render() {

    // Check for an empty filter collection here to keep the markup cleaner. This disables the add filter button.
    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));
    let showTimeFilters = this.props.selectedView === pumpDashboardViews.OPERATION || this.props.selectedView === pumpDashboardViews.DATA_EXPLORATION || this.props.selectedView.type === ComponentTypes.USER_VIEW;
    let showAdditionalTimeFilters = showTimeFilters === true && !_.isNil(this.props.selectedTimeFrame) && this.props.selectedTimeFrame.label === 'Custom';

    let foundView = _.isEmpty(this.props.user.dashboards) ? null : _.find(this.props.user.dashboards[ComponentTypes.PUMP_DASHBOARD].views.views, ['id', this.props.selectedView?.id ?? this.props.selectedView]);
    let allowCustomization = (_.isNil(foundView) ? false : foundView.allowCustomization);
    const manageViewOption = { id: 'MANAGE_VIEWS', name: 'Manage Views', type: ComponentTypes.USER_VIEW, isFavorite: false, icon: 'dashboard_customize' };
    const customViews = [
      ..._.filter(this.props.dashboardViews, ['type', ComponentTypes.USER_VIEW]),
      manageViewOption
    ];
    const favoriteViews = _.filter(this.props.dashboardViews, ['isFavorite', true]);
    const filterOptions = createFilterOptions({
      stringify: (option) => option.name,
    });
    let isInvalidSelectedCustomView = _.isNil(this.props?.selectedCustomView?.name) || !_.includes(customViews.map(x => x.id), this.props?.selectedCustomView?.id);

    return (
      <Box sx={styles.page}>
        <Box sx={{...styles.pageContent, width: '100%', flexFlow: 'row nowrap', justifyContent: 'flex-start'}}>
          <Box sx={{...styles.pageHeader, flexGrow: 1 }}>
            <Box sx={{ ...styles.pageHeaderTitle, width: '220px' }}>
              <Typography variant={"h6"}>Pump Dashboard</Typography>
              {
                <ShepherdTour steps={steps} tourOptions={tourOptions}>
                  <ShepherdButton isDisabled={this.props.isManageCustomViews || this.props.editMode === true} />
                </ShepherdTour>
              }
            </Box>
            {
              this.props.editMode === true &&
              <Box sx={styles.editModePageHeaderActions}>
                <Divider sx={styles.actionsDivider} orientation='vertical' />
                <Box sx={{ ...styles.editModeActions, visibility: (!_.isNil(this.props.contextData.card) ? 'hidden' : 'visibile') }}>
                  <Box sx={{ display: 'flex', flexFlow: 'row-reverse nowrap', marginRight: 2, flexGrow: 0.5 }}>
                    <Typography variant='caption'>Add, Remove, Configure, and Position Cards</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexFlow: 'row nowrap', marginLeft: 2, flexGrow: 0.5 }}>
                    <Button sx={{ ...styles.actionButton, marginRight: 2 }} variant='contained'
                      onClick={() => { this.props.user.dashboards[ComponentTypes.PUMP_DASHBOARD].hasChanges ? this.props.onShowDialog("undoChanges") : this.props.onUndoChangesDialogOK(); }}>
                      {
                        this.props.user.dashboards[ComponentTypes.PUMP_DASHBOARD].hasChanges ? 'Discard Changes' : 'Cancel'
                      }
                    </Button>
                    <Button variant='contained' color='primary' onClick={() => { this.props.onSaveDashboardToUser(ComponentTypes.PUMP_DASHBOARD); }}
                      disabled={!this.props.user.dashboards[ComponentTypes.PUMP_DASHBOARD].hasChanges}>Save</Button>

                    <Box sx={{ display: 'flex', flexFlow: 'row-reverse nowrap', flexGrow: 1 }}>
                      {
                        // These buttons are only available for system views, which do not have a type property
                        _.isNil(this.props.selectedView.type) &&
                        <Box sx={{ display: 'flex', flexFlow: 'row nowrap', flexGrow: 1, justifyContent: 'flex-start', marginLeft: 8 }}>
                          <Button variant='contained' color='primary' onClick={() => this.props.onShowDialog("restoreDefault")}>Restore Default</Button>
                          {this.props.user.hasDashboardAdminPermission === true &&
                            <Button sx={{ marginLeft: '10px' }} variant='contained' color='primary' onClick={() => this.props.onShowDialog("setOwnerDefault")}>Save as Default</Button>
                          }
                        </Box>
                      }
                    </Box>

                  </Box>
                </Box>
              </Box>
            }
            {
              this.props.editMode !== true &&
              <>
                <Box sx={styles.pageHeaderActions}>
                  <Divider sx={styles.actionsDivider} orientation='vertical' />
                  {
                    allowCustomization &&
                    <>
                      <Box sx={{ ...styles.pageHeaderButton, paddingLeft: 0 }}>
                        <Tooltip title="Edit View" className='editViewButton'>
                          <Box>
                            <IconButton
                              onClick={() => { this.props.onEditDashboard(true); }}
                              size="large"
                              disabled={this.props.isManageCustomViews === true}
                            >
                              <EditIcon />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Box>
                    </>
                  }
                  <Box sx={styles.pageHeaderButton}>
                    <Tooltip title="Refresh">
                      <IconButton
                        onClick={() => this.manualRefreshDisplay()}
                        size="large"
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Divider sx={styles.actionsDivider} orientation='vertical' />
                  <Box sx={{ ...styles.pageHeaderButton, paddingLeft: 0 }}>
                    <Tooltip title="Dashboard View">
                      <IconButton
                        onClick={() =>
                          this.props.selectView(pumpDashboardViews.DASHBOARD)
                        }
                        color={
                          this.props.selectedView === pumpDashboardViews.DASHBOARD
                            ? 'primary'
                            : 'inherit'
                        }
                        size="large"
                      >
                        <DashboardIcon />
                      </IconButton>
                    </Tooltip>
                    <svg height="3" width="51">
                      <line
                        x1="0"
                        y1="0"
                        x2="51"
                        y2="0"
                        stroke="#CE4300"
                        strokeWidth="3"
                        visibility={
                          this.props.selectedView === pumpDashboardViews.DASHBOARD
                            ? "visible"
                            : "hidden"
                        }
                      />
                    </svg>
                  </Box>
                  <Box sx={styles.pageHeaderButton}>
                    <Tooltip title="Operation View">
                      <IconButton
                        onClick={() =>
                          this.props.selectView(pumpDashboardViews.OPERATION)
                        }
                        color={
                          this.props.selectedView === pumpDashboardViews.OPERATION
                            ? 'primary'
                            : 'inherit'
                        }
                        size="large"
                      >
                        <GearsIcon />
                      </IconButton>
                    </Tooltip>
                    <svg height="3" width="51">
                      <line
                        x1="0"
                        y1="0"
                        x2="51"
                        y2="0"
                        stroke="#CE4300"
                        strokeWidth="3"
                        visibility={
                          this.props.selectedView === pumpDashboardViews.OPERATION
                            ? "visible"
                            : "hidden"
                        }
                      />
                    </svg>
                  </Box>
                  <Box sx={styles.pageHeaderButton}>
                    <Tooltip title="Data Exploration View">
                      <IconButton
                        onClick={() =>
                          this.props.selectView(pumpDashboardViews.DATA_EXPLORATION)
                        }
                        color={
                          this.props.selectedView === pumpDashboardViews.DATA_EXPLORATION
                            ? 'primary'
                            : 'inherit'
                        }
                        size="large"
                      >
                        <ShowChartIcon />
                      </IconButton>
                    </Tooltip>
                    <svg height="3" width="51">
                      <line
                        x1="0"
                        y1="0"
                        x2="51"
                        y2="0"
                        stroke="#CE4300"
                        strokeWidth="3"
                        visibility={
                          this.props.selectedView === pumpDashboardViews.DATA_EXPLORATION
                            ? "visible"
                            : "hidden"
                        }
                      />
                    </svg>
                  </Box>
                </Box>
                <Divider sx={styles.actionsDivider} orientation='vertical' />
                <Box sx={styles.filterContainer} className="customViewsComboBox">
                  <AutoCompleteMDT
                    sx={{ ...styles.dashboardSelection, width: '200px', paddingLeft: '0px' }}
                    fullWidth forcePopupIcon
                    options={customViews}
                    filterOptions={filterOptions}
                    getOptionLabel={(view) => view.name ?? ''}
                    renderOption={(props, option, state) => {
                      return (
                        <Fragment key={option.id}>
                          {option.id === manageViewOption.id && <hr />}
                          <li {...props}>
                            <Grid container spacing={1}>
                              <Grid item sx={{ width: '30px' }}>
                                <Icon font='small'>{option.icon}</Icon>
                              </Grid>
                              <Grid item sx={{
                                width: '125px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'
                              }}>{option.name}</Grid>
                            </Grid>
                          </li>
                        </Fragment>
                      )
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={this.props.selectedCustomView}
                    inputValue={this.props.customViewInput}  //set input value to keep selected option as is when click on manage view option
                    getOptionDisabled={option => option.id === manageViewOption.id && this.props.isManageCustomViews}
                    renderInput={(params) => {
                      const inputProps = { ...params.InputProps, style: { fontSize: "1.15rem" }, disableUnderline: true };
                      return <>
                        <TextField {...params}
                          InputProps={inputProps}
                          autoFocus variant="standard"
                          placeholder={isInvalidSelectedCustomView ? "Custom Views" : this.props.selectedCustomView.name}
                        />
                      </>;
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (!_.isNil(event) && event && event.type !== 'blur' && newInputValue !== manageViewOption.name) {
                        this.props.onUpdateCustomViewInput(newInputValue);
                      }
                    }}
                    onChange={(event, value, reason) => {
                      if (value.id === manageViewOption.id) {
                        this.props.onManageCustomViews(true);
                      } else {
                        this.props.selectView(value);
                      }
                    }}
                  />
                </Box>
                {
                  !_.isEmpty(favoriteViews) && favoriteViews.slice(0, 3).map(favoriteView => {
                    return (
                      <Fragment key={favoriteView.id}>
                        <Box sx={styles.pageHeaderButton}>
                          <Tooltip title={favoriteView.name}>
                            <IconButton
                              onClick={() =>
                                this.props.selectView(favoriteView)
                              }
                              color={
                                this.props.selectedView?.id === favoriteView.id
                                  ? 'primary'
                                  : 'inherit'
                              }
                              size="large"
                            >
                              <Icon>{favoriteView.icon}</Icon>
                            </IconButton>
                          </Tooltip>
                          <svg height="3" width="51">
                            <line
                              x1="0"
                              y1="0"
                              x2="51"
                              y2="0"
                              stroke="#CE4300"
                              strokeWidth="3"
                              visibility={
                                this.props.selectedView?.id === favoriteView.id
                                  ? "visible"
                                  : "hidden"
                              }
                            />
                          </svg>
                        </Box>
                      </Fragment>
                    );
                  })
                }
                <Divider sx={styles.actionsDivider} orientation='vertical' />
                <Typography variant={"subtitle1"}>FILTER:</Typography>
                <Box sx={styles.filterListContainer}>
                  {this.props.appliedFilters.map((filterContext, index) => {
                    return (
                      <Chip sx={styles.childFilterComponent}
                        key={index}
                        label={filterContext.chipLabel}
                        onDelete={() => this.props.deleteFilterContext(index)}
                      />
                    );
                  })}  
                  <IconButton
                              onClick={() => this.props.openFilterDialog()}
                              disabled={emptyFilters}
                              size="large"
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Box>
                <Box sx={styles.dataAgeContainer}>
                  <Typography sx={styles.childComponent}>Data as of:</Typography>
                  <Typography sx={styles.childComponent}>{this.props.dataAgeDisplay}</Typography>
                </Box>
              </>
            }
          </Box>
          <Box id="screenshot-area" sx={{ height: "max-content", width: "fit-content", backgroundColor: "grey.900" }}>
            {
              this.props.editMode !== true &&
              <Box sx={styles.selectionContainer}>
                <Typography variant={"subtitle1"}>PUMP:</Typography>
                <AutoCompleteMDT
                  sx={styles.contextSelection}
                  options={this.props.contexts}
                  value={this.props.selectedContext}
                  onChange={(event, value, reason) => {
                    this.props.selectContext(value);
                  }}
                  noOptionsText={"No pumps found..."}
                />
                <Box sx={styles.pumpNextPrevActions}>
                  <Tooltip title=
                    {
                      (!_.isEmpty(this.props.contexts) &&
                        _.first(this.props.contexts) === this.props.selectedContext) ?
                        "No Prev Pump" :
                        "Prev Pump"
                    }>
                    <span>
                      <IconButton
                        disabled={
                          (!_.isEmpty(this.props.contexts) &&
                            _.first(this.props.contexts) === this.props.selectedContext) ||
                          (this.props.cardsQueryRunning)
                        }
                        onClick={() => this.props.selectPrevPump()}
                        size="large"
                      >
                        <NavigateBeforeIcon />
                      </IconButton>
                    </span>
                  </Tooltip>

                  <Tooltip title={
                    (!_.isEmpty(this.props.contexts) &&
                      _.last(this.props.contexts) === this.props.selectedContext) ?
                      "No Next Pump" :
                      "Next Pump"
                  }>
                    <span>
                      <IconButton
                        disabled={
                          (!_.isEmpty(this.props.contexts) &&
                            _.last(this.props.contexts) === this.props.selectedContext) ||
                          (this.props.cardsQueryRunning)
                        }
                        onClick={() => this.props.selectNextPump()}
                        size="large"
                      >
                        <NavigateNextIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
                {showTimeFilters === true && (
                  <Box sx={styles.timeFilterContainer}>
                    <Typography variant={"subtitle1"}>TIME FRAME:</Typography>
                    <Box sx={styles.timeFilterItem}>
                      <AutoCompleteMDT
                        sx={styles.timeFrameSelection}
                        getOptionLabel={(timeFrame) => timeFrame.label}
                        options={this.props.timeFrames}
                        value={this.props.selectedTimeFrame}
                        onChange={(event, value, reason) => {
                          this.props.setTimeFrame(value);
                        }}
                        noOptionsText={"No data found..."}
                      />
                    </Box>
                  </Box>
                )}
                {showAdditionalTimeFilters === true && (
                  <Box sx={styles.timeFilterContainer}>
                    <Typography variant={"subtitle1"}>START:</Typography>
                    <Box sx={styles.timeFilterItem}>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DateTimePickerMDT
                            value={this.props.selectedCustomStartTimeDisplay}
                            onAccept={(value) => {
                              if (!_.isNil(value)) {
                                this.props.setCustomStartTime(value);
                              }
                            }}
                            onChange={(value) => {
                              if (!_.isNil(value)) {
                                this.props.setCustomStartTimeDisplay(value);
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </Box>
                    <Typography variant={"subtitle1"}>DURATION:</Typography>
                    <Box sx={styles.timeFilterItem}>
                      <AutoCompleteMDT
                        sx={styles.timeFrameSelection}
                        options={this.props.customDurations}
                        value={this.props.selectedCustomDuration}
                        onChange={(event, value, reason) => {
                          this.props.setCustomDuration(value);
                        }}
                        noOptionsText={"No durations found..."}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            }
            {
              this.props.editMode === true &&
              <Box sx={styles.selectionContainer} />
            }

            <Box sx={styles.viewContainer}>
              {this.props.selectedView === pumpDashboardViews.DASHBOARD && (
                <DashboardView
                  pump={this.props.selectedContext}
                  user={this.props.user}
                  editMode={this.props.editMode === true}
                  cardsMetaconfig={this.props.cardsList}
                />
              )}
              {this.props.selectedView === pumpDashboardViews.OPERATION && (
                <OperationView
                  pump={this.props.selectedContext}
                  selectedTimeFrame={this.props.selectedTimeFrame}
                  selectedCustomStartTime={this.props.selectedCustomStartTime}
                  selectedCustomDuration={this.props.selectedCustomDuration}
                  user={this.props.user}
                  editMode={this.props.editMode === true}
                  cardsMetaconfig={this.props.cardsList}
                />
              )}
              {this.props.selectedView === pumpDashboardViews.DATA_EXPLORATION && (
                <Box sx={{ width: '100%', height: '100%', paddingLeft: '10px' }}>
                  <DataExplorationView
                    asset={
                      _.isNil(this.props.selectedContext)
                        ? {}
                        : this.props.selectedContext
                    }
                    selectedTimeFrame={this.props.selectedTimeFrame}
                    selectedCustomStartTime={this.props.selectedCustomStartTime}
                    selectedCustomDuration={this.props.selectedCustomDuration}
                    manualRefreshTime={this.manualRefreshTime}
                    owner={ComponentTypes.PUMP_DASHBOARD}
                    user={this.props.user}
                    editMode={this.props.editMode === true}
                    chartModifiers={{
                      ZoomPanModifier: 'x',
                    }}
                  />
                </Box>
              )}
              {this.props.selectedView?.type === ComponentTypes.USER_VIEW && (
                <CustomView
                  pump={this.props.selectedContext}
                  selectedTimeFrame={this.props.selectedTimeFrame}
                  selectedCustomStartTime={this.props.selectedCustomStartTime}
                  selectedCustomDuration={this.props.selectedCustomDuration}
                  manualRefreshTime={this.manualRefreshTime}
                  owner={ComponentTypes.PUMP_DASHBOARD}
                  user={this.props.user}
                  editMode={this.props.editMode === true}
                  viewConfig={this.props.dashboardViews.find(view => view.id === this.props.selectedView?.id)}
                  cardsMetaconfig={this.props.cardsList}
                />
              )}
            </Box>
          </Box>
          <FilterDialog stateDef={this.props.stateDef} />
          <Progress open={this.props.queryRunning} />
          <ConfirmationDialog showDialog={this.props.showDialog}
            okCallback={() => { this.props.showDialog === "undoChanges" ? this.props.onUndoChangesDialogOK() : this.props.showDialog === "setOwnerDefault" ? this.props.onSetOwnerDefaultDialogOK() : this.props.onRestoreDefaultDialogOK(this.props.selectedView); }}
            cancelCallback={() => { this.props.onDialogCancel(); }} />
        </Box>
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.PUMP_DASHBOARD : props.stateKey,
      type: ComponentTypes.PUMP_DASHBOARD,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let appState = applicationState(state);
  let componentState = pumpDashboardState(state[stateDef.key]);
  const dashboard = appState.user.dashboards[ComponentTypes.PUMP_DASHBOARD];

  return {
    contexts: componentState.contexts,
    selectedContext: componentState.selectedContext,
    dataAgeDisplay: componentState.dataAgeDisplay,
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    selectedView: componentState.selectedView,
    timeFrames: componentState.timeFrames,
    customDurations: componentState.customDurations,
    selectedTimeFrame: componentState.selectedTimeFrame,
    selectedCustomStartTime: componentState.selectedCustomStartTime,
    selectedCustomDuration: componentState.selectedCustomDuration,
    user: appState.user,
    selectedCustomStartTimeDisplay: componentState.selectedCustomStartTimeDisplay,
    queryRunning: componentState.queryRunning,
    showDialog: componentState.showDialog, // should be a string, either "", "undoChanges" or "restoreDefault"
    editMode: dashboard?.editMode,
    isManageCustomViews: dashboard?.isManageCustomViews,
    contextDrawerOpen: appState.context.contextDrawerOpen,
    contextDrawerMinimized: appState.context.contextDrawerMinimized,
    configPanelWidth: componentState.configPanelWidth,
    contextData: appState.context.contextData,
    dashboardViews: dashboard?.views?.views ?? [],
    customViewInput: componentState.customViewInput,
    cardsQueryRunning: componentState.cardsQueryRunning,
    selectedCustomView: componentState.selectedCustomView,
    cardsList: componentState.cardsList,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadDisplay: () => { dispatch(pumpDashboardActions.loadDisplay(props.stateDef)) },
    queryDataAge: (truckId) => { dispatch(pumpDashboardActions.queryDataAge(props.stateDef, truckId)) },
    clearDataAge: () => { dispatch(pumpDashboardActions.clearDataAge(props.stateDef)) },
    refreshRelativeTime: () => { dispatch(pumpDashboardActions.refreshRelativeTime(props.stateDef)) },
    selectContext: (context) => { dispatch(pumpDashboardActions.selectContext(props.stateDef, context)) },
    selectNextPump: () => { dispatch(pumpDashboardActions.selectNextPump(props.stateDef)) },
    selectPrevPump: () => { dispatch(pumpDashboardActions.selectPrevPump(props.stateDef)) },
    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef)) },
    deleteFilterContext: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index)) },
    selectView: (view) => { dispatch(pumpDashboardActions.selectView(props.stateDef, view)) },
    onUpdateCustomViewInput: (customViewInput) => { dispatch(pumpDashboardActions.onUpdateCustomViewInput(props.stateDef, null, customViewInput)) },
    onDiscardCustomViewConfiguration: () => { dispatch(appUserConfigActions.onDiscardCustomViewConfiguration(ComponentTypes.PUMP_DASHBOARD)) },
    setTimeFrame: (timeFrame) => { dispatch(pumpDashboardActions.setTimeFrame(props.stateDef, timeFrame)) },
    setCustomStartTime: (startTime) => { dispatch(pumpDashboardActions.setCustomStartTime(props.stateDef, startTime)) },
    setCustomDuration: (duration) => { dispatch(pumpDashboardActions.setCustomDuration(props.stateDef, duration)) },
    setCustomStartTimeDisplay: (startTime) => { dispatch(pumpDashboardActions.setCustomStartTimeDisplay(props.stateDef, startTime)) },

    setNavigationContext: (context) => { dispatch(pumpDashboardActions.selectNavigationContext(props.stateDef, context)); },
    resetUrl: () => { dispatch(appNavActions.resetUrl('/pump-dashboard')); },

    onEditDashboard: (isEdit) => { dispatch(pumpDashboardActions.editDashboard(props.stateDef, isEdit, ComponentTypes.PUMP_DASHBOARD)); },
    onManageCustomViews: (isManage) => { dispatch(pumpDashboardActions.manageCustomViews(props.stateDef, isManage, ComponentTypes.PUMP_DASHBOARD)); },
    onShowDialog: (show) => { dispatch(pumpDashboardActions.showDialog(props.stateDef, show)); },
    onUndoChangesDialogOK: () => { dispatch(pumpDashboardActions.undoChangesDialogOK(props.stateDef)); },
    onDialogCancel: () => { dispatch(pumpDashboardActions.dialogCancel(props.stateDef)); },
    onSaveDashboardToUser: (name) => { dispatch(pumpDashboardActions.saveDashboardToUser(props.stateDef, name)); },
    onSetOwnerDefaultDialogOK: () => { dispatch(pumpDashboardActions.saveDashboardToOwner(props.stateDef, ComponentTypes.PUMP_DASHBOARD)); },
    onSelectCard: (card) => { dispatch(appUserConfigActions.onSelectedCard(props.stateDef, card)); },
    onRefreshTimestamp: (timestamp) => { dispatch(appUserActions.onRefreshTimestamp(timestamp)); },
    onRefreshManualTimestamp: (timestamp) => { dispatch(appUserActions.onRefreshManualTimestamp(timestamp)); },
    onRestoreDefaultDialogOK: (view) => { dispatch(pumpDashboardActions.restoreDefaultDialogOK(props.stateDef, ComponentTypes.PUMP_DASHBOARD, view)); },

    onOpenContextDrawer: (open, width) => { dispatch(contextActions.openContextDrawer(open, width)); },
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(PumpDashboard))
